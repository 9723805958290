import LoadingEvent from "./events/LoadingEvent";
import ReadyEvent from "./events/ReadyEvent";
import BrowserUnloadEvent from "./events/BrowserUnloadEvent";
import SetTitleEvent from "./events/SetTitleEvent";
import {ProductIcon} from "../../types";
import SetIconEvent from "./events/SetIconEvent";
import CloseRequestEvent from "./events/CloseRequestEvent";
import CloseEvent from "./events/CloseEvent";
import MinimizeEvent from "./events/MinimizeEvent";
import DirectHeaderCloseEvent from "./events/DirectHeaderCloseEvent";

export namespace WindowMethods {
    /**
     * Displays the loading animation
     * @param message
     */
    export const setLoading = (message?: string) => {
        return new LoadingEvent({
            loading: true,
            message
        });
    };

    /**
     * Hides the loading animation
     */
    export const unsetLoading = () => {
        return new LoadingEvent({
            loading: false
        })
    };

    /**
     * Marks the window as "loaded"
     */
    export const setReady = (isRestored: boolean) => {
        return new ReadyEvent({isRestored});
    };

    /**
     * Should be executed before the user's browser window closes
     */
    export const setBrowserUnloading = () => {
        return new BrowserUnloadEvent();
    };

    /**
     * Sets the window's title
     * @param title
     */
    export const setTitle = (title: string) => {
        return new SetTitleEvent(title);
    };

    /**
     * Sets the window's icon name
     * @param icon
     */
    export const setIcon = (icon: ProductIcon) => {
        return new SetIconEvent(icon);
    };

    /**
     * Should be executed when the user clicks the close icon
     */
    export const closeRequest = () => {
        return new CloseRequestEvent();
    };

    /**
     * close Event firing in header on clicking x button
     */
    export const directHeaderClose = (isDirectClose: boolean = false) => {
        return new DirectHeaderCloseEvent(isDirectClose);
    }

    /**
     * Closes the chat window
     */
    export const close = () => {
        return new CloseEvent(true)
    };

    /**
     * Minimizes the ChatWindow
     */
    export const minimizeWindow = () => {
        return new MinimizeEvent(true);
    };

    /**
     * Un-Minimizes the ChatWindow
     */
    export const unMinimizeWindow = () => {
        return new MinimizeEvent(false);
    };
}