import InbentaService from './InbentaService';
import {SendMessageResponse} from '../definition/conversation';
import {Messaging} from '../../../../events/messaging';
import {Answer, AnswerType, ListValueDisplayType, TrackingAttribute} from '../definition/answerTypes';
import {RatingsAction, SingleChoiceAction} from '../../../../events/interaction/types';
import {Interaction} from '../../../../events/interaction';
import {stripHtml} from '../helpers/stripHtml';
import {ProductIcon} from '../../../../types';
import {DirectCall} from '../definition/directCall';
import {RatingsIcon, RatingsType} from '../definition/ratings';
import {RelatedContent} from '../../../../events/messaging/types';
import {Interaction as InbentaInteraction} from '../events/interaction';
import SideWindowAgentMessage from '../components/Messages/SideWindowAgentMessage';
import {ToolDirection} from '../../../service/tracking/types';
import {TypingMethods} from '../../../../events/typing/methods';
import AgentMessageService from "../../../../service/AgentMessageService";
import {InteractionMethods} from "../../../../events/interaction/methods";
import setAgentTyping = TypingMethods.setAgentTyping;
import unsetAgentTyping = TypingMethods.unsetAgentTyping;
import enableBirthDateMask = InteractionMethods.enableBirthDateMask;
import enableInsuranceSumMask = InteractionMethods.enableInsuranceSumMask;
import enableContractDurationMask = InteractionMethods.enableContractDurationMask;
import enableEinkommenSumMask = InteractionMethods.enableEinkommenSumMask;
import enableKreditSumMask = InteractionMethods.enableKreditSumMask;
import enableZipMask = InteractionMethods.enableZipMask;
import enablePreviousDamageMask = InteractionMethods.enablePreviousDamageMask;
import enableSurgeryCountMask = InteractionMethods.enableSurgeryCountMask;
import disableInputMask = InteractionMethods.disableInputMask;
import hideInteraction = InteractionMethods.hideInteraction;
import {MessagingMethods} from "../../../../events/messaging/methods";
import renderDropdownSearchMessage = MessagingMethods.renderDropdownSearchMessage;
import animalRaces from '../../../../../../static/animalRaces.json';

/**
 * This service is responsible for rendering the responses delivered by the POST /message request
 */
class MessageRenderingService extends InbentaService {

    public initialInteractionIsInputBox: boolean;

    // do net send tracking events if opening flag is set
    public skipTrackingEvent: boolean = false;
    public toolName = null;

    /**
     * Handles the message response
     * @param response
     * @param isInitialResponse
     */
    public handleMessageResponse(response: SendMessageResponse, isInitialResponse?: boolean) {

        this.inbenta.chatWindow.messageTransforming.withDelay(() => {
            this.inbenta.dispatchEvent(
                setAgentTyping()
            );
        })

        let answers = response.answers;
        if (!Array.isArray(answers) && response) {
            // @ts-ignore
            const singleItem = response as Answer;
            if(singleItem.message) {
                answers = [singleItem]
            } else {
                return;
            }
        }

        if(Array.isArray(answers) && answers.length === 1 && Array.isArray(answers[0])) {
            // @ts-ignore
            answers = [...answers[0]]
        }

        this.inbenta.allowScrollToLatestFirstMessage = true;
        AgentMessageService.removeFirstMessageFlag();

        let hasRequiredMultipleChoiceQuestion = false;
        let allowRenderInputBox = true;

        answers.forEach((answer, index) => {
            if (this.isButtonAnswer(answer)) {
                allowRenderInputBox = false;
                answer.type = AnswerType.PolarQuestion;
                answer.options = answer.actionField.listValues?.values.map(option => ({
                    value: option.option,
                    label: option.label[0],
                    attributes: {
                        ANSWER_TEXT: option.option,
                        title: option.label[0]
                    }
                }))
            }

            if(answer.type === AnswerType.MultipleChoiceQuestion) {
                if(!answer.flags || !answer.flags.length || answer.flags.indexOf("multiple-options") === -1) {
                    hasRequiredMultipleChoiceQuestion = true;
                }
            }

            if (answer.attributes && answer.attributes['DIRECT_CALL'] && answer.attributes['DIRECT_CALL'].__startsWith('chatbot_schliessen')) {
                allowRenderInputBox = false;
            }

            const currentDelay = this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration && this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration.wait ? this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration.wait : 0;
            setTimeout(() => {
                if(answer.actionField) {
                    const actionField = answer.actionField;
                    switch (actionField.variableName) {
                        case "geburtsdatum":
                            this.inbenta.dispatchEvent(enableBirthDateMask());
                            break;
                        case "versicherungssumme":
                            this.inbenta.dispatchEvent(enableInsuranceSumMask());
                            break;
                        case "einkommen":
                            this.inbenta.dispatchEvent(enableEinkommenSumMask());
                            break;
                        case "kreditsumme":
                            this.inbenta.dispatchEvent(enableKreditSumMask());
                            break;
                        case "laufzeit":
                            this.inbenta.dispatchEvent(enableContractDurationMask());
                            break;
                        case "plz":
                            this.inbenta.dispatchEvent(enableZipMask());
                            break;
                        case "vorschaeden":
                            this.inbenta.dispatchEvent(enablePreviousDamageMask());
                            break;
                        case "anzahl_ops":
                            this.inbenta.dispatchEvent(enableSurgeryCountMask());
                            break;
                        case "rasse_katze":
                        case "rasse_hund":
                        case "rasse_pferd":
                            this.inbenta.dispatchEvent(hideInteraction());
                            const races = this.getAnimalRaces(actionField.variableName);
                            this.inbenta.dispatchEvent(renderDropdownSearchMessage(races));
                            break;
                        default:
                            this.inbenta.dispatchEvent(disableInputMask());
                    }
                } else {
                    switch (answer.message) {
                        case "Entschuldigen Sie 😔, die Versicherungssumme muss zwischen 25.000 € und 10.000.000 € liegen. Bitte wählen Sie eine andere Summe oder wenden Sie sich an meine menschlichen Kollegen.":
                            this.inbenta.dispatchEvent(enableInsuranceSumMask());
                            break;
                        case "Leider konnte ich Ihr eingegebenes Geburtsdatum nicht verarbeiten. Bitte geben Sie es in dem Format TT.MM.JJJJ (Tag.Monat.Jahr) an. Zum Beispiel: 15.03.1978.":
                            this.inbenta.dispatchEvent(enableBirthDateMask());
                            break;
                        case "Tut mir leid! 😔 Der Vertrag kann für max. 50 Jahre abgeschlossen werden und max. bis zu Ihrem 80. Lebensjahr laufen.":
                            this.inbenta.dispatchEvent(enableContractDurationMask());
                            break;
                        default:
                            this.inbenta.dispatchEvent(disableInputMask());
                    }
                }
            }, (this.inbenta.chatWindow.messageTransforming.currentMessageQueue * currentDelay)-currentDelay)

        })

        answers.forEach((answer, index) => {
            this.evaluateSkipTrackingEvent(answer, answers);

            this.renderAnswer(answer, index === 0);
            this.renderSideWindow(answer, hasRequiredMultipleChoiceQuestion);
            this.renderRatings(answer);
            this.renderRelatedContent(answer);
            this.renderPolarQuestion(answer);
            this.renderMultipleChoiceQuestion(answer);
        })

        const currentWait = this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration && this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration.wait ? this.inbenta.chatWindow.messageTransforming.currentMessageQueueConfiguration.wait : 0;

        setTimeout(() => {

            // unset typing
            this.inbenta.dispatchEvent(
                unsetAgentTyping()
            );

        }, (this.inbenta.chatWindow.messageTransforming.currentMessageQueue * currentWait)-currentWait);

        const surveyFreeTextRegExp = new RegExp(DirectCall.SurveyFreeText);
        const pureAnswers = answers.filter(answer => {
            let valid = this.isAnswerDirectCallValid(answer);

            const directCall = answer.attributes ? answer.attributes['DIRECT_CALL'] : null;
            if (!directCall) {
                return answer.type === AnswerType.Answer && valid;
            }

            return answer.type === AnswerType.Answer && valid && !directCall.match(surveyFreeTextRegExp);
        });

        if (pureAnswers.length === answers.length && allowRenderInputBox) {
            if(isInitialResponse) {
                this.inbenta.util.renderInputBox();

                this.initialInteractionIsInputBox = true;
            } else {
                if(this.initialInteractionIsInputBox) {
                    this.inbenta.util.renderInputBox();
                } else {
                    this.inbenta.dispatchEvent(Interaction.Methods.removeCachedInteractionsCompletely());
                    this.inbenta.util.renderInputBox();
                    this.initialInteractionIsInputBox = true;
                }
            }
        }
    }

    /**
     * Renders the basic answer
     * @param answer
     * @param isFirstMessage
     */
    private renderAnswer(answer: Answer, isFirstMessage?: boolean|false) {

        if(answer.type !== AnswerType.Answer) {
            return;
        }

        if (this.isSideWindowAnswer(answer)) {
            return;
        }

        if (!this.isAnswerDirectCallValid(answer)) {
            return;
        }

        this.renderAnswers(answer, isFirstMessage);

    }

    private renderSideWindow(answer: Answer, hasRequiredMultipleChoiceQuestion?: boolean|false) {

        if (!this.isSideWindowAnswer(answer)) {
            return;
        }

        const sideWindow = {
            title: answer.attributes['title'],
            content: answer.attributes['SIDEBUBBLE_TEXT'],
            preventShowingTextInputOnClose: hasRequiredMultipleChoiceQuestion
        }

        // copy the message list
        const {messageList} = answer;

        // get the last answer to render the sidewindow message
        const lastAnswer = messageList.pop();

        for(const message of messageList) {
            // render the other answers as normal messages
            this.inbenta.dispatchEvent(
                Messaging.Methods.renderAgentMessage(
                    stripHtml(message),
                    {
                        icon: ProductIcon.Bot
                    }
                )
            )
            this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, stripHtml(message));
        }

        const sideWindowAgentMessageComponent = new SideWindowAgentMessage(this.inbenta.chatWindow, {
            message: stripHtml(lastAnswer),
            sideWindow
        });

        this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, stripHtml(lastAnswer), answer.attributes ? answer.attributes.TRACKING : null, this.skipTrackingEvent);

        this.inbenta.dispatchEvent(Messaging.Methods.renderComponentAsMessage(sideWindowAgentMessageComponent, () => {
            setTimeout(() => {
                this.inbenta.dispatchEvent(InbentaInteraction.Methods.showSideWindow(sideWindow));
            }, 500);
        }));

    }

    /**
     * Renders the polar question as single choice interaction element
     * @param answer
     */
    private renderPolarQuestion(answer: Answer) {
        if(answer.type !== AnswerType.PolarQuestion) {
            return;
        }

        this.renderAnswers(answer);

        // show the single choice element
        this.inbenta.dispatchEvent(Interaction.Methods.hideChoiceInteraction());
        this.inbenta.dispatchEvent(Interaction.Methods.showSingleChoice(this.generateAction(answer)));

    }

    /**
     * Renders the multiple choice question as multiple choice interaction element
     * @param answer
     */
    private renderMultipleChoiceQuestion(answer: Answer) {

        if(answer.type !== AnswerType.MultipleChoiceQuestion) {
            return;
        }

        this.renderAnswers(answer);

        this.inbenta.chatWindow.messageTransforming.withDelay(() => {
            this.inbenta.dispatchEvent(
                unsetAgentTyping()
            );

            // show the single choice element
            this.inbenta.dispatchEvent(Interaction.Methods.hideChoiceInteraction());
            this.inbenta.dispatchEvent(Interaction.Methods.showSingleChoice(this.generateAction(answer)));

            // render input box if multiple options flag is set
            if(answer.flags && answer.flags.length && answer.flags.indexOf("multiple-options") !== -1) {
                this.inbenta.util.renderInputBox();
            }
        })

    }

    private renderRatings(answer: Answer) {
        if (!answer.attributes || answer.attributes['RATINGS'] === "FALSE") {
            return;
        }

        const actions = this.generateRatingsAction(answer);
        if (actions) {
            this.inbenta.dispatchEvent(Interaction.Methods.showRatings(actions));
        }
    }

    private isAnswerDirectCallValid(answer: Answer) {
        const directCall = answer.attributes ? answer.attributes['DIRECT_CALL'] : null;
        if (!directCall) {
            return true;
        }

        const surveyStarsRegExp = new RegExp(DirectCall.SurveyStars);
        const surveyNoRegExp = new RegExp(DirectCall.SurveyNo);

        if (directCall.match(surveyStarsRegExp) || directCall.match(surveyNoRegExp)) {
            return false;
        }

        return true;
    }

    /**
     * @param answer
     */
    private isSideWindowAnswer(answer: Answer) {
        return !!(answer.attributes && answer.attributes['SIDEBUBBLE_TEXT']);
    }

    /**
     *
     * @param answer
     */
    private isButtonAnswer(answer: Answer) {
        return answer?.actionField?.fieldType === 'list' &&
            answer?.actionField?.listValues?.displayType === ListValueDisplayType.Buttons
    }

    /**
     *
     * @param answer
     */
    private renderAnswers(answer: Answer, isFirstMessage?: boolean|false) {

        const messageList = answer.messageList.map(messageListItem => {
            return stripHtml(messageListItem);
        }).filter(item => !!item)

        messageList.filter(item => !!item).forEach((message, index) => {
            this.inbenta.dispatchEvent(Messaging.Methods.renderAgentMessage(message, {icon: ProductIcon.Bot, isFirstMessage: isFirstMessage && index === 0}))
            if(message.length) {
                // messageList.length === index+1 -> prevents double sending attributes tracking AZIT-207
                this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, message, answer.attributes && messageList.length === index+1 ? answer.attributes.TRACKING : null, this.skipTrackingEvent)
            }
        });
    }

    private renderRelatedContent(answer: Answer) {

        if (answer.parameters && answer.parameters.contents && answer.parameters.contents.related) {

            const related = answer.parameters.contents.related;
            const relatedContent: RelatedContent = {
                label: related.relatedTitle,
                entries: related.relatedContents.map(entry => {
                    return {
                        label: entry.title,
                        onClick: () => {

                            this.inbenta.dispatchEvent(Messaging.Methods.renderUserMessage(stripHtml(entry.title)));

                            if (entry.revisitableLink) {
                                this.inbenta.conversation.sendMessage({
                                    directCall: entry.revisitableLink
                                })
                            } else if (entry.title) {
                                this.inbenta.conversation.sendMessage({
                                    message: entry.title
                                })
                            }

                        }
                    }
                })
            };

            this.inbenta.dispatchEvent(InbentaInteraction.Methods.renderRelatedContent(relatedContent));
        }
    }

    /**
     *
     * @param answer
     */
    private generateAction(answer: Answer): SingleChoiceAction[] {
        // generate the actions
        return answer.options.map(option => {
            return {
                label: option.label,
                onClick: () => {

                    // is displayed though a new on line event
                    if(!this.inbenta.conversation.livepersonBridgeEnabled) {
                        this.inbenta.dispatchEvent(Messaging.Methods.renderUserMessage(option.label));
                    }
                    this.inbenta.dispatchEvent(Interaction.Methods.hideChoiceInteraction());

                    this.inbenta.conversation.sendMessage({
                        message: option.label,
                        option: option.value.toString()
                    });
                }
            }
        });
    }

    private generateRatingsAction(answer: Answer) : RatingsAction[]|null {
        const rateCode = answer.parameters && answer.parameters.contents ? answer.parameters.contents.trackingCode.rateCode : null;
        if (!rateCode) {
            return null;
        }
        return [
            {
                onClick: () => {
                    this.inbenta.ratingService.rateThumbsUp(rateCode);
                },
                hideComponentOnClick: true,
                type: RatingsType.ThumbsUp,
                icon: RatingsIcon.ThumbsUp
            },
            {
                onClick: () => {
                    this.inbenta.ratingService.rateThumbsDown(rateCode);
                },
                hideComponentOnClick: true,
                type: RatingsType.ThumbsDown,
                icon: RatingsIcon.ThumbsDown
            }
        ];
    }

    private evaluateSkipTrackingEvent(currentAnswer, allAnswers) {
        this.skipTrackingEvent = false;

        const isSameChatbot = JSON.parse(localStorage.getItem('cw__current-configuration')).isContinued;
        if (!isSameChatbot) {
            return;
        }

        if (currentAnswer.messageList.length == 0) {
            return;
        }

        const trackingAttributeString = allAnswers[0]?.attributes?.TRACKING; // tracking opening flag is set by inbenta only in the first message
        if (!trackingAttributeString) {
            return;
        }

        const parsedAttributeString = JSON.parse(trackingAttributeString) as TrackingAttribute;
        this.skipTrackingEvent = parsedAttributeString?.opening === 'true';
    }

    /* TODO: chat ui backend -> fetch from webhook service */
    private getAnimalRaces(category: string): string[] {
        switch (category) {
            case "rasse_katze":
                return animalRaces['katze'];
            case "rasse_hund":
                return animalRaces['hund'];
            case "rasse_pferd":
                return animalRaces['pferd'];
        }
    }

}

export default MessageRenderingService;
