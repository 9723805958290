const JSX = {
    createElement: (tag, attrs, ...children) => {

        if (typeof tag === 'function') {
            children = children.filter(child => !!child);
            return tag({
                ...attrs,
                children: children.length ? children : null
            })
        }
        // regular html tags will be strings to create the elements
        if (typeof tag === 'string') {

            // fragments to append multiple children to the initial node
            const fragments = document.createDocumentFragment();
            const element = document.createElement(tag);

            if (attrs) {
                const attrKeys = Object.keys(attrs);

                for (const attrKey of attrKeys) {

                    if (attrKey === 'innerHTML') {
                        element.innerHTML = attrs[attrKey];
                    } else if (attrKey.__startsWith('on') && typeof attrs[attrKey] === 'function') {
                        const eventName = attrKey.toLowerCase().replace('on', '');
                        //element.addEventListener(eventName, attrs[attrKey]);
                    } else {
                        element.setAttribute(attrKey, attrs[attrKey])
                    }
                }
            }

            children.forEach(child => {
                if (child instanceof HTMLElement || child instanceof Element || child instanceof HTMLButtonElement) {
                    fragments.appendChild(child)
                } else if (typeof child === 'object') {
                    try {
                        child.forEach(oneChild => {
                            if (Array.isArray(oneChild)) {
                                oneChild.forEach(lastChild => fragments.appendChild(lastChild));
                            } else {
                                fragments.appendChild(oneChild);
                            }
                        });
                    } catch (e) {
                    }
                } else if (typeof child === 'string') {
                    if (child.includes('<')) {
                        const htmlNode = document.createElement('span');
                        htmlNode.innerHTML = child;
                        fragments.appendChild(htmlNode);
                    } else {
                        const textnode = document.createTextNode(child);
                        fragments.appendChild(textnode);
                    }

                }
            });
            element.appendChild(fragments);
            // Merge element with attributes
            Object.assign(element, attrs);
            return element
        }
    }
};


export default JSX;

declare namespace JSX {
    export type Element = HTMLElement;
}
