import {ConversationHistoryEntry} from "../definition/conversation";
import {AbstractEvent} from "../../../../events/AbstractEvent";
import ChatWindow from "../../../../ChatWindow";
import {Messaging} from "../../../../events/messaging";
import {stripHtml} from "./stripHtml";
import {ProductIcon} from "../../../../types";

export const historyItemToEvent = (chatWindow: ChatWindow, historyItem: ConversationHistoryEntry): AbstractEvent<any> => {
    if (!historyItem.message) {
        return null;
    }

    const timestamp = new Date(Date.parse(historyItem.datetime));

    if (historyItem.user === 'user') {
        return Messaging.Methods.renderUserMessage(historyItem.message, {timestamp});
    } else if (historyItem.user === 'bot') {
        const buttonOnClickRegex = /<button onclick=/;
        if (historyItem.message.match(buttonOnClickRegex)) {
            return null;
        }
        return Messaging.Methods.renderAgentMessage(stripHtml(historyItem.message), {timestamp, icon: ProductIcon.Bot});
    }
    return null;
};