import InbentaService from "./InbentaService";

import {API_BASE_URL} from "../config/const";

import {DEFAULT_HEADERS} from "../config/defaults";
import {Interaction} from "../../../../events/interaction";
import {InteractionEvents} from '../../../../events/interaction/events';
import onTextInput = InteractionEvents.onTextInput;
import {InteractionMethods} from "../../../../events/interaction/methods";
import enableDateMask = InteractionMethods.enableDateMask;

class UtilService extends InbentaService {

    chatBotBaseUrl: string;

    getChatbotApiUrl = async (): Promise<string> => {
        const requestUrl = `${API_BASE_URL}/apis`;
        const requestHeaders = this.baseHeaders;

        const response = await fetch(requestUrl, {
            method: 'GET',
            headers: requestHeaders
        }).then(res => res.json());

        try {
            return response.apis.chatbot + '/v1';
        } catch (e) {
            console.warn(`Could not fetch Inbenta API endpoints. Please make sure your domain ${window.location.host} is listed as whitelisted domain.`);
            this.inbenta.api.shutdownService.executeShutdown();
        }

    };

    get baseHeaders() {
        return {
            ...DEFAULT_HEADERS,
            ...{
                'authorization': `Bearer ${this.inbenta.authorization.currentAuthorization.accessToken}`,
                'x-inbenta-key': this.inbenta.context.apiKey
            }
        };
    }

    get baseHeadersWithEnvironment() {
        return {
            ...this.baseHeaders,
            'x-inbenta-env': this.inbenta.chatWindow.configuration.inbenta.inbentaEnvironment
        }
    }

    public renderInputBox(force = false) {

        if (!force) {
            const latestEvents = this.inbenta.chatWindow.footerEvents.latestDirectCallEvents;
            //@ts-ignore
            if(latestEvents.length && latestEvents[latestEvents.length-1].constructor.NAME === onTextInput) {
                return;
            }
        }

        this.inbenta.dispatchEvent(Interaction.Methods.showTextInput(this.inbenta.chatWindow.translation.translate('in-chat.input-box.placeholder')));
        // this.inbenta.dispatchEvent(enableDateMask());
    }

    initialize = async () => {
        this.chatBotBaseUrl = await this.getChatbotApiUrl();
    };


}

export default UtilService
