import {define, withLocale, withPrefix, withScope} from "../helpers/TranslationHelpers";
import {ApiProvider} from "../api/providers/providers";

export default withScope(withLocale([

    ...withPrefix('general', [
        define('header', 'Allie, Ihre digitale Assistentin', 'Inbenta Chat-Window header'),
    ]),

    ...withPrefix('star_rating', [
        define('survey_1star', 'Sehr unzufrieden', 'Sterne Bewertung: 1 Stern'),
        define('survey_2stars', 'unzufrieden', 'Sterne Bewertung: 2 Sterne'),
        define('survey_3stars', 'teils/teils', 'Sterne Bewertung: 3 Sterne'),
        define('survey_4stars', 'zufrieden', 'Sterne Bewertung: 4 Sterne'),
        define('survey_5stars', 'sehr zufrieden', 'Sterne Bewertung: 5 Sterne'),
    ]),

    ...withPrefix('survey_free_text', [
        define('input_placeholder', 'Ihre Meinung', 'Survey Free Text: Input Placeholder'),
        define('bot_anwser', 'Danke, dass Sie sich Zeit genommen haben', 'Survey Free Text: Bot Answer'),
    ]),

    ...withPrefix('rating', [
        define('answer_helpful', 'War diese Antwort hilfreich?', 'War diese Antwort hilfreich?'),
        define('answer_input_placeholder', 'Meinung abgeben:', 'Rating Input Placeholder: Meinung abgeben'),
        define('answer_bot_feedback_message_thumbsdown', 'Bitte sagen Sie uns, warum diese Antwort unzureichend für Sie ist. <br/>', 'Rating Bot Nachricht: Meinung abgeben'),
        define('answer_bot_feedback_message_thumbsup', 'Bitte sagen Sie uns, warum diese Antwort zureichend für Sie ist. <br/>', 'Rating Bot Nachricht: Meinung abgeben'),
        define('skip', 'Überspringen', 'Rating überspringen'),
        define('user_feedback', "Danke für Ihre Bewertung!", "Text nach Ratings")
    ]),
], 'de'), ApiProvider.Inbenta)
