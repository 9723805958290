export namespace WindowEvents {
    export const onLoading = "events/window/loading";
    export const onReady = "events/window/ready";
    export const onBrowserUnload = "events/window/browser-unload";

    export const onCloseRequest = "events/window/close-request";
    export const onClose = "events/window/close";

    export const onMinimize = "events/window/minimize";

    export const onSetIcon = "events/window/set-icon";
    export const onSetTitle = "events/window/set-title";

    export const directHeaderClose = "events/window/direct-header-close"
}