import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";

class ContactAgencyTransitionHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith("kontakt_agentur_übergang");
    }

    public handle(answer: Answer) {
        this.openUserAssistant();
    }

}

export default ContactAgencyTransitionHandler;
