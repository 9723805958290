
export interface SendEventAttributes {
    type: SendEventType,
    code: string,
    value?: string|number,
    comment?: string
}

export interface SendEventResponse {
    status?: number
}

export enum SendEventType {
    Click = "click",
    Rate = "rate"
}