import AbstractHandler from "./AbstractHandler";
import {DirectCall} from "../../definition/directCall";
import {Answer} from "../../definition/answerTypes";

class ContactPhvLiveChatHandler extends AbstractHandler {

    canHandle(answer: Answer): boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ContactPhvLiveChat);
    }

    handle(answer: Answer) {
        setTimeout(() => {
            this.inbenta.chatWindow.api.shutdownService.executeShutdown();
            const url = "https://www.allianz.de/service/chat/?AZMEDID=Offline_PR-Privatschutz_KA-chat20_ME-Brief_MV-Motiv5?ua=Chat";
            let win = window.open(url, '_blank')
            if (win) {
                win.focus();
            }
        }, 1500);
    }

}

export default ContactPhvLiveChatHandler