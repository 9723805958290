import AbstractSubscriber from '../AbstractSubscriber';
import ConfigureTrackingEvent from '../../events/tracking/events/ConfigureTrackingEvent';
import {TrackingEvents} from '../../events/tracking/events';
import {EndType} from "../../api/service/tracking/types";
import onConfigureTracking = TrackingEvents.onConfigureTracking;

class ConfigureTrackingSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(onConfigureTracking, this.handleTrackingConfiguration.bind(this));
    }

    private handleTrackingConfiguration(event: ConfigureTrackingEvent) {

        const {
            enabled,
            chatStart,
            chatState,
            toolCategory,
            toolName,
            triggerOpenType,
            triggerType,
            pageName,
            trackingOpenType,
            trackingToolName,
        } = event.data;

        this.chatWindow.tracking.enabled = !!enabled;
        this.chatWindow.tracking.chatStart = chatStart || this.chatWindow.tracking.chatStart;
        this.chatWindow.tracking.chatState = chatState || this.chatWindow.tracking.chatState;
        this.chatWindow.tracking.toolCategory = toolCategory || this.chatWindow.tracking.toolCategory;
        this.chatWindow.tracking.toolName = toolName || this.chatWindow.tracking.toolName;
        this.chatWindow.tracking.triggerOpenType = triggerOpenType || this.chatWindow.tracking.triggerOpenType;
        this.chatWindow.tracking.triggerType = triggerType || this.chatWindow.tracking.triggerType;

        this.chatWindow.tracking.trackingAppName = trackingToolName || this.chatWindow.tracking.trackingAppName;
        this.chatWindow.tracking.trackingPageView = pageName || this.chatWindow.tracking.trackingPageView;
        this.chatWindow.tracking.trackingOpenType = trackingOpenType || this.chatWindow.tracking.trackingOpenType;

        if(this.chatWindow.configuration.configuration.implicitShutdown) {
            this.chatWindow.tracking.trackToolExit(EndType.Implicit);
        }


    }

}

export default ConfigureTrackingSubscriber;
