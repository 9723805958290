import {AbstractEvent} from "../../AbstractEvent";
import {InteractionEvents} from "../events";
import onEnableDateMask = InteractionEvents.onEnableDateMask;
import IMask from "imask";

export interface DateMaskConfig {
    placeholder: string
    config: any,
    validation?: RegExp;
}

class EnableDateMaskEvent extends AbstractEvent<DateMaskConfig> {
    static NAME = onEnableDateMask;
}

export default EnableDateMaskEvent;