import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import Messages from "./Messages";
import Environment from '../../helpers/Environment';
import {Utility} from '../../events/utility';
import ScrollToBottomEvent from '../../events/utility/events/ScrollToBottomEvent';
import {ApiProvider} from "../../api/providers/providers";

export default class Body extends GeneralComponent<any> implements ComponentInterface {

    protected onConstructor() {
        this.registerEventHandler(Utility.Events.onScrollToBottom, this.handleScrollToBottom.bind(this));
    }

    render(parentElement: HTMLElement | Element) {
        this.generateBody();
        this.renderChildren();
        parentElement.appendChild(this.domElement);

        if (this.chatWindow.api.currentProvider.name === ApiProvider.Inbenta) {
            if (Environment.isMobileDevice()) {
                this.domElement.addEventListener("touchmove", () => {
                    setTimeout(() => {
                        this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage = false;
                    }, 500)
                })
            } else {
                this.domElement.onscroll = () => {
                    setTimeout(() => {
                        this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage = false;
                    }, 500)
                }
            }
        }

        // workaround to prevent double clicking
        this.domElement.addEventListener('click', function (e) {
            const target = e.target as HTMLElement
            if (target.tagName.toLowerCase() === 'a') {
                target.style.pointerEvents = "none";
                target.style.cursor = "default";

                setTimeout(() => {
                    target.style.pointerEvents = "auto";
                    target.style.cursor = "pointer";
                }, 6000);
            }
        });
    }

    private renderChildren() {

        const messages = new Messages(this.chatWindow);
        messages.render(this.domElement);

    }

    private generateBody() {
        switch (this.chatWindow.api.currentProvider.name) {
            case ApiProvider.Inbenta:
                this.domElement = <div class={`cw__body ${Environment.isMobileDevice() || Environment.isFirefox() ? '' : 'cw__column-reverse'}`}/>;
                break;
            default:
                this.domElement = <div class={`cw__body ${Environment.isEdge() || Environment.isFirefox() ? '' : 'cw__column-reverse'}`}/>;
        }
    }

    private handleScrollToBottom(event: ScrollToBottomEvent) {
        this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage = true;
        if (event.data.force) {
            this.scrollToBottom(event);
        } else {
            if (this.chatWindow.api.currentProvider.name !== ApiProvider.Inbenta) {
                if(Environment.isEdge() || Environment.isFirefox()) {
                    this.scrollToBottom(event);
                }
            }
        }
    }

    private scrollToBottom(event: ScrollToBottomEvent) {
        setTimeout(() => {
            this.domElement.scrollTop = this.domElement.scrollHeight;
        }, event.data.withDelay ? 350 : 0);
    }

}
