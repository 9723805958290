import {AbstractEvent} from "../../AbstractEvent";
import {ProductIcon} from "../../../types";
import {WindowEvents} from "../events";

/**
 * Sets the header icon
 */
class SetIconEvent extends AbstractEvent<ProductIcon> {
    static NAME = WindowEvents.onSetIcon;
}

export default SetIconEvent