import AbstractHandler from "./AbstractHandler";
import {DirectCall} from "../../definition/directCall";
import {Interaction} from "../../../../../events/interaction";
import {Answer} from "../../definition/answerTypes";
import {Messaging} from "../../../../../events/messaging";

class SurveyYesHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.SurveyYes);
    }

    public handle(answer: Answer) {
        this.inbenta.allowBackOnClose = true;
        setTimeout(() => {
            const clickCode = answer.parameters.contents.trackingCode.clickCode;
            if (!clickCode) {
                return;
            }
            this.inbenta.dispatchEvent(Interaction.Methods.showStarRating(
                [
                    {
                        value: "survey_1star",
                        displayValue: this.inbenta.chatWindow.translation.translate('star_rating.survey_1star')
                    },
                    {
                        value: "survey_2stars",
                        displayValue: this.inbenta.chatWindow.translation.translate('star_rating.survey_2stars')
                    },
                    {
                        value: "survey_3stars",
                        displayValue: this.inbenta.chatWindow.translation.translate('star_rating.survey_3stars')
                    },
                    {
                        value: "survey_4stars",
                        displayValue: this.inbenta.chatWindow.translation.translate('star_rating.survey_4stars')
                    },
                    {
                        value: "survey_5stars",
                        displayValue: this.inbenta.chatWindow.translation.translate('star_rating.survey_5stars')
                    }
                ],
                (selectedStar) => {
                    this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
                    this.inbenta.dispatchEvent(Messaging.Methods.renderUserMessage(selectedStar.displayValue));
                    this.inbenta.conversation.sendMessage({directCall: selectedStar.value});
                }
            ));
        })
    }

}

export default SurveyYesHandler;
