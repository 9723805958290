import InbentaService from "./InbentaService";

import {SendEventType} from '../definition/events';
import {Interaction} from "../../../../events/interaction";
import {ChatState} from "../definition/chatState";
import {Messaging} from "../../../../events/messaging";
import {ProductIcon} from "../../../../types";
import AgentMessage from "../../../../components/General/Messages/AgentMessage";
import {ToolDirection} from '../../../service/tracking/types';

class RatingService extends InbentaService {

    public rateThumbsUp(rateCode)  {

        // track interaction
        this.inbenta.api.trackInteraction();

        this.sendEvent(rateCode, "1");
        if (this.inbenta.chatWindow.configuration.inbenta.enableFeedbackForAllRatings) {
            this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
            this.showRatingFeedback(rateCode, "1", this.inbenta.chatWindow.translation.translate('rating.answer_bot_feedback_message_thumbsup'));
            this.inbenta.dispatchEvent(Interaction.Methods.showTextInput(this.inbenta.chatWindow.translation.translate('rating.answer_input_placeholder')));
        } else {
            const message = this.inbenta.chatWindow.translation.translate('rating.user_feedback');
            this.inbenta.dispatchEvent(Messaging.Methods.renderAgentMessage(message, {icon: ProductIcon.Bot, isFirstMessage: true}));
            this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, message);
        }
    }

    public rateThumbsDown(rateCode)  {

        // track interaction
        this.inbenta.api.trackInteraction();

        this.sendEvent(rateCode, "2");
        this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
        this.showRatingFeedback(rateCode, "2", this.inbenta.chatWindow.translation.translate('rating.answer_bot_feedback_message_thumbsdown'));
        this.inbenta.dispatchEvent(Interaction.Methods.showTextInput(this.inbenta.chatWindow.translation.translate('rating.answer_input_placeholder')));
    }

    private showRatingFeedback(rateCode: string, value: string, message: string) {

        // track interaction
        this.inbenta.api.trackInteraction();

        this.inbenta.context.chatState = {
            type: ChatState.Survey,
            callback: (message) => {
                this.sendEvent(rateCode, value, message);
                const translatedMessage = this.inbenta.chatWindow.translation.translate('rating.user_feedback');
                this.inbenta.dispatchEvent(Messaging.Methods.renderAgentMessage(translatedMessage, {icon: ProductIcon.Bot, isFirstMessage: true}));
                this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, translatedMessage);
                this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
                this.inbenta.util.renderInputBox(true);
            }
        }

        const agentMessageComponent = new AgentMessage(this.inbenta.chatWindow, {
            message: message,
            icon: ProductIcon.Bot,
            isFirstMessage: true,
            ctaText: {
                label: this.inbenta.chatWindow.translation.translate('rating.skip'),
                onClick: () => {
                    const translatedMessage = this.inbenta.chatWindow.translation.translate('rating.user_feedback');
                    this.inbenta.dispatchEvent(Messaging.Methods.renderAgentMessage(translatedMessage, {icon: ProductIcon.Bot, isFirstMessage: true}));
                    this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Outbound, translatedMessage);
                    this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
                    this.inbenta.util.renderInputBox(true);
                    this.inbenta.context.chatState = {
                        type: ChatState.Chatting
                    }
                },
                removeOnClick: true
            }
        });

        this.inbenta.dispatchEvent(Messaging.Methods.renderComponentAsMessage(agentMessageComponent));
    }

    private sendEvent(rateCode: string, value: string, comment?: string) {
        this.inbenta.trackingEventService.sendEvent({
            type: SendEventType.Rate,
            code: rateCode,
            value: value,
            comment: comment
        });
    }
}

export default RatingService;
