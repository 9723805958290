import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";
import {DirectCall} from "../../definition/directCall";

class ContactAgency4Handler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ContactAgency4);
    }

    public handle(answer: Answer) {
        this.openUserAssistant();
    }

}

export default ContactAgency4Handler;
