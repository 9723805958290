import InbentaService from "./InbentaService";

import {AuthorizationResponse} from "../definition/authorization";
import {API_BASE_URL} from "../config/const";

class AuthorizationService extends InbentaService {

    private localstorage_key = 'inbenta_authorization';

    private authTimeout: any;

    authorize = async (): Promise<AuthorizationResponse> => {

        const currentAuthorization = this.currentAuthorization;
        if (!currentAuthorization || currentAuthorization.expiration <= Math.round(+new Date() / 1000)) {
            this.currentAuthorization = await this.renewAuthorization();
        }

        return this.currentAuthorization;

    };

    renewAuthorization = async (): Promise<AuthorizationResponse> => {
        const requestUrl = `${API_BASE_URL}/auth`;
        const requestHeaders = {
            'x-inbenta-key': this.inbenta.context.apiKey,
            'Content-Type': 'application/json'
        };
        const requestBody = {
            domain_key: this.inbenta.context.domainKey
        };
        const response = await fetch(requestUrl, {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(requestBody)
        }).then(res => res.json()) as AuthorizationResponse;

        // check if expires in has been set
        if(response.expires_in) {
            const expiresIn = (response.expires_in - 30) * 1000;
            this.setAuthorizationTimeout(expiresIn);
        }

        return response;
    };

    refreshAuthorizationToken = async () => {
        const requestUrl = `${API_BASE_URL}/refreshToken`;
        const requestHeaders = {
            'x-inbenta-key': this.inbenta.context.apiKey,
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this.inbenta.authorization.currentAuthorization.accessToken}`,
        };
        const response = await fetch(requestUrl, {
            method: 'POST',
            headers: requestHeaders
        }).then(res => res.json()) as AuthorizationResponse;

        // check if expires in has been set
        if(response.expires_in) {
            const expiresIn = (response.expires_in - 30) * 1000;
            this.setAuthorizationTimeout(expiresIn);
        }

        this.currentAuthorization = response;
    };

    public setAuthorizationTimeout(expiresIn) {
        this.authTimeout = setTimeout(() => {
            this.refreshAuthorizationToken();
        }, expiresIn);
    }

    public clearAuthorizationTimeout() {
        if (this.authTimeout) {
            clearTimeout(this.authTimeout);
        }
    }

    get currentAuthorization(): AuthorizationResponse | null {
        if (!localStorage.getItem(this.localstorage_key)) {
            return null;
        }
        return JSON.parse(localStorage.getItem(this.localstorage_key))
    }

    set currentAuthorization(authorization: AuthorizationResponse) {
        localStorage.setItem(this.localstorage_key, JSON.stringify(authorization))
    }

    initialize = async () => {
        this.currentAuthorization = await this.authorize();
    };

}

export default AuthorizationService;
