import {AbstractEvent} from "../../AbstractEvent";
import {WindowEvents} from "../events";

/**
 * Is dispatched when the minimize button is pressed
 */
class MinimizeEvent extends AbstractEvent<boolean> {
    static NAME = WindowEvents.onMinimize;
}

export default MinimizeEvent;