import {AbstractEvent} from "../../AbstractEvent";
import {UtilityEvents} from "../events";

/**
 * Clears the conversation history
 */
class ClearHistoryEvent extends AbstractEvent<any> {
    static NAME = UtilityEvents.onClearHistory;
}

export default ClearHistoryEvent;