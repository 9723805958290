import {AbstractEvent} from "../../AbstractEvent";
import {UserInteractionEventConfig} from "../types";
import {MessagingEvents} from "../events";

/**
 * This event is dispatched, when the user interacts with a speech bubble
 * Fox example a multiple choice question
 */
class UserInteractionEvent extends AbstractEvent<UserInteractionEventConfig> {
    static NAME = MessagingEvents.onUserInteraction;
}

export default UserInteractionEvent;