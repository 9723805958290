import {define, withLocale, withPrefix, withScope} from "../helpers/TranslationHelpers";
import {ApiProvider} from "../api/providers/providers";

export default withScope(withLocale([

    ...withPrefix('survey', [

        define('validation.answer-missing', "Bitte beantworten Sie die Frage.", 'Text, wenn eine Antwort in einer Umfrage nicht ausgefüllt wurde'),
        define('validation.one-answer-required', "Bitte geben Sie mindestens eine Nachricht ein.", 'Text, wenn der Nutzer in einer Umfrage auf "abschicken" klickt, ohne eine Nachricht eingegeben zu haben'),
        define('validation.email-invalid', "Bitte geben Sie eine gültige E-Mail-Adresse ein.", 'E-Mail-Adressen werden auf ein richtiges Format überprüft (@, .com etc.). Dieser Text erscheint in der Umfrage, wenn eine Mail-Adresse in einem falschen Format eingegeben wurde (z.B. ohne @)'),
        define('validation.invalid-number', "Bitte geben Sie eine gültige Zahl ein.", 'Dieser Text wird in der Umfrage ausgegeben, wenn eine ungültige Zahl (Buchstabe, Kommawert etc.) erkannt wurde'),
        define('validation.invalid-alpha-number', "Bitte geben Sie eine gültige Zahl ein.", 'Dieser Text wird in der Umfrage ausgegeben, wenn eine ungültige Alphanummerische Eingabe erkannt wurde'),
        define('validation.invalid-answer', "Bitte geben Sie eine gültige Antwort an.", 'Dieser Text wird in der Umfrage bei einer ungültigen Antwort ausgegeben'),

        define('skip-answer', `Sie können diese Nachricht <span class="highlight">Überspringen</span>.`, 'Nachricht für den Überspringen Hinweis in einer Umfrage')

    ]),

    define("Account expired", "Aufgrund eines technischen Problems konnte keine Verbindung zum Server hergestellt werden.", 'Nachricht, wenn kein Agent Online ist (z.B. außerhalb der Service-Zeiten). Abfrage nach der Pre Chat Survey (Anbieter abhängig)'),
    define("Availability state is: Offline", "Derzeit ist leider keiner unserer Mitarbeiter verfügbar.", 'Nachricht, wenn kein Agent zur Verfügung steht (z.B. wenn alle Agenten im Gespräch sind)'),
    define("Availability state is: Away", "Derzeit können wir Sie leider mit keinem unserer Mitarbeiter verbinden. Bitte versuchen Sie es in ein paar Minuten erneut.", 'Bestätigungstext bei Anforderung des Transskripts. %s ist der Platzhalter für die eingegebene E-Mail-Adresse des Nutzers'),

    ...withPrefix('predefined', [
       define('on-transcript-request', 'Wir senden das Chat-Transkript nun an Ihre E-Mail Adresse %s.', 'Bestätigungstext bei Anforderung des Transskripts. %s ist der Platzhalter für die eingegebene E-Mail-Adresse des Nutzers'),
       define('on-chained-question', 'Bitte helfen Sie uns, Ihren Service-Agenten zu finden.', 'Hinweis für den Nutzer, warum er die Pre Chat Survey ausfüllen ')
    ]),


    ...withPrefix('general', [
        define('header', 'Allianz Live-Chat', 'Header des Chat Windows, bevor der Agentenname eingeblendet wird'),
    ]),

    ...withPrefix('survey.chained.level', [
        define('pre.header', 'Bitte helfen Sie uns, Ihren Kundenberater zu finden.', 'Vor der ersten Frage der Prechat Survey'),
        define('1.header', 'Zu welchem Thema haben Sie ein Anliegen?', 'Erste Frage der Prechat Survey'),
        define('2.header', 'Um welches Produkt oder welchen Service handelt es sich?', 'Zweite Frage der Pre Chat Survey')
    ]),

    ...withPrefix('printing', [

        define('agent-name.default', 'Allianz-Kundenberater', 'Überschrift der Namensspalte im Chat Ausdruck (Anbieter abhängig)'),
        define('survey-agent-name.default', 'Chat-Umfrage', 'Name der Umfrage (Anbieter abhängig)'),
        define('visitor-name.default', 'Sie', 'Name bzw. Bezeichnung des Nutzers (Anbieter abhängig)'),
        define('system-name.default', 'System', 'Absender-Bezeichnung bei System-Messages (Anbieter abhängig)'),

        ...withPrefix('feature', [
            ...withPrefix('request-transcript', [
                define('label', 'E-Mail', 'Transscript anfordern CTA Button'),
                define('placeholder', 'E-Mail Adresse', 'Platzhalter für Transcript E-Mail Feld'),
                define('message', 'Bitte geben Sie Ihre E-Mail-Adresse ein.', 'Nachricht des Agents'),
                define('user-message', 'Ich möchte den Chat-Verlauf per E-Mail erhalten.', 'Nachricht des Nutzers wenn er den CTA Button betätigt')
            ])
        ]),

        ...withPrefix('survey', [
            define('header', 'Den Chat-Verlauf können Sie sich ganz bequem ausdrucken oder per E-Mail zusenden lassen. Welche der beiden Optionen wünschen Sie?', 'Nachricht des Agenten, wenn der Nutzer den Chat Verlauf entweder drucken oder per E-Mail erhalten will')
        ])

    ])


], 'de'), ApiProvider.LivePerson)
