import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";
import {DirectCall} from "../../definition/directCall";

class ChatAcceptedHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ChatAccepted);
    }

    public handle(answer: Answer) {
        // this.inbenta.directClose = false;
    }

}

export default ChatAcceptedHandler;
