import JSX from "../../../jsx";
import Avatar from "../Content/Avatar";
import {MessageType, ProductIcon} from "../../../types";
import {ErrorLevel} from "../../../events/error-handling/types";
import Environment from "../../../helpers/Environment";

interface MessageProps {
    type: MessageType|ErrorLevel
    icon?: ProductIcon
    text?: string
    children?: any,
    ctaText?: CtaText,
    isFirstMessage?: boolean|false
    additionalContentMessageHtmlClass?: string
}

interface CtaText {
    label: string
    onClick?: (e) => void
}

const GeneralMessage = (props: MessageProps) => {

    if(props.text) {
        props.text = props.text.replace(/(?:\r\n|\r|\n)/g, '</br>');
    }

    const messageElement = <p class="c-copy cw__message__content-content__text" role="alert"></p>
    messageElement.innerHTML = props.text

    if(Environment.isMobileDevice()) {
        const includingATags = messageElement.querySelectorAll('a[target="_blank"]')
        includingATags.forEach(element => {
            element.addEventListener('click', () => {
                //@ts-ignore
                if(window.chatbotservice && window.chatbotservice.minimize) {
                    //@ts-ignore
                    window.chatbotservice.minimize();
                }
            })
        })
    }

    if(props.ctaText) {
        messageElement.appendChild(<a href={"#"} class={"ctaTextLink"} onclick={props.ctaText.onClick}>{props.ctaText.label}</a>)
    }

    const resultingElement = (
        <div class={`cw__message cw__message-${props.type} ${props.type !== 'sent' ? 'cw__message--slide-in' : ''} ${props.isFirstMessage ? "first": ""}`} tabindex="0">
            {props.icon ? <Avatar icon={props.icon}/> : null}
            {props.text || props.children ? <div class={`cw__message__content ${props.additionalContentMessageHtmlClass ? props.additionalContentMessageHtmlClass : ""}`}>
                {props.text ? messageElement : null}
                {props.children && props.text ? <span class="cw__util-pt-1"/> : null}
                {props.children ? props.children : null}
            </div> : null}
        </div>
    );

    return resultingElement;

};

export default GeneralMessage
