import PerfectScrollbar from 'perfect-scrollbar';
import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";

import RenderMessageComponentEvent from "../../events/messaging/events/RenderMessageComponentEvent";
import LoadingEvent from "../../events/window/events/LoadingEvent";
import AgentTypingMessage from "../General/Messages/AgentTypingMessage";
import AnimationHelper from "../../helpers/AnimationHelper";
import AgentTypingEvent from "../../events/typing/events/AgentTypingEvent";
import {TypingState} from "../../events/typing/types";
import {Window} from "../../events/window";
import {Utility} from "../../events/utility";
import {Typing} from "../../events/typing";
import {Messaging} from "../../events/messaging";
import HideCtaSystemMessageEvent from '../../events/messaging/events/HideCtaSystemMessageEvent';
import Environment from '../../helpers/Environment';
import UserTypingEvent from "../../events/typing/events/UserTypingEvent";
import {ApiProvider} from "../../api/providers/providers";
import AgentMessageService from "../../service/AgentMessageService";
import {InteractionEvents} from "../../events/interaction/events";
import onTextInput = InteractionEvents.onTextInput;
import TextInputEvent from "../../events/interaction/events/TextInputEvent";
import {UtilityMethods} from "../../events/utility/methods";
import scrollToBottom = UtilityMethods.scrollToBottom;

/**
 * This class represents the messages container
 */
export default class Messages extends GeneralComponent<any> implements ComponentInterface {

    // holds the agent typing dom element
    private agentTyping: HTMLElement = <AgentTypingMessage icon={this.chatWindow.api.currentProvider.getAgentIcon()}/>;

    // holds the perfect scrollbar instance
    private scrollbarInstance: PerfectScrollbar;

    public onConstructor() {
        this.registerEventHandlers();
        AnimationHelper.animateReceivedMessage(this.agentTyping);
    }

    public render(parentElement: HTMLElement | Element) {
        this.generateMessagesComponent();
        parentElement.appendChild(this.domElement);
    }

    public generateMessagesComponent() {
        this.domElement = <div class="cw__messages__container" aria-label="Nachrichten"/>;
    }

    private registerEventHandlers() {
        this.registerEventHandler(Messaging.Events.onRenderMessageComponent, this.handleMessageComponentRendering.bind(this));
        this.registerEventHandler(Utility.Events.onClearHistory, this.clearMessageHistory.bind(this));
        this.registerEventHandler(Window.Events.onLoading, this.handleChatWindowLoadingEvent.bind(this));
        this.registerEventHandler(Typing.Events.onAgentTyping, this.handleAgentTyping.bind(this));
        this.registerEventHandler(Typing.Events.onUserTyping, this.handleUserTyping.bind(this));
        this.registerEventHandler(Messaging.Events.onHideCtaSystemMessage, this.handleHideCtaSystemMessage.bind(this));
        this.registerEventHandler(onTextInput, this.handleShowTextInputEvent.bind(this))
    }

    /**
     * Renders the incoming component
     * @param event
     */
    private handleMessageComponentRendering(event: RenderMessageComponentEvent) {

        this.chatWindow.messageTransforming.withDelay(() => {
            // render the component
            event.data.component.render(this.domElement);

            // move the agent typing bubble to the bottom, if it exists
            if (this.domElement.querySelector('.cw__agent-typing')) {
                this.domElement.appendChild(this.agentTyping);
            }


            // execute the rendering callback, if configured
            if (event.data.callback && typeof event.data.callback === 'function') {
                event.data.callback();
            }
        });

    }

    /**
     * Clears the message history
     */
    private clearMessageHistory() {
        this.domElement.innerHTML = '';
    }

    /**
     * Handles the chat window loading state
     * - The messages container should be hidden when the loading animation is active
     * @param event
     */
    private handleChatWindowLoadingEvent(event: LoadingEvent) {
        const {loading} = event.data;
        if (loading) {
            this.domElement.style.display = 'none'
        } else {
            this.domElement.style.display = '';
        }
    }

    /**
     * Handles the agent's typing state
     * - Displays / hides the agent typing bubble
     * @param event
     */
    private handleAgentTyping(event: AgentTypingEvent) {

        if (event.data === TypingState.Typing) {
            this.domElement.appendChild(this.agentTyping);
        } else {
            this.agentTyping.remove();
        }

        // scroll to bottom since the messages container expanded
        this.dispatchEvent(Utility.Methods.resizeContainer());
    }

    private handleUserTyping(event: UserTypingEvent) {
        this.dispatchEvent(Utility.Methods.scrollToBottom(false, true));
    }

    private handleHideCtaSystemMessage(event: HideCtaSystemMessageEvent) {
        this.domElement.querySelectorAll('.cw__message.cw__message-cta-system').forEach(element => {
            element.remove();
        });
    }

    private handleShowTextInputEvent(event: TextInputEvent) {
        setTimeout(() => {
            this.dispatchEvent(scrollToBottom())
        }, 100)
    }

}
