import {AbstractEvent} from "../../AbstractEvent";
import {ErrorEventData} from "../types";
import {ErrorHandlingEvents} from "../events";

/**
 * Should be dispatched, when a provider error has occurs
 */
class ErrorEvent extends AbstractEvent<ErrorEventData> {
    static NAME = ErrorHandlingEvents.onError;
}

export default ErrorEvent;