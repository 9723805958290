import {ComponentInterface} from "../components/GeneralComponent";
import AbstractSubscriber from "./AbstractSubscriber";
import SystemMessage from "../components/General/Messages/SystemMessage";
import AgentMessage from "../components/General/Messages/AgentMessage";
import UserMessage from "../components/General/Messages/UserMessage";
import CtaSystemMessage from "../components/General/Messages/CtaSystemMessage";
import RenderCtaSystemMessageEvent from "../events/messaging/events/RenderCtaSystemMessageEvent";
import RenderBasicMessageEvent from "../events/messaging/events/RenderBasicMessageEvent";
import {BasicMessageType} from "../types";
import {Messaging} from "../events/messaging";
import EnableMessageQueueEvent from '../events/messaging/events/EnableMessageQueueEvent';
import {UtilityMethods} from '../events/utility/methods';
import {Utility} from '../events/utility';
import DropdownSearchMessageEvent from "../events/messaging/events/DropdownSearchEvent";
import DropdownSearchMessage from "../components/General/Messages/DropdownSearchMessage";

/**
 * This class is used to transform message events containing strings to its bound components and render it
 */
class MessageTransformingSubscriber extends AbstractSubscriber {

    public currentMessageQueue = 0;
    public currentMessageQueueConfiguration?;


    protected onConstructor() {

        this.registerEventHandler(Messaging.Events.onRenderBasicMessage, [
            this.transformUserMessage.bind(this),
            this.transformAgentMessage.bind(this),
            this.transformSystemMessage.bind(this),
        ]);

        this.registerEventHandler(Messaging.Events.onRenderCtaSystemMessage, this.transformCtaSystemMessage.bind(this));
        this.registerEventHandler(Messaging.Events.onEnableMessageQueue, this.handleEnableMessageQueueEvent.bind(this));
        this.registerEventHandler(Messaging.Events.onDropdownSearchMessage, this.transformDropdownSearchMessage.bind(this));
    }

    /**
     * Handles user messages
     * @param event
     */
    private transformUserMessage(event: RenderBasicMessageEvent) {

        if(event.data.type !== BasicMessageType.User) {
            return;
        }

        const {message} = event.data;
        const userMessageComponent = new UserMessage(this.chatWindow, {
            message
        });

        this.renderComponent(userMessageComponent);

    }

    /**
     * Handles Agent messages
     * @param event
     */
    private transformAgentMessage(event: RenderBasicMessageEvent) {

        if(event.data.type !== BasicMessageType.Agent) {
            return;
        }

        const {message} = event.data;
        const agentMessageComponent = new AgentMessage(this.chatWindow, {
            message,
            icon: event.data.icon,
            relatedContent: event.data.relatedContent,
            isFirstMessage: event.data.isFirstMessage
        });

        this.renderComponent(agentMessageComponent);

    }

    /**
     * Handles system messages
     * @param event
     */
    private transformSystemMessage(event: RenderBasicMessageEvent) {

        if(event.data.type !== BasicMessageType.System) {
            return;
        }

        const {message} = event.data;
        const systemMessageComponent = new SystemMessage(this.chatWindow, {
            message
        });

        this.renderComponent(systemMessageComponent);

    }

    /**
     * Renders a CTA system message
     * @param event
     */
    private transformCtaSystemMessage(event: RenderCtaSystemMessageEvent) {

        const ctaSystemMessage = new CtaSystemMessage(this.chatWindow, event.data);
        this.renderComponent(ctaSystemMessage);

    }

    /**
     * Renders a dropdown search message
     * @param event
     */
    private transformDropdownSearchMessage(event: DropdownSearchMessageEvent) {
        const dropdownSearchMessage = new DropdownSearchMessage(this.chatWindow, event.data);
        this.renderComponent(dropdownSearchMessage);
    }

    /**
     * Renders the component
     * @param component
     */
    private renderComponent(component: ComponentInterface): void {
        this.dispatchEvent(Messaging.Methods.renderComponentAsMessage(component));
    }

    private handleEnableMessageQueueEvent(event: EnableMessageQueueEvent) {
        this.currentMessageQueue = 0;
        if(event.data.enabled) {
            this.currentMessageQueueConfiguration = event.data;
        } else {
            this.currentMessageQueueConfiguration = null;
        }
    }

    public calculateMessageDelay(num?: number): number {

        if(!this.currentMessageQueueConfiguration) {
            return 0;
        }

        const waitingTime = this.currentMessageQueue * this.currentMessageQueueConfiguration.wait;
        if(num) {
            return waitingTime + num;
        } else {
            this.currentMessageQueue++;
            return waitingTime;
        }

    }

    public decreaseMessageQueue() {
        if(this.currentMessageQueue) {
            this.currentMessageQueue--;
        }
        if(this.currentMessageQueue === 0) {
            this.dispatchEvent(Utility.Methods.scrollToBottom(true, true))
            setTimeout(() => {
                this.dispatchEvent(Utility.Methods.scrollToBottom(true, true))
            }, 200)
        }
    }

    public withDelay(ref: () => void, addition?: number) {
        const delay = this.calculateMessageDelay(addition);
        setTimeout(() => {
            this.decreaseMessageQueue();
            ref();
            setTimeout(() => {
                this.dispatchEvent(Utility.Methods.scrollToBottom(false, true))
            }, 200)
        }, delay);
    }

}

export default MessageTransformingSubscriber;
