import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import {FooterWrapper} from "../General/Content/FooterWrapper";
import AnimationHelper from "../../helpers/AnimationHelper";
import {Interaction} from "../../events/interaction";
import {NotificationInteractionConfig} from "../../events/interaction/types";
import NotificationEvent from "../../events/interaction/events/NotificationEvent";

class Notification extends GeneralComponent<any> implements ComponentInterface {

    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.onNotification, this.handleShowDirectCallEvent.bind(this));
        this.registerEventHandler(Interaction.Events.onHideInteraction, this.onHide.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.parentElement = parentElement as HTMLElement;
    }

    private static generateDirectCallActions(configuration: NotificationInteractionConfig): HTMLElement {
        return (
            <FooterWrapper displayTop={configuration.displayTop}>
                <div class="cw__direct-call direct-call__container direct-call__notification direct-call__error">
                    <p class="c-copy">{configuration.label}</p>
                </div>
            </FooterWrapper>
        )
    }

    private handleShowDirectCallEvent(event: NotificationEvent) {
        this.domElement = Notification.generateDirectCallActions(event.data);
        AnimationHelper.animateDirectCall(this.domElement);
        this.visible = true;
    }

    private onHide() {
        this.visible = false;
        this.domElement = null;
    }

    set visible(visible: boolean) {
        if (visible && !this.parentElement.contains(this.domElement)) {
            this.parentElement.appendChild(this.domElement);
        } else if (!visible && this.parentElement.contains(this.domElement)) {
            this.domElement.remove();
        }
    }


}

export default Notification;
