import JSX from "../../../../../jsx"

import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";
import {MessageType, ProductIcon} from "../../../../../types";
import GeneralMessage from "../../../../../components/General/Messages/GeneralMessage";
import {SideWindowOptions} from "../../events/interaction/types";
import {Interaction} from "../../events/interaction";
import {CloseSideWindowEvent, ShowSideWindowEvent} from "../../events/interaction/events/SideWindowEvent";

interface SideWindowAgentMessageProps {
    message: string,
    sideWindow: SideWindowOptions
}

class SideWindowAgentMessage extends GeneralComponent<SideWindowAgentMessageProps> implements ComponentInterface {

    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.showSideWindowShowInteraction, this.hideCta.bind(this));
        this.registerEventHandler(Interaction.Events.closeSideWindowShowInteraction, this.showCta.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.generateHTML();
        parentElement.appendChild(this.domElement);
    }

    public generateHTML() {
        this.domElement =
            <GeneralMessage
                type={MessageType.Received}
                icon={ProductIcon.Bot}
                text={this.props.message}
                ctaText={{
                    label: this.chatWindow.translation.translate('sidewindow.re-open'),
                    onClick: (e) => {
                        this.dispatchEvent(Interaction.Methods.showSideWindow({
                            ...this.props.sideWindow
                        }));
                    }
                }}
            >
            </GeneralMessage>
    }

    private hideCta(event: ShowSideWindowEvent) {
        if (event.data.title === this.props.sideWindow.title) {
            this.domElement.querySelector('.ctaTextLink').classList.add('cw__hidden');
        } else {
            this.domElement.querySelector('.ctaTextLink').classList.remove('cw__hidden');
        }
    }

    private showCta(event: CloseSideWindowEvent) {
        this.domElement.querySelector('.ctaTextLink').classList.remove('cw__hidden');
    }
}

export default SideWindowAgentMessage;
