class AnimationHelper {

    static animateReceivedMessage(element: HTMLElement) {
    };

    static animateDirectCall(element: HTMLElement, displayTop?: boolean) {
        element.classList.add(displayTop ? 'cw__footer__fade-in-top' : 'cw__footer__fade-in');
        AnimationHelper.animateRemoval(element);
    }

    static animateRemoval(element: HTMLElement) {
        //@ts-ignore
        /*element._remove = element.remove;
        element.remove = () => {
            element.classList.add('cw__footer__fade-out');
            setTimeout(() => {
                //@ts-ignore
                element._remove();
            }, 200)
        }*/
    }

}

export default AnimationHelper;
