import ConfigurationService from "./ConfigurationService";

/**
 * This service is able to put and read things into the localstorage
 * It also adds a prefix to the local storage key which comes from the root html node (key=localstoragePrefix)
 *
 *      <div class="centered" id="liveperson" data-config='{"provider":"liveperson","localstoragePrefix":"liveperson"}'></div>
 *
 *          -> prefix = liveperson
 *
 * @example
 *
 *      const data = {foo: 'bar'}
 *      PersistenceService.set('test', data) -> void
 *      PersistenceService.get('test') -> {foo: 'bar'}
 */
class PersistenceService {

    private configuration: ConfigurationService;

    constructor(configuration: ConfigurationService) {
        this.configuration = configuration;
    }

    /**
     * Stores the data into the localstorage
     * @param key
     * @param data
     */
    public set(key: string, data: any) {
        localStorage.setItem(this.getPrefixedKey(key), JSON.stringify(data));
    }

    public setSessionStorage(key: string, data: any) {
        sessionStorage.setItem(this.getPrefixedKey(key), JSON.stringify(data));
    }

    /**
     * Removes the key
     * @param key
     */
    public remove(key: string) {
        localStorage.removeItem(this.getPrefixedKey(key));
    }

    public removeSessionStorage(key: string) {
        sessionStorage.removeItem(this.getPrefixedKey(key));
    }

    /**
     * Reads and parses the data from the localstorage
     * @param key
     * @return any|null
     */
    public get(key: string): any | null {
        const prefixedKey = this.getPrefixedKey(key);
        if (!localStorage.getItem(prefixedKey)) {
            return null;
        }

        return JSON.parse(localStorage.getItem(this.getPrefixedKey(key)));
    }

    public getSessionStorage(key: string): any | null {
        const prefixedKey = this.getPrefixedKey(key);
        if (!sessionStorage.getItem(prefixedKey)) {
            return null;
        }

        return JSON.parse(sessionStorage.getItem(this.getPrefixedKey(key)));
    }

    /**
     * Creates the localstorage prefix
     * @param key
     */
    private getPrefixedKey(key: string): string {
        return `cw__${key}`;
    }

}

export default PersistenceService
