import InbentaSubscriber from "./InbentaSubscriber";
import UserMessageEvent from "../../../../events/messaging/events/UserMessageEvent";
import {Messaging} from "../../../../events/messaging";
import {Interaction} from "../../../../events/interaction";
import {Interaction as InbentaInteraction} from "../events/interaction";
import {ChatState} from "../definition/chatState";
import {SidewindowStorageKey} from "../definition/sidewindow";
import {SideWindowOptions} from "../events/interaction/types";

class UserMessageSubscriber extends InbentaSubscriber {

    public register() {
        this.registerEventHandler(Messaging.Events.onUserMessage, [
            this.renderUserMessage.bind(this),
            this.handleUserMessage.bind(this)
        ]);
    }

    public unregister() {
        this.unloadEventHandler(Messaging.Events.onUserMessage);
        this.unloadEventHandler(Messaging.Events.onUserMessage);
    }

    /**
     * Renders the user message
     * @param event
     */
    public renderUserMessage(event: UserMessageEvent) {
        this.dispatchEvent(Messaging.Methods.renderUserMessage(event.data.message));
        this.dispatchEvent(Interaction.Methods.hideChoiceInteraction());

        const sideWindowStorage = this.inbenta.api.persistence.get(SidewindowStorageKey);
        if (!!sideWindowStorage) {
            this.inbenta.dispatchEvent(InbentaInteraction.Methods.closeSideWindow(sideWindowStorage as SideWindowOptions))
        }
    }

    /**
     * Handles the user message response
     * @param event
     */
    public async handleUserMessage(event: UserMessageEvent) {

        const {message} = event.data;

        if (this.inbenta.context.chatState && this.inbenta.context.chatState.type === ChatState.Survey) {
            this.inbenta.context.chatState.callback(message);

            this.inbenta.context.chatState = {
                type: ChatState.Chatting
            }
        } else {
            await this.inbenta.conversation.sendMessage({
                message
            });
        }

        if (this.inbenta.directClose) {
            this.inbenta.directClose = false;
        }
    };

}

export default UserMessageSubscriber;
