import AbstractSubscriber from "./AbstractSubscriber";
import {AbstractEvent} from "../events/AbstractEvent";
import RestoreInteractionEvent from "../events/interaction/events/RestoreInteractionEvent";
import {Interaction} from "../events/interaction";
import {InteractionConfig} from "../events/interaction/types";

class FooterEventsSubscriber extends AbstractSubscriber {

    public latestDirectCallEvents: AbstractEvent<InteractionConfig>[] = [];
    public latestNotRestoreAbleEvent: AbstractEvent<InteractionConfig>;

    protected onConstructor() {

        this.registerEventHandler(Interaction.Events.onTextInput, [
            this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onSingleChoice, [
            this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onMultipleChoice, [
            this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onStarRating, [
            this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onNotification, [
           this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onPreventInteraction, [
            this.handleLatestDirectCallEvent.bind(this)
        ]);

        this.registerEventHandler(Interaction.Events.onRestoreInteraction, this.restoreLatestDirectCallEvent.bind(this));
    }

    private handleLatestDirectCallEvent(event: AbstractEvent<InteractionConfig>) {

        this.dispatchEvent(Interaction.Methods.hideInteraction());

        if (event.data && !event.data.doNotRestore) {
            this.latestDirectCallEvents.push(event);
        }

        if(event.data && event.data.doNotRestore) {
            this.latestNotRestoreAbleEvent = event;
        }
    }

    private restoreLatestDirectCallEvent(event: RestoreInteractionEvent) {

        if (event.data && event.data.removeCached === true) {
            this.latestDirectCallEvents = [];
        }

        if(event.data && event.data.removeConditional && typeof event.data.removeConditional === 'function') {
            this.latestDirectCallEvents = event.data.removeConditional(this.latestDirectCallEvents);
        }

        if (!(event.data && (event.data.removeCached || event.data.removeConditional)) && this.latestDirectCallEvents && this.latestDirectCallEvents.length) {
            const latestDirectCall = this.latestDirectCallEvents.pop();

            if(event.data.reInitOnDispatch && latestDirectCall.onDispatch && typeof latestDirectCall.onDispatch === 'function') {
                this.dispatchEvent(latestDirectCall, latestDirectCall.onDispatch)
            } else {
                this.dispatchEvent(latestDirectCall);
            }

        }

    }

}

export default FooterEventsSubscriber;
