export enum ProductIcon {
    Agent = "product-person",
    Bot = "speech-bubble-dots-o",
    Survey = "chat-o",
    ErrorCritical = "exclamation-triangle-o",
    ErrorMajor = "exclamation-triangle-o",
    SystemMessage = "product-light-bulb-idea",
    ChevronDown = "chevron-down",
    Print = 'print-o',
    Email = 'e-mail-read-o'
}

export enum MessageType {
    Sent = 'sent',
    Received = 'received',
    Critical = 'critical',
    Major = 'major'
}

export enum BasicMessageType {
    Agent,
    User,
    System
}

export enum Asset {
    ConversationEnd = "/thank-you.svg",
    Question = "/question.png",
    Welcome = "/welcome.png",
    Survey = "/survey.png",
    Timer = "/timer.svg"
}

export enum SurveyType {
    Print = "api/survey/print"
}
