import AbstractHandler from "./AbstractHandler";
import {DirectCall} from "../../definition/directCall";
import {Interaction} from "../../../../../events/interaction";
import {Answer} from "../../definition/answerTypes";
import {Messaging} from "../../../../../events/messaging";

class CloseChatbotHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith('chatbot_schliessen');
    }

    public handle(answer: Answer) {
        this.inbenta.api.makeChatWindowInactive();
        this.inbenta.chatWindow.footerEvents.latestDirectCallEvents = [];

        setTimeout(() => {

            if (this.inbenta.allowBackOnClose) {
                this.inbenta.api.renderClosingDialog(true, () => {
                    this.inbenta.util.renderInputBox();
                });
            } else {
                this.inbenta.directClose = true;
                this.inbenta.api.renderClosingDialog(false);
            }


        }, 3000);
    }

}

export default CloseChatbotHandler;
