import JSX from "../../../jsx";

import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";

interface AnswerComponentProps {
    message: string
}

class SystemMessage extends GeneralComponent<AnswerComponentProps> implements ComponentInterface {

    public render(parentElement: HTMLElement | Element) {
        this.generateMessage();
        parentElement.appendChild(this.domElement);
    }

    private generateMessage() {
        this.domElement = (
            <div class="cw__message cw__message-system cw__message--slide-in">
                <div class="cw__message cw__message-system__content">
                    <p class="c-copy">{this.props.message}</p>
                </div>
            </div>
        );
    }

}

export default SystemMessage;
