import JSX from "../../../jsx";
import {Asset} from '../../../types';
import AssetHelper from '../../../helpers/AssetHelper';

export const FooterWrapper = (props: {
    label?: string,
    labelTop?: boolean,
    labelAlignLeft?: boolean,
    displayTop?: boolean,
    children?: any,
    onLabelClick?: () => void,
    asset?: Asset
}) => {
    return <div class={`cw__footer__wrapper ${props.displayTop ? 'cw__footer--display-top' : 'cw__footer--display-bottom'}`} role="alert">
        <div class="cw__footer">
            {
                props.labelTop && props.label ?
                    <div class={props.labelAlignLeft ? "cw__footer__label cw__footer__label-top cw__footer__label-align_left" : "cw__footer__label cw__footer__label-top"}>
                        {props.onLabelClick ? <a onclick={props.onLabelClick} class="c-copy">{props.label}</a> : <p class="c-copy">{props.label}</p>}
                    </div>
                : null
            }
            {props.displayTop && props.asset ? <div class="cw__footer__image" innerHTML={AssetHelper.renderAssetAsImage(props.asset)}/> : null}
            {props.label && props.displayTop ? <div class="cw__footer__label">
                {props.onLabelClick ? <a onclick={props.onLabelClick} class="c-copy">{props.label}</a> : <p class="c-copy">{props.label}</p> }
            </div> : null}
            {props.children}

            {props.label && !props.displayTop && !props.labelTop ?
                <div class="cw__footer__label">
                    {props.onLabelClick ? <a onclick={props.onLabelClick} class="c-copy">{props.label}</a> : <p class="c-copy">{props.label}</p>}
                </div> : null}
        </div>
    </div>
};
