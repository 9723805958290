import JSX from "../../../jsx";

interface DirectCallWrapperProps {
    ariaLabel: string,
    children?: any
    withoutFlex?: boolean
}

export const DirectCallContainer = (props: DirectCallWrapperProps) => {
    return (
        <div class="cw__direct-call direct-call__container" style={props.withoutFlex ? 'display: block' : ''} aria-label={props.ariaLabel}>{props.children ? props.children : null}</div>
    )
};
