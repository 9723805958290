import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";

class ContactCallbackHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith("rueckruf_vereinbaren");
    }

    public handle(answer: Answer) {
        setTimeout(() => {
            this.inbenta.chatBotService.openUserAssistantPhone();
            this.inbenta.chatWindow.api.shutdownService.executeShutdown();
        })
    }

}

export default ContactCallbackHandler;
