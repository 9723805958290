export namespace MessagingEvents {

    export const onUserMessage = "events/messaging/user-message";
    export const onUserInteraction = "events/messaging/user-interaction";
    export const onRenderMessageComponent = "events/messaging/render-message-component";
    export const onRenderCtaSystemMessage = "events/messaging/render-cta-system-message";
    export const onRenderBasicMessage = "events/messaging/render-basic-message";
    export const onHideCtaSystemMessage = "events/messaging/hide-cta-system-message";
    export const onEnableMessageQueue = "events/messaging/enable-message-queue";
    export const onDropdownSearchMessage = "events/messaging/dropdown-search-message";
}
