import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import TextInput from "../Interaction/TextInput";
import SingleChoice from "../Interaction/SingleChoice";
import MultipleChoice from "../Interaction/MultipleChoice";
import StarRating from "../Interaction/StarRating";
import Notification from "../Interaction/Notification";
import Loader from '../Body/Loader';

export default class Footer extends GeneralComponent<any> implements ComponentInterface {

    render(parentElement: HTMLElement | Element) {

        this.generateFooterElement();
        parentElement.appendChild(this.domElement);

        const inputBox = new TextInput(this.chatWindow);
        inputBox.render(this.domElement);

        const directCallSingle = new SingleChoice(this.chatWindow);
        directCallSingle.render(this.domElement);

        const directCallMultiple = new MultipleChoice(this.chatWindow);
        directCallMultiple.render(this.domElement);

        const directCallStars = new StarRating(this.chatWindow);
        directCallStars.render(this.domElement);

        const directCallNotification = new Notification(this.chatWindow);
        directCallNotification.render(this.domElement);

        const loader = new Loader(this.chatWindow);
        loader.render(this.domElement);

    }

    private generateFooterElement() {
        this.domElement = <div style="padding-top: 2px;"/>
    }

}
