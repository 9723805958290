import UserMessageEvent from "./events/UserMessageEvent";
import UserInteractionEvent from "./events/UserInteractionEvent";
import {RelatedContent, UserInteractionEventConfig} from "./types";
import RenderBasicMessageEvent from "./events/RenderBasicMessageEvent";
import {BasicMessageType, ProductIcon} from "../../types";
import {ComponentInterface} from "../../components/GeneralComponent";
import RenderMessageComponentEvent from "./events/RenderMessageComponentEvent";
import RenderCtaSystemMessageEvent from "./events/RenderCtaSystemMessageEvent";
import HideCtaSystemMessageEvent from './events/HideCtaSystemMessageEvent';
import EnableMessageQueueEvent from './events/EnableMessageQueueEvent';
import {AgentMessageCta} from "../../components/General/Messages/AgentMessage";
import DropdownSearchMessageEvent from "./events/DropdownSearchEvent";

export namespace MessagingMethods {

    /**
     * Submits a user message
     * @param message
     */
    export const submitUserMessage = (message: string) => {
        return new UserMessageEvent({
            message
        })
    };

    /**
     * Submits a user interaction
     * - Is currently LivePerson specific
     * @param data
     */
    export const submitUserInteraction = (data: UserInteractionEventConfig) => {
        return new UserInteractionEvent(data);
    };

    /**
     * Marks the user interaction as skipped
     * - Is currently LivePerson specific
     * @param id
     */
    export const skipUserInteraction = (id: number) => {
        return new UserInteractionEvent({
            skipped: true,
            id,
            displayValue: '',
            value: '',
        })
    };

    /**
     * Renders a user message
     * @param message
     * @param optionalConfig
     */
    export const renderUserMessage = (message: string, optionalConfig?: {timestamp?: Date}) => {
        return new RenderBasicMessageEvent({
            type: BasicMessageType.User,
            message,
            ...optionalConfig
        })
    };

    /**
     * Renders a agent message
     * @param message
     * @param optionalConfig
     */
    export const renderAgentMessage = (message: string, optionalConfig?: {timestamp?: Date, icon?: ProductIcon, cta?: AgentMessageCta, isFirstMessage?: boolean}) => {
        return new RenderBasicMessageEvent({
            type: BasicMessageType.Agent,
            message,
            ...optionalConfig
        })
    };

    /**
     * Renders an agent message including related content
     * @param message
     * @param relatedContent
     * @param optionalConfig
     */
    export const renderRelatedContentMessage = (message: string, relatedContent: RelatedContent, optionalConfig?: {timestamp?: Date, icon?: ProductIcon}) => {
        return new RenderBasicMessageEvent({
            type: BasicMessageType.Agent,
            message,
            relatedContent,
            ...optionalConfig
        })
    };

    /**
     * Renders a system message
     * @param message
     * @param optionalConfig
     */
    export const renderSystemMessage = (message: string, optionalConfig?: {timestamp?: Date}) => {
        return new RenderBasicMessageEvent({
            type: BasicMessageType.System,
            message,
            ...optionalConfig
        })
    };

    /**
     * Renders a CTA system message
     * @param message
     * @param onClick
     */
    export const renderCtaSystemMessage = (message: string, onClick: () => void) => {
        return new RenderCtaSystemMessageEvent({
            label: message,
            onClick
        })
    };

    /**
     * Renders the given component as a message
     * @param component
     * @param postRender
     */
    export const renderComponentAsMessage = (component: ComponentInterface, postRender?: () => void) => {
        return new RenderMessageComponentEvent({
            component,
            callback: postRender
        })
    };

    export const renderDropdownSearchMessage = (data: string[]) => {
        return new DropdownSearchMessageEvent({
            data
        })
    }

    /**
     * Hides all the CTA system messages
     */
    export const hideCtaSystemMessage = () => {
        return new HideCtaSystemMessageEvent()
    };

    /**
     * Enables the message queue
     * @param wait
     */
    export const enableMessageQueue = (wait: number = 150) => {
        return new EnableMessageQueueEvent({
            enabled: true,
            wait
        })
    };

    /**
     * Disables the message queue
     */
    export const disableMessageQueue = () => {
        return new EnableMessageQueueEvent({
            enabled: false
        })
    }

}
