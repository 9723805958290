import JSX from "../../../../../jsx"

import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";
import Header from "./Header";
import {Interaction} from "../../events/interaction";
import Body from "./Body";
import {CloseSideWindowEvent, ShowSideWindowEvent} from "../../events/interaction/events/SideWindowEvent";

class SideWindow extends GeneralComponent<any> implements ComponentInterface {

    private header: Header;
    private body: Body;
    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.showSideWindowShowInteraction, this.generateContent.bind(this));
        this.registerEventHandler(Interaction.Events.closeSideWindowShowInteraction, this.removeSideWindow.bind(this));
    }

    public render(parentElement?: HTMLElement) {
        this.parentElement = parentElement as HTMLElement;
    }

    public generateContent(event: ShowSideWindowEvent) {
        if (this.domElement) {
            this.removeSideWindow();
            this.dispatchEvent(Interaction.Methods.closeSideWindow({
                ...event.data
            }));
        }

        const {chatContainer} = this.buildSideWindowContent();
        this.parentElement.appendChild(this.domElement);
        this.header = new Header(this.chatWindow, {
            title: event.data.title,
            closeCallback: () => {
                this.dispatchEvent(Interaction.Methods.closeSideWindow({
                    ...event.data
                }));
            }
        });
        this.header.render(chatContainer);

        this.body = new Body(this.chatWindow, {content: event.data.content});
        this.body.render(chatContainer);
    }

    public removeSideWindow() {
        this.domElement.remove();
        this.domElement = undefined;
    }

    public buildSideWindowContent() {

        this.domElement = (
            <div class="cw__user-assistant_side_window cw__assistant-frame show" role="region" aria-label="Allianz Live-Chat side window" tabindex="0" id="cw_chat-sidewindow">
                <div class="cw__container" />
            </div>
        );

        return {
            chatContainer: this.domElement.querySelector('.cw__container'),
            headerContainer: this.domElement.querySelector('.cw__header__container')
        };

    }
}

export default SideWindow;
