import {
    AgentType,
    BasicInteractionConfig,
    FilterCachedInteractions,
    MultipleChoiceOption,
    NotificationType, PreventInteractionConfig, RatingsAction,
    SingleChoiceAction,
    StarOption, TextInputInteractionConfig
} from './types';
import TextInputEvent from "./events/TextInputEvent";
import SingleChoiceEvent from "./events/SingleChoiceEvent";
import MultipleChoiceEvent from "./events/MultipleChoiceEvent";
import RatingsEvent from "./events/RatingsEvent";
import NotificationEvent from "./events/NotificationEvent";
import StarRatingEvent from "./events/StarRatingEvent";
import PreventInteractionEvent from "./events/PreventInteractionEvent";
import RestoreInteractionEvent from "./events/RestoreInteractionEvent";
import HideInteractionEvent from './events/HideInteractionEvent';
import HideChoiceInteractionEvent from "./events/HideChoiceInteractionEvent";
import SetPlaceholderEvent from './events/SetPlaceholderEvent';
import EnableDateMaskEvent, {DateMaskConfig} from "./events/EnableDateMaskEvent";
import AgentChangeEvent from "./events/AgentChangeEvent";
import TextInputResetEvent from "./events/TextInputResetEvent";
import ShowInteractionEvent from "./events/ShowInteractionEvent";
import DisableInputMask from "./events/DisableInputMask";

export namespace InteractionMethods {

    /**
     * Shows an text input
     */
    export const showTextInput = (placeholder: string, config?: TextInputInteractionConfig) => {
        return new TextInputEvent({
            show: true,
            ...config,
            placeholder
        })
    };

    /**
     * Shows a single choice element
     * @param actions
     * @param config
     */
    export const showSingleChoice = (actions: SingleChoiceAction[], config?: BasicInteractionConfig) => {
        return new SingleChoiceEvent({
            show: true,
            ...config,
            actions,
        })
    };

    /**
     * Shows an multiple choice interaction component
     * @param options
     * @param onSubmit
     * @param config
     */
    export const showMultipleChoice = (options: MultipleChoiceOption[], onSubmit: (filledOptions: MultipleChoiceOption[]) => void, config?: BasicInteractionConfig) => {
        return new MultipleChoiceEvent({
            show: true,
            options,
            onSubmit,
            ...config
        })
    };

    export const showRatings = (actions: RatingsAction[], config?: BasicInteractionConfig) => {
        return new RatingsEvent({
            show: true,
            ...config,
            actions,
        })
    };


    /**
     * Shows a notification
     * @param message
     * @param type
     * @param config
     */
    export const showNotification = (message: string, type?: NotificationType, config?: BasicInteractionConfig) => {
        return new NotificationEvent({
            show: true,
            type,
            ...config,
            label: message
        });
    };

    /**
     * Hides a notification
     */
    export const hideNotification = () => {
        return new NotificationEvent({
            show: false,
            doNotRestore: true
        })
    };

    /**
     * Shows a star rating
     * @param stars
     * @param onSubmit
     * @param config
     */
    export const showStarRating = (stars: StarOption[], onSubmit: (selectedStar: StarOption) => void, config?: BasicInteractionConfig) => {
        return new StarRatingEvent({
            show: true,
            data: stars,
            onSubmit,
            ...config
        })
    };

    /**
     * Prevents / Hides all interaction elements
     */
    export const preventInteraction = (config?: PreventInteractionConfig) => {
        return new PreventInteractionEvent(config);
    };

    /**
     * Removes the interaction cache, so they are not restore able anymore
     */
    export const removeCachedInteractionsCompletely = () => {
        return new RestoreInteractionEvent({
            removeCached: true
        })
    };

    /**
     * Removes the interaction cache using a Array.filter function, so you decide which interactions are deleted
     * @param conditionFunc
     */
    export const removeCachedInteractionsConditional = (conditionFunc: FilterCachedInteractions) => {
        return new RestoreInteractionEvent({
            removeConditional: conditionFunc
        })
    };

    /**
     * Re-Initializes the last cached interaction
     * @param reInitOnDispatch
     */
    export const restoreLastCachedInteraction = (reInitOnDispatch?: boolean) => {
        return new RestoreInteractionEvent({reInitOnDispatch});
    };

    /**
     * Shows the current interaction
     */
    export const showInteraction = () => {
        return new ShowInteractionEvent();
    };

    /**
     * Hides the current interaction
     */
    export const hideInteraction = () => {
        return new HideInteractionEvent();
    };

    /**
     * Hides the choice interaction component
     */
    export const hideChoiceInteraction = () => {
        return new HideChoiceInteractionEvent();
    };

    /**
     * Overwrites the placeholder
     * @param placeholder
     */
    export const setPlaceholder = (placeholder: string) => {
        return new SetPlaceholderEvent({
            placeholder
        });
    }

    /**
     * set the current agent type
     * @param type
     */
    export const setAgentType = (type: AgentType) => {
        return new AgentChangeEvent({
            type
        })
    }

    /**
     * Enables the date mask
     */
    export const enableDateMask = (data: DateMaskConfig) => {
        return new EnableDateMaskEvent(data);
    }

    export const enableBirthDateMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: Date
            },
            validation: /^(?:(?:31(?:\.)(?:0?[13578]|1[02]))(?:\.)|(?:(?:29|30)(?:\.)(?:0?[13-9]|1[0-2])(?:\.)))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(?:\.)0?2(?:\.)(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(?:\.)(?:(?:0?[1-9])|(?:1[0-2]))(?:\.)(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
            placeholder: 'TT.MM.JJJJ'
        })
    }

    export const enableInsuranceSumMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: 'num €',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: '.'
                    }
                }
            },
            placeholder: "Versicherungssumme in €"
        })
    }

    export const enableEinkommenSumMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: 'num €',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: '.'
                    }
                }
            },
            placeholder: "Bruttojahreseinkommen in €"
        })
    }

    export const enableKreditSumMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: 'num €',
                blocks: {
                    num: {
                        mask: Number,
                        thousandsSeparator: '.'
                    }
                }
            },
            placeholder: "Kreditsumme in €"
        })
    }

    export const enableContractDurationMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: Number,
                min: 0,
                max: 100
            },
            placeholder: "Laufzeit in Jahren"
        })
    }

    /**
     * Disables the date mask
     */
    export const disableInputMask = () => {
        return new DisableInputMask();
    }

    export const enableZipMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: '00000',
            },
            validation: /^[\d]{5}$/,
            placeholder: 'PLZ'
        });
    }

    export const enablePreviousDamageMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: Number,
                min: 0,
                max: 9
            },
            validation: /^[0-9]\d{0}$/,
            placeholder: 'Anzahl Vorschäden'
        })
    }

    export const enableSurgeryCountMask = () => {
        return new EnableDateMaskEvent({
            config: {
                mask: Number,
                min: 0,
                max: 50
            },
            validation: /^[0-9]\d{0}$/,
            placeholder: 'Anzahl der Operationen'
        });
    }

    /* resets text input */
    export const resetTextInput = () => {
        return new TextInputResetEvent();
    }

}
