import InbentaService from "./InbentaService";
import {InbentaConst} from '../../../../const';
import {ChatState, ChatStateContext} from "../definition/chatState";

class ContextService extends InbentaService {

    private _chatState: ChatStateContext;

    get apiKey(): string {
        return this.inbenta.chatWindow.configuration.inbenta.apiKey;
    }

    get domainKey(): string {
        return this.inbenta.chatWindow.configuration.inbenta.domainKey;
    }

    get chatState(): ChatStateContext {
        return this._chatState;
    }

    set chatState(context) {
        this._chatState = context;
    }

}

export default ContextService;
