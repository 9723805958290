import JSX from "../../../jsx";
import GeneralMessage from "./GeneralMessage";

import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import CtaMessageButton from "../Content/CtaMessageButton";
import {MessageType, ProductIcon} from "../../../types";
import {Window} from "../../../events/window";

import RelatedContentComponent from "./RelatedContent";

import {RelatedContent} from "../../../events/messaging/types";
import AgentMessageService from "../../../service/AgentMessageService";
import {ApiProvider} from "../../../api/providers/providers";


export interface AgentMessageProps {
    message: string,
    icon?: ProductIcon
    cta?: AgentMessageCta,
    ctaText?: AgentMessageCta,
    relatedContent?: RelatedContent,
    isFirstMessage?: boolean
}

export interface AgentMessageCta {
    label: string
    onClick?: () => void,
    removeOnClick?: boolean
}

class AgentMessage extends GeneralComponent<AgentMessageProps> implements ComponentInterface {

    public render(parentElement: HTMLElement | Element) {
        this.generateMessage();
        parentElement.appendChild(this.domElement);

        if (this.chatWindow.api.currentProvider.name === ApiProvider.Inbenta && this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage) {
            AgentMessageService.scrollToLatestFirstMessage();
        }

        if(this.props.relatedContent) {
            const relatedContent = new RelatedContentComponent(this.chatWindow, this.props.relatedContent);
            relatedContent.render(this.domElement.querySelector('.cw__message__content'));
        }

    }

    private generateMessage() {

        const icon = this.props.icon  ? this.props.icon : ProductIcon.Agent;

        this.domElement =
            <GeneralMessage
                type={MessageType.Received}
                isFirstMessage={this.props.isFirstMessage}
                icon={icon}
                text={this.props.message}
                ctaText={this.props.ctaText ? {
                    label: this.props.ctaText.label,
                    onClick: (e) => {
                        e.preventDefault();
                        this.props.ctaText.onClick();
                        if (this.props.ctaText.removeOnClick) {
                            this.domElement.remove();
                        }
                    }
                } : null}
            >
                {this.props.cta ? <CtaMessageButton onClick={this.handleCtaButtonClick.bind(this)} label={this.props.cta.label}/> : null}
            </GeneralMessage>;

        this.updateIcon(icon);
    }

    private handleCtaButtonClick(event) {
        event.preventDefault();
        const spacer = this.domElement.querySelector('.cw__util-pt-1');
        if (spacer) {
            spacer.remove();
        }
        event.currentTarget.remove();
        if (this.props.cta.onClick && typeof this.props.cta.onClick === 'function') {
            this.props.cta.onClick()
        }
    }

    /**
     * Updates the header icon based on the current message
     * @param icon
     */
    private updateIcon(icon: ProductIcon) {
        this.dispatchEvent(Window.Methods.setIcon(icon))
    }

}

export default AgentMessage;
