import AbstractHandler from "./AbstractHandler";
import {ChatState} from "../../definition/chatState";
import {SendEventType} from "../../definition/events";
import {DirectCall} from "../../definition/directCall";
import {Interaction} from "../../../../../events/interaction";
import {Answer} from "../../definition/answerTypes";

class SurveyFreeTextHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.SurveyFreeText);
    }

    public handle(answer: Answer) {
        this.inbenta.allowBackOnClose = true;

        const rateCode = answer.parameters.contents.trackingCode.rateCode;
        if (!rateCode) {
            return;
        }

        this.inbenta.context.chatState = {
            type: ChatState.Survey,
            callback: (message) => {

                // send survey fretext with user message
                const response = this.inbenta.trackingEventService.sendEvent({
                    type: SendEventType.Rate,
                    code: rateCode,
                    value: 3, // Use the number 3 because this is the survey comment identifier
                    comment: message
                });

                this.inbenta.conversation.sendMessage({directCall: DirectCall.SurveyComplete});
                this.inbenta.dispatchEvent(Interaction.Methods.hideInteraction());
            }
        };

        this.inbenta.chatWindow.messageTransforming.withDelay(() => {
            this.inbenta.dispatchEvent(Interaction.Methods.showTextInput(this.inbenta.chatWindow.translation.translate('survey_free_text.input_placeholder')))
        }, 1300);
    }

}

export default SurveyFreeTextHandler;
