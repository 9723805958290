import JSX from "../../../../../jsx"

import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";
import {MessageType, ProductIcon} from "../../../../../types";
import GeneralMessage from "../../../../../components/General/Messages/GeneralMessage";
import {RelatedContent as RelatedContentProps} from "../../../../../events/messaging/types";
import Icon from "../../../../../components/General/Content/Icon";
import ScrollToBottomEvent from "../../../../../events/utility/events/ScrollToBottomEvent";
import Environment from "../../../../../helpers/Environment";
import {UtilityMethods} from "../../../../../events/utility/methods";
import scrollToBottom = UtilityMethods.scrollToBottom;
import AgentMessageService from "../../../../../service/AgentMessageService";

class RelatedContent extends GeneralComponent<RelatedContentProps> implements ComponentInterface {

    private parentElement: HTMLElement;
    private isExpanded: boolean = false;

    public render(parentElement: HTMLElement | Element): void {
        this.generateHTML();
        parentElement.appendChild(this.domElement);

        if (this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage) {
            AgentMessageService.scrollToLatestFirstMessage();
        }
    }

    public generateHTML() {

        this.domElement =
            <GeneralMessage
                type={MessageType.Received}
                icon={ProductIcon.Bot}
                additionalContentMessageHtmlClass={"cw__message__content--grey"}
            >
                <div class="cw__related-content related-content__container">
                    <div class="cw__related-content related-content__header">
                        <button class={"button-flex"} style={Environment.isIE11() ? "width: 100%;" : ""} onclick={() => {
                            if(this.isExpanded) {
                                this.collapse();
                            } else {
                                this.expand();
                            }
                        }}>
                            <span class="c-copy">{this.props.label}</span>
                            <Icon icon={ProductIcon.ChevronDown}/>
                        </button>
                    </div>
                    <div class="cw__related-content related-content__content related-content__content--light-background  related-content__content--grey" style="display: none">
                        {this.props.entries.map(entry => {
                            return (
                                <div class="cw__related-content">
                                    <button
                                        tabindex="0"
                                        class={`c-button cw__direct-call direct-call__button c-button--secondary`}
                                        onclick={() => {
                                            entry.onClick(entry);
                                            this.collapse();
                                        }}
                                    >
                                        {entry.label}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </GeneralMessage>
    }

    private getContent(): HTMLElement {
        return this.domElement.querySelector('.cw__related-content.related-content__content');
    }

    /**
     *
     */
    private expand() {
        this.isExpanded = true;
        const content = this.getContent();
        content.style.display = '';
        this.domElement.classList.add('related-content__container--expanded');

        setTimeout(() => {
            this.domElement.scrollIntoView();
        }, 0);
    }

    /**
     *
     */
    private collapse() {
        this.isExpanded = false;
        const content = this.getContent();
        content.style.display = 'none';
        this.domElement.classList.remove('related-content__container--expanded');
    }

}

export default RelatedContent;
