import {DefinedTranslation, TranslationService} from "../service/TranslationService";

/**
 * Defines a translation
 * @param translationKey
 * @param translationValue
 * @param description
 */
export const define = (translationKey: string, translationValue: string, description?: string): DefinedTranslation => {
    return {
        translationKey,
        translationValue,
        description: description || ''
    }
};

/**
 * Exports the given translations with a locale
 * @param definedTranslations
 * @param locale
 */
export const withLocale = (definedTranslations: DefinedTranslation[], locale: string): DefinedTranslation[] => {
    return definedTranslations.map(translation => {
        return {
            locale,
            ...translation
        };
    });
};

/**
 *
 * @param definedTranslations
 * @param prefix
 */
export const withPrefix = (prefix: string, definedTranslations: DefinedTranslation[]): DefinedTranslation[] => {
    return definedTranslations.map(translation => {
        return {
            ...translation,
            translationKey: `${prefix}.${translation.translationKey}`
        }
    });
};

/**
 * Exports the given translations with a scope
 * @param definedTranslations
 * @param scope
 */
export const withScope = (definedTranslations: DefinedTranslation[], scope?: string): DefinedTranslation[] => {
    return definedTranslations.map(translation => {
        return {
            scope: !!scope ? scope : TranslationService.SCOPE_GLOBAL,
            ...translation
        }
    });
};
