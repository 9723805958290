import AbstractSubscriber from "./AbstractSubscriber";
import ProviderChangeEvent from "../events/api/events/ProviderChangeEvent";
import {Utility} from "../events/utility";
import {Api} from "../events/api";

/**
 * This subscriber listens for the ProviderChangeEvent and dispatches events depending on this
 */
class ProviderChangeSubscriber extends AbstractSubscriber {

    public onConstructor() {
        this.registerEventHandler(Api.Events.onProviderChange, this.handleProviderChange.bind(this));
    }

    private handleProviderChange(event: ProviderChangeEvent) {
        this.dispatchEvent(Utility.Methods.clearHistory());
    }

}

export default ProviderChangeSubscriber;