import JSX from "../../../jsx";
import GeneralMessage from "./GeneralMessage";
import {MessageType, ProductIcon} from "../../../types";

const AgentTypingMessage = (props: {icon?: ProductIcon}) => {
    return (
        <GeneralMessage type={MessageType.Received} icon={props.icon ? props.icon : ProductIcon.Agent}>
            <div class="cw__agent-typing">
                <div class="cw__agent-typing__wrapper">
                    <span className="cw__agent-typing_dot"/>
                    <span className="cw__agent-typing_dot"/>
                    <span className="cw__agent-typing_dot"/>
                </div>
            </div>
         </GeneralMessage>
    )
};

export default AgentTypingMessage;
