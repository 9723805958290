import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";

import Header from "../Header/Header";
import Body from "../Body/Body";
import Footer from "../Footer/Footer";
import StartupHelper from "../../helpers/StartupHelper";
import MinimizeEvent from "../../events/window/events/MinimizeEvent";
import {Window} from "../../events/window";
import {Utility} from "../../events/utility";
import UnreadMessages from "../Body/UnreadMessages";
import ProgressBar from '../ProgressBar/ProgressBar';

class ChatWindowContent extends GeneralComponent<any> implements ComponentInterface {

    private header: Header;
    private progressBar: ProgressBar;
    private body: Body;
    private footer: Footer;
    private unreadMessages: UnreadMessages;

    protected onConstructor() {
        this.registerEventHandler(Window.Events.onMinimize, this.handleMinimizeEvent.bind(this));
    }

    public render(parentElement?: HTMLElement) {

        const {chatContainer} = this.buildChatWindowContent();

        parentElement.appendChild(this.domElement);

        this.unreadMessages = new UnreadMessages(this.chatWindow, {});
        this.header = new Header(this.chatWindow, {});
        //this.progressBar = new ProgressBar(this.chatWindow, {});
        this.body = new Body(this.chatWindow, {});
        this.footer = new Footer(this.chatWindow, {});

        const isMinimized = StartupHelper.minimized;

        this.unreadMessages.render(chatContainer);
        this.header.render(chatContainer);
        //this.progressBar.render(chatContainer);
        this.body.render(chatContainer);

        if(isMinimized) {
            this.body.domElement.style.display = 'none';
        }

        this.footer.render(chatContainer);

        if(isMinimized) {
            this.footer.domElement.style.display = 'none';
        }

        if(!isMinimized) {
            this.domElement.focus();
        }

    }

    public buildChatWindowContent() {

        const initialHeight = StartupHelper.minimized ? 56 : StartupHelper.containerHeight;

        this.domElement = (
            <div class={`cw__user-assistant cw__assistant-frame show ${this.chatWindow.api.currentProvider.name}`} role="region" aria-label="Allianz Live-Chat" tabindex="0" id="cw_chat-window">
                <div class="cw__container" style={`height: ${initialHeight}px`} />
            </div>
        );

        return {
            chatContainer: this.domElement.querySelector('.cw__container'),
            headerContainer: this.domElement.querySelector('.cw__header__container')
        };

    }

    private handleMinimizeEvent(event: MinimizeEvent) {

        const container = this.domElement.querySelector('.cw__container') as HTMLElement;

        if(event.data) {
            this.body.domElement.style.display = 'none';
            this.footer.domElement.style.display = 'none';
            /*if(this.progressBar.isVisible) {
                this.progressBar.domElement.style.display = 'none';
            }*/
            container.style.height = '56px';
        } else {
            container.style.height = `${StartupHelper.containerHeight}px`;
            setTimeout(() => {
                this.body.domElement.style.display = '';
                this.footer.domElement.style.display = '';
                /*if(this.progressBar.isVisible) {
                    this.progressBar.domElement.style.display = '';
                }*/
                this.dispatchEvent(Utility.Methods.resizeContainer());
            }, 300);

        }
    }

}

export default ChatWindowContent;
