import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import {FooterWrapper} from "../General/Content/FooterWrapper";
import {DirectCallContainer} from "../General/Content/DirectCallWrapper";
import AnimationHelper from "../../helpers/AnimationHelper";
import {StarOption, StarRatingInteractionConfig} from "../../events/interaction/types";
import {Interaction} from "../../events/interaction";
import StarRatingEvent from "../../events/interaction/events/StarRatingEvent";
import {UtilityMethods} from '../../events/utility/methods';
import scrollToBottom = UtilityMethods.scrollToBottom;
import Environment from '../../helpers/Environment';

class StarRating extends GeneralComponent<any> implements ComponentInterface {

    private currentConfiguration: StarRatingInteractionConfig;
    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.onStarRating, this.handleShowDirectCallStarsEvent.bind(this));
        this.registerEventHandler(Interaction.Events.onHideInteraction, this.onHide.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.parentElement = parentElement as HTMLElement;
    }

    private generateStarsRating(configuration: StarRatingInteractionConfig) {

        const values = configuration.data;

        return (
            <FooterWrapper {...configuration}>
                <DirectCallContainer ariaLabel={"Sternebewertung"}>
                    <div class="cw__direct-call direct-call__starsrating-wrapper">
                        <div class="cw__direct-call direct-call__stars-value-container">
                            <span class="c-copy cw__direct-call direct-call__stars-value" style="opacity: 0;">
                                Sternebewertung
                            </span>
                        </div>
                        <div class="cw__direct-call direct-call__stars-container"
                             onmouseleave={this.removeActiveStarClasses.bind(this)}>
                            {values.map((value, index) => {
                                return this.generateStar(value, index, values.length)
                            })}
                        </div>
                    </div>
                </DirectCallContainer>
            </FooterWrapper>
        );
    }

    private generateStar(value: StarOption, index: number, totalAmount: number) {

        const star = (
            <div class="cw__direct-call direct-call__star" tabindex="0" role="button" aria-label={value.displayValue}>
                <div
                    innerHTML={`<svg viewBox="0 0 68 64" ${Environment.isIE11() ? `style="width: 35px; height: 32px;"` : ''}><path d="M44.82 20.08l22.61 4.33-15.76 16.81L54.53 64l-20.82-9.71L12.9 64l2.86-22.78L0 24.41l22.61-4.33L33.71 0z"></path></svg>`}/>
            </div>
        );

        star.addEventListener('mouseover', () => {

            let currentStarPassed = false;
            const stars = [].slice.call(this.domElement.querySelectorAll('.cw__direct-call.direct-call__star'));
            for (const currentStar of stars) {
                if (!currentStarPassed) {
                    currentStar.classList.add('direct-call__star-active');
                } else {
                    currentStar.classList.remove('direct-call__star-active')
                }
                if (currentStar === star) {
                    currentStarPassed = true;
                }
            }

            (this.domElement.querySelector('.cw__direct-call.direct-call__stars-value') as HTMLElement).style.opacity = '1';
            (this.domElement.querySelector('.cw__direct-call.direct-call__stars-value') as HTMLElement).innerText = value.displayValue;

            this.dispatchEvent(scrollToBottom(false));

        });

        star.addEventListener('click', () => {
            if (this.currentConfiguration.onSubmit && typeof this.currentConfiguration.onSubmit === 'function') {
                this.chatWindow.tracking.trackSurvey(index+1, totalAmount);
                value.displayValue = this.domElement.querySelector('.direct-call__stars-container').outerHTML;
                this.currentConfiguration.onSubmit(value);
            }
        });

        return star;

    }

    private removeActiveStarClasses() {
        this.domElement.querySelectorAll('.cw__direct-call.direct-call__star').forEach(currentStar => {
            currentStar.classList.remove('direct-call__star-active');
            (this.domElement.querySelector('.cw__direct-call.direct-call__stars-value') as HTMLElement).style.opacity = "0";
        });
    }

    private handleShowDirectCallStarsEvent(event: StarRatingEvent) {
        this.chatWindow.messageTransforming.withDelay(() => {
            this.domElement = this.generateStarsRating(event.data);
            AnimationHelper.animateDirectCall(this.domElement);
            this.currentConfiguration = event.data;
            this.visible = true;
        });
    }

    private onHide() {
        this.visible = false;
        this.domElement = null;
    }

    set visible(visible: boolean) {
        if (visible && !this.parentElement.contains(this.domElement)) {
            this.parentElement.appendChild(this.domElement);
        } else if (!visible && this.parentElement.contains(this.domElement)) {
            this.domElement.remove();
        }
    }

}

export default StarRating;
