export enum LivepersonConst {
    ApiKey = "721c180b09eb463d9f3191c41762bb68",
    AccountNumber = "11598923",
    ExternalMethodJsName = "initializeChatWindow",
    EngagementButtonSelector = ".LPMcontainer.LPMoverlay"
}

export enum InbentaConst {
    ApiKey = "BY3wMTxm+xqFBIteA7G0YOV7VveF7dkhk9pfgO3ZjcE=",
    DomainKey = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0IjoiYWxsaWFuel9hemRfaGF1c3JhdF9jaGF0Ym90X2RlIiwiZG9tYWluX2tleV9pZCI6IkJaZkg2M0VTRTBtNnAzSUxUUDRrUFE6OiJ9.QYLTVaHMd1W8-WmmMtaCdFrglGJXg9EHdeQNy5tQMNvirOYcPuLtcVU_BmJywZOTjzyIBJparWmq5XqAErPxDA"
}

export enum ContentGuruConst {
    ApiKey = "2J7TH3RPPJ5O6Y0T2Z61PCVG1GW7ZOJV",
    ApiSecret = "UUKOR73NIFWIP90J2LYIOZLT4M6FJ5B2",

    // Copied from LP
    ExternalMethodJsName = "initializeChatWindow",
    EngagementButtonSelector = ".LPMcontainer.LPMoverlay",
    AccountNumber = "11598923"
}

export enum DefaultConfig {
    ConfigurationAttributeKey = 'customconfiguration',
    SurveyDelay = 650,
    BotDelay = 650
}
