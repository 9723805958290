import JSX from "../../../jsx";
import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import GeneralMessage from "./GeneralMessage";
import {MessageType} from "../../../types";

interface UserMessageProps {
    message: string
}

class UserMessage extends GeneralComponent<UserMessageProps> implements ComponentInterface {

    public render(parentElement: HTMLElement | Element) {
        this.generateMessage();
        parentElement.appendChild(this.domElement);
    }

    private generateMessage() {

        this.domElement =
            <GeneralMessage
                type={MessageType.Sent}
                text={this.props.message}
            />;
    }

}

export default UserMessage;
