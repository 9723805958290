import AbstractSubscriber from './AbstractSubscriber';
import CloseRequestEvent from '../events/window/events/CloseRequestEvent';
import {SingleChoiceAction} from '../events/interaction/types';
import {Window} from '../events/window';
import {Interaction} from '../events/interaction';
import {Asset} from '../types';
import {ApiProvider} from '../api/providers/providers';

class ChatWindowCloseSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(Window.Events.onCloseRequest, this.handleRequestChatWindowCloseEvent.bind(this));
    }

    /**
     * Handles the request chat window event
     * @param event
     */
    private handleRequestChatWindowCloseEvent(event: CloseRequestEvent) {

        // the inbenta chat window close subscriber handles the dialog to add more functionality
        if(this.chatWindow.api.currentProvider.name === ApiProvider.Inbenta && this.chatWindow.configuration.inbenta.surveyId) {
            return;
        }

        this.dispatchEvent(Interaction.Methods.preventInteraction({
            doNotRestore: true,
            resizeContainer: false
        }));

        const singleChoiceActions: SingleChoiceAction[] = [
            {
                label: this.chatWindow.translation.translate('wordings.yes'),
                onClick: () => {
                    this.closeChatWindow();
                    this.dispatchEvent(Interaction.Methods.preventInteraction({
                        doNotRestore: true
                    }))
                }
            },
            {
                label: this.chatWindow.translation.translate('wordings.no'),
                onClick: () => {

                    this.dispatchEvent(Interaction.Methods.preventInteraction({
                        doNotRestore: true
                    }));
                    this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction())
                }
            }
        ];

        this.dispatchEvent(Interaction.Methods.showSingleChoice(singleChoiceActions, {
            doNotRestore: true,
            displayTop: true,
            label: this.chatWindow.translation.translate('predefined.close-confirmation'),
            asset: Asset.Question,
            allowPrimary: true
        }));

    }

    /**
     * Closes the ChatWindow
     */
    private closeChatWindow() {
        this.dispatchEvent(Window.Methods.close())
    }

}

export default ChatWindowCloseSubscriber;
