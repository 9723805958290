import {AbstractEvent} from "../../AbstractEvent";
import {ApiEvents} from "../events";

interface ApiReadyProps {
    domElement: HTMLElement
}
/**
 * This event is dispatched, when API is ready
 */
class ApiReadyEvent extends AbstractEvent<ApiReadyProps> {
    static NAME = ApiEvents.onApiReady;
}

export default ApiReadyEvent;