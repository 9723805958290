import JSX from "../../../../../jsx";
import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";

export interface HeaderProps {
    title: string,
    closeCallback?: () => void
}

class Header extends GeneralComponent<HeaderProps> implements ComponentInterface {


    protected onConstructor() {}

    render(parentElement: HTMLElement | Element): void {
        this.generateHeader();
        parentElement.appendChild(this.domElement);
    }

    private generateHeader() {
        this.domElement = (
            <div class="cw__header cw__sidewindow">
                <div class="cw__header-title">
                    <h3>
                        {
                            this.props.title
                        }
                    </h3>
                </div>
                <div class="cw__header-actions" aria-label="Aktionen">
                    <button aria-label="Fenster schließen" tabindex="5" onclick={this.handleCloseButtonClick.bind(this)} class="c-icon c-icon--close cw__header-close-button"/>
                </div>
            </div>
        )
    }

    private handleCloseButtonClick() {
        this.props.closeCallback();
    }
}

export default Header;
