import {ErrorEventData} from "./types";
import ErrorEvent from "./events/ErrorEvent";
import DisplayErrorEvent from "./events/DisplayErrorEvent";
import HideErrorsEvent from "./events/HideErrorsEvent";
import ResetMaxRetriesEvent from "./events/ResetMaxRetriesEvent";

export namespace ErrorHandlingMethods {

    /**
     * Reports an error
     * @param data
     */
    export const reportError = (data: ErrorEventData) => {
        return new ErrorEvent(data);
    };

    /**
     * Displays an error
     * @param data
     */
    export const displayError = (data: ErrorEventData) => {
        return new DisplayErrorEvent(data);
    };

    /**
     * Hides an error
     */
    export const hideErrors = () => {
        return new HideErrorsEvent();
    };

    /**
     * Resets the max retries for the given identifier
     * @param identifier
     */
    export const resetMaxRetries = (identifier: string) => {
        return new ResetMaxRetriesEvent(identifier);
    };

}