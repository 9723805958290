import JSX from "../../../jsx";
import Icon from "./Icon";
import {ProductIcon} from "../../../types";

interface AvatarProps {
    icon: ProductIcon
}

const Avatar = (props: AvatarProps) => {
    return (
        <div class="cw__message__avatar">
            <Icon icon={props.icon} />
        </div>
    )
};

export default Avatar
