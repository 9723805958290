import InbentaService from "../InbentaService";
import {Answer} from "../../definition/answerTypes";

abstract class AbstractHandler extends InbentaService {

    public canHandle(answer: Answer) : boolean {
        return false;
    }

    public handle(answer: Answer) {

    }

    protected openUserAssistant() {
        this.inbenta.chatWindow.messageTransforming.withDelay(() => {
            this.inbenta.chatWindow.api.shutdownService.executeShutdown();
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('azde:ua:open', {detail: "Agentursuche"}));
                document.dispatchEvent(new CustomEvent('azde:ua:open', {detail: "Leadformular"}));
            }, 750);
        })
    }

    protected openPhoneAssistant() {
        setTimeout(() => {
            this.inbenta.chatWindow.api.shutdownService.executeShutdown();
            setTimeout(() => {
                document.dispatchEvent( new CustomEvent('azde:ua:open', {detail: "Telefon"}));
            }, 750)
        }, 1500);
    }

}

export default AbstractHandler;
