import {ApiMethods} from "./events/api/methods";
import {ErrorHandlingMethods} from "./events/error-handling/methods";
import {InteractionMethods} from "./events/interaction/methods";
import {MessagingMethods} from "./events/messaging/methods";
import {TypingMethods} from "./events/typing/methods";
import {UtilityMethods} from "./events/utility/methods";
import {WindowMethods} from "./events/window/methods";
import {ApiEvents} from "./events/api/events";
import {ErrorHandlingEvents} from "./events/error-handling/events";
import {MessagingEvents} from "./events/messaging/events";
import {InteractionEvents} from "./events/interaction/events";
import {TypingEvents} from "./events/typing/events";
import {UtilityEvents} from "./events/utility/events";
import {WindowEvents} from "./events/window/events";
import Environment from "./helpers/Environment";

export namespace ChatWindowApi {

    export const Events = {
        ...ApiEvents,
        ...ErrorHandlingEvents,
        ...InteractionEvents,
        ...MessagingEvents,
        ...TypingEvents,
        ...UtilityEvents,
        ...WindowEvents
    };

    export const Methods = {
        ...ApiMethods,
        ...ErrorHandlingMethods,
        ...InteractionMethods,
        ...MessagingMethods,
        ...TypingMethods,
        ...UtilityMethods,
        ...WindowMethods
    }

}
