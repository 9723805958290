/**
 * Will be emitted when the user interacts with the message box
 */
import {AbstractEvent} from "../../AbstractEvent";
import {TypingState} from "../types";
import {TypingEvents} from "../events";

/**
 * Is dispatched, when the user is typing
 * Listen for this event to tell the provider, that the user is typing
 */
class UserTypingEvent extends AbstractEvent<TypingState> {
    static NAME = TypingEvents.onUserTyping;
}

export default UserTypingEvent;