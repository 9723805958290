import JSX from "../../../jsx";

import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import {Messaging} from "../../../events/messaging";

export interface CtaSystemMessageProps {
    label: string
    onClick: () => void
}

class CtaSystemMessage extends GeneralComponent<CtaSystemMessageProps> implements ComponentInterface {

    protected onConstructor() {
        this.registerEventHandler(Messaging.Events.onUserMessage, this.handleUserInteractionEvent.bind(this));
        this.registerEventHandler(Messaging.Events.onUserInteraction, this.handleUserInteractionEvent.bind(this));
    }

    public render(parentElement: HTMLElement | Element) {
        this.generateMessage();
        parentElement.appendChild(this.domElement);
    }

    private generateMessage() {
        this.domElement = (
            <div class="cw__message cw__message-cta-system cw__message--slide-in">
                <div class="cw__message cw__message-system__content">
                    <p class="c-copy" onclick={this.props.onClick}>{this.props.label}</p>
                </div>
            </div>
        );
    }

    private handleUserInteractionEvent() {
        this.domElement.remove();
    }

}

export default CtaSystemMessage;
