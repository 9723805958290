import {GeneralComponent} from '../../components/GeneralComponent';
import StartupHelper from '../../helpers/StartupHelper';
import {EndType} from './tracking/types';

let restartAttempts = 2;

class ShutdownService extends GeneralComponent<any> {

    // holds the hearbeat
    public heartbeatInterval: any = null;

    /**
     * Use this function to shutdown the chat window
     */
    public async executeShutdown(endType: EndType = EndType.Explicit) {
        this.stopHeartbeat();
        StartupHelper.emitBeforeShutdownEvent();
        try {
            await this.shutdownProvider(endType);
        } catch (e) {
        }
        await this.shutdownDomElement();
        await this.resetStartupHelper();
    }

    public async shutdownProvider(endType: EndType = EndType.Explicit) {
        this.chatWindow.tracking.trackToolExit(endType);
        if (this.chatWindow.api.currentProvider) {
            await this.chatWindow.api.currentProvider.eject();
        }
    }

    public async restart() {

        const currentConfig = this.chatWindow.configuration.configuration;

        await this.shutdownProvider();
        await this.shutdownDomElement();

        if(restartAttempts > 0) {

            restartAttempts = restartAttempts -1;

            setTimeout(() => {
                StartupHelper.renderFromConfiguration(currentConfig);
            }, 500);
        }

    }

    public async shutdownDomElement() {

        const assistantFrame = this.chatWindow.domElement.querySelector('.cw__user-assistant');
        if (assistantFrame) {
            assistantFrame.classList.remove('show');
        }

        setTimeout(() => {
            this.chatWindow.domElement.remove();
        }, 200);

    }

    public stopHeartbeat() {
        if(this.heartbeatInterval) {
            clearInterval(this.heartbeatInterval)
            this.heartbeatInterval = null;
        }
    }

    public async resetStartupHelper() {
        StartupHelper.open = false;
        StartupHelper.currentConfiguration = null;
    }

}

export default ShutdownService;
