import {AbstractEvent} from "../../AbstractEvent";
import {ErrorHandlingEvents} from "../events";

/**
 * Should be dispatched, when a function ran throw successfully
 */
class ResetMaxRetriesEvent extends AbstractEvent<string> {
    static NAME = ErrorHandlingEvents.onHideErrors
}

export default ResetMaxRetriesEvent;