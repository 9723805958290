import './polyfills';
import './ts-polyfills';

if (process.env.NODE_ENV === 'development') {
    require('../styles/main.dev.scss');
} else {
    if(process.env.IS_PREVIEW) {
        require('../styles/main.dev.scss');
    } else {
        require('../styles/main.prod.scss');
    }
}


import StartupHelper from './app/helpers/StartupHelper';

StartupHelper.initialize();