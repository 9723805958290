import autosize from 'autosize';
import PerfectScrollbar from "perfect-scrollbar";
import IMask from 'imask';

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import JSX from "../../jsx";

import {FooterWrapper} from "../General/Content/FooterWrapper";
import TextInputEvent from "../../events/interaction/events/TextInputEvent";
import AnimationHelper from "../../helpers/AnimationHelper";
import {Typing} from "../../events/typing";
import {Interaction} from "../../events/interaction";
import {Messaging} from "../../events/messaging";
import SetPlaceholderEvent from '../../events/interaction/events/SetPlaceholderEvent';
import Environment from "../../helpers/Environment";
import EnableDateMaskEvent from "../../events/interaction/events/EnableDateMaskEvent";
import {InteractionMethods} from "../../events/interaction/methods";
import setPlaceholder = InteractionMethods.setPlaceholder;
const xss = require("xss");

class TextInput extends GeneralComponent<any> implements ComponentInterface {

    private parentElement: HTMLElement;
    private keyPressTimeout;
    private isTyping: boolean = false;
    private perfectScrollbarInstance: PerfectScrollbar;
    private latestEvent: TextInputEvent;
    private maskInstance: IMask.InputMask<any>|null;
    private latestPlaceholder: string;
    private maskValidation: RegExp|null = null;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.onTextInput, this.handleShowInputBoxEvent.bind(this));
        this.registerEventHandler(Interaction.Events.onTextInputReset, this.handleTextInputReset.bind(this));
        this.registerEventHandler(Interaction.Events.onHideInteraction, this.onHide.bind(this));
        this.registerEventHandler(Interaction.Events.onShowInteraction, this.onShow.bind(this));
        this.registerEventHandler(Interaction.Events.onSetPlaceholder, this.handlePlaceholderChange.bind(this));
        this.registerEventHandler(Interaction.Events.onEnableDateMask, this.enableDateMask.bind(this));
        this.registerEventHandler(Interaction.Events.onDisableInputMask, this.disableInputMask.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.parentElement = parentElement as HTMLElement;
        this.domElement = this.generateInputBox();
        autosize(this.inputElement);
        AnimationHelper.animateDirectCall(this.domElement);

        // if(Environment.isWindows()) {
            this.perfectScrollbarInstance = new PerfectScrollbar(this.domElement.querySelector('.input-box__scrolling-textarea'), {
                useBothWheelAxes: false,
                suppressScrollX: true,
                minScrollbarLength: 20
            });
        // }

        this.domElement.addEventListener('autosize:resized', this.handleTextAreaResize.bind(this));
    }

    private enableDateMask(event: EnableDateMaskEvent) {

        // check if it is already masked
        if(this.maskInstance) {
            return;
        }

        this.maskInstance = IMask(this.inputElement, {
            ...event.data.config
        });

        this.placeholder = event.data.placeholder;
        this.maskValidation = event.data.validation || null;

    }

    private disableInputMask() {
        if (!this.maskInstance) {
            return;
        }
        this.maskInstance.destroy();
        this.maskInstance = null;
        this.maskValidation = null;
        this.chatWindow.api.dispatchEvent(setPlaceholder(this.latestPlaceholder));
    }

    private generateInputBox() {
        return (
            <FooterWrapper>
                <div class="cw__input-box input-box__container">
                    <form class="cw__input-box input-box__form" onsubmit={this.handleFormSubmit.bind(this)}>
                        <div class="cw__input-box input-box__textarea-holder">
                            <div class="cw__input-box input-box__char-counter">
                                <div class="c-icon c-icon--exclamation-circle" />
                                <p class="c-copy"/>
                            </div>
                            <div class="cw__input-box input-box__divider" />
                            <div class={"cw__input-box input-box__placeholder"} />
                            <div class="cw__input-box input-box__scrolling-textarea" style={`max-height: ${32*this.chatWindow.api.currentProvider.getProviderInputConfig().InputMaxLines-1}px`}>
                                <textarea
                                    rows={1}
                                    class="c-textinput__field c-textinput__field--floating js-floating__input cw__input-box input-box__input"
                                    onfocus={() => {
                                        this.form.classList.add('input-box__form--focus');
                                    }}
                                    onblur={() => {
                                        /*setTimeout(() => {
                                            this.form.classList.remove('input-box__form--focus');
                                        }, 200)*/
                                    }}
                                    onkeydown={(event) => {
                                        if (event.keyCode === 13 && !event.shiftKey) {
                                            event.preventDefault();
                                            this.handleFormSubmit();
                                        }
                                    }}
                                    onkeypress={this.handleKeyPress.bind(this)}
                                    onkeyup={this.handleKeyUp.bind(this)}
                                    autofocus='autofocus'
                                    autocomplete='off'
                                    maxlength={this.chatWindow.api.currentProvider.getProviderInputConfig().InputMaxLength}
                                />
                            </div>
                        </div>
                        <button aria-label="Nachricht abschicken" class="cw__input-box input-box__submit" onclick={() => {this.handleFormSubmit(null)}}>
                            <span class="c-icon c-icon--telegram" />
                        </button>
                    </form>
                </div>
            </FooterWrapper>
        )
    }

    private handleShowInputBoxEvent(event: TextInputEvent) {
        this.latestEvent = event;
        this.chatWindow.messageTransforming.withDelay(() => {
            this.visible = true;
            this.latestPlaceholder = event.data.placeholder;
            if(!this.maskInstance) {
                this.placeholder = event.data.placeholder;
            }
            this.message = '';
        });
    }

    private onHide() {
        this.visible = false;
    }

    private onShow() {
        this.visible = true;
    }

    private handleFormSubmit(event = null) {
        if (event) {
            event.preventDefault();
        }

        const message = xss(this.message);
        if (message && message.trim()) {

            if(this.maskValidation && !this.maskValidation.test(message)) {
                return;
            }

            this.dispatchEvent(Messaging.Methods.submitUserMessage(message));

            if(this.latestEvent && this.latestEvent.data.onSubmit && typeof this.latestEvent.data.onSubmit === 'function') {
                this.latestEvent.data.onSubmit(message);
            }

            this.message = '';
            this.charsCount = 0;
            if(this.maskInstance) {
                this.disableInputMask();
            }
            autosize.update(this.inputElement);
        }
    }

    /**
     * This function checks the user typing state
     */
    private handleKeyPress() {
        if(!this.isTyping) {
            // user started typing
            this.dispatchEvent(Typing.Methods.setUserTyping());
            this.isTyping = true;
        }
        if (this.keyPressTimeout != undefined) {
            clearTimeout(this.keyPressTimeout)
        }
        this.keyPressTimeout = setTimeout(() => {
            if(this.isTyping) {
                this.isTyping = false;
                this.dispatchEvent(Typing.Methods.unsetUserTyping())
            }
        }, 2500);
    }

    private handleKeyUp(event) {
        if (Environment.isIE11()) {
            this.togglePlaceholder();
        }

        this.charsCount = this.inputElement.value.length;
        const scrollContainer = this.domElement.querySelector('.cw__input-box.input-box__scrolling-textarea') as HTMLElement;
        if(!event.currentTarget.value) {
            scrollContainer.style.maxHeight = `${32}px`
        } else {
            scrollContainer.style.maxHeight = `${32*this.chatWindow.api.currentProvider.getProviderInputConfig().InputMaxLines-1}px`
        }
    }

    private togglePlaceholder() {
        // toggle showing placeholder wrapper
        if (this.message.length <= 0) {
            this.placeholderHolder.style.display = "block";
        } else {
            this.placeholderHolder.style.display = "none";
        }
    }

    get inputElement() {
        return this.domElement.querySelector('textarea');
    }

    get placeholderHolder() {
        return this.domElement.querySelector('.input-box__placeholder') as HTMLElement;
    }

    get form() {
        return this.domElement.querySelector('form');
    }

    get message(): string {
        //@ts-ignore
        return this.inputElement.value;
    }

    // sets the message
    set message(value: string) {
        //@ts-ignore
        this.inputElement.value = value;
    }

    // sets the placeholder holder
    set placeholder(placeholder: string) {

        const words = placeholder.split(' ');
        let placeholderWords = [];
        let charsCount = 0;
        let placeholderIsFull = false;

        for(const word of words) {
            if(charsCount <= 32) {
                placeholderWords.push(word);
                charsCount += word.length;
            } else if(!placeholderIsFull) {
                placeholderWords.push('...');
                placeholderIsFull = true;
            }
        }

        if (Environment.isIE11()) {
            this.placeholderHolder.innerHTML = placeholderWords.join(' ');
        } else {
            this.inputElement.setAttribute('placeholder', placeholderWords.join(' '));
        }
    }


    // sets the visibility state
    set visible(visible: boolean) {
        if (visible && !this.parentElement.contains(this.domElement)) {
            this.parentElement.appendChild(this.domElement);
            this.inputElement.focus();
        } else if (!visible && this.parentElement.contains(this.domElement)) {
            this.domElement.remove();
        }
    }

    set charsCount(count: number) {

        const maxInputLength = this.chatWindow.api.currentProvider.getProviderInputConfig().InputMaxLength;
        const counterElement = (this.domElement.querySelector('.cw__input-box.input-box__char-counter') as HTMLElement);
        const dividerElement = (this.domElement.querySelector('.cw__input-box.input-box__divider') as HTMLElement);
        if(count === maxInputLength) {
            counterElement.style.display = 'flex';
            dividerElement.style.display = 'block';
            counterElement.querySelector('p').innerHTML = this.chatWindow.translation.translate('general.max-chars-reached');
            this.flashTextArea();
            this.handleTextAreaResize();
        } else if(count >= maxInputLength * 0.7) {
            counterElement.style.display = 'flex';
            dividerElement.style.display = 'block';
            counterElement.querySelector('p').innerHTML = this.chatWindow.translation.translate('general.chars-count', count, maxInputLength);
            this.unFlashTextArea();
            this.handleTextAreaResize();
        } else {
            counterElement.style.display = 'none';
            dividerElement.style.display = 'none';
            this.unFlashTextArea();
            this.handleTextAreaResize();
        }

    }

    flashTextArea() {
        const inputContainer = this.domElement.querySelector('.input-box__container') as HTMLElement;
        inputContainer.classList.add('cw__max-characters-exceeded');
        inputContainer.classList.add('warning');
    }

    unFlashTextArea() {
        const inputContainer = this.domElement.querySelector('.input-box__container') as HTMLElement;
        inputContainer.classList.remove('cw__max-characters-exceeded');
        inputContainer.classList.remove('warning');
    }

    handleTextAreaResize() {
        // if(Environment.isWindows()) {
            this.perfectScrollbarInstance.update();
        // }
    }

    /**
     * Handles the placeholder change
     * @param event
     */
    handlePlaceholderChange(event: SetPlaceholderEvent) {
        this.latestPlaceholder = event.data.placeholder;
        if(!this.maskInstance) {
            this.placeholder = event.data.placeholder;
        }
    }

    private handleTextInputReset() {
        this.message = '';
    }

}

export default TextInput;
