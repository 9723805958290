import InbentaService from './InbentaService';
import {
    ConversationHistoryEntry,
    FetchSurveyResponse,
    SendMessageResponse,
    StartConversationResponse
} from '../definition/conversation';
import {historyItemToEvent} from '../helpers/historyItemtoEvent';
import {Window} from '../../../../events/window';
import InbentaMessageResponseEvent from '../events/InbentaMessageResponseEvent';
import {Messaging} from '../../../../events/messaging';
import {ToolDirection} from '../../../service/tracking/types';
import {Utility} from "../../../../events/utility";
import LivepersonProvider from "../../liveperson/liveperson";
import {getAemChatBotConfiguration} from "../../../../helpers/AemExtractor";
import Environment from "../../../../helpers/Environment";

declare global {
    interface Window {
        inbentaInitialDirectCall?: string
    }
}

interface sendMessageAttributes {
    message?: string
    option?: string
    directCall?: string
    ignoreResponse?: boolean,
    isLatest?: boolean
}

export interface ConversationVariable {
    name: string
    value: string|string[]
}

class ConversationManager extends InbentaService {

    private temporaryConversation: StartConversationResponse;
    private hasAnsweredFirstQuestion: boolean = false;
    public livepersonBridgeEnabled = false;

    private conversation_local_storage_key = 'inbenta_conversation';

    /**
     * returns the current conversation from localstorage
     */
    get currentConversation(): StartConversationResponse | null {
        if (localStorage.getItem(this.conversation_local_storage_key)) {
            return JSON.parse(localStorage.getItem(this.conversation_local_storage_key))
        }
        if(this.temporaryConversation) {
            return this.temporaryConversation;
        }
        return null;
    }

    /**
     * Sets the current conversation and persists it into the localstorage
     * @param conversation
     */
    set currentConversation(conversation: StartConversationResponse) {
        if (conversation) {
            localStorage.setItem(this.conversation_local_storage_key, JSON.stringify(conversation));
        } else {
            localStorage.removeItem(this.conversation_local_storage_key);
        }
    }

    /**
     * Starts a conversation
     * https://developers.inbenta.io/chatbot/api/api-routes
     */
    public async startConversation(renewSession: boolean = false): Promise<StartConversationResponse> {

        // track interaction
        this.inbenta.api.trackInteraction();

        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/conversation`;
        const requestHeaders = this.inbenta.util.baseHeadersWithEnvironment;

        if (!this.currentConversation || renewSession) {

            this.currentConversation = null;
            this.temporaryConversation = await fetch(requestUrl, {
                method: 'POST',
                headers: requestHeaders,
                body: JSON.stringify({
                    lang: 'de',
                })
            }).then(res => res.json());

            if (!renewSession) {
                this.inbenta.directClose = true;
            }


            const variables = this.getVariables();
            if(variables.length) {
                await this.setConversationVariables(this.temporaryConversation.sessionToken, variables);
            }

            // AZIT-139: Already pre-merged, please dont duplicate this line
            this.inbenta.api.dispatchEvent(Messaging.Methods.enableMessageQueue(this.inbenta.getDefaultConfig().BotDelay));
            this.inbenta.messageRendering.handleMessageResponse(await this.sendInitialMessage(), true );

        } else {

            const variables = this.getVariables();
            if(variables.length) {
                try {
                    await this.setConversationVariables(this.currentConversation.sessionToken, variables);
                } catch (e) {
                    console.warn('could not set new context variables', e.message)
                }
            }

            const historyItems = await this.getConversationHistory();
            const messageEvents = historyItems.map(historyItem => {
                return historyItemToEvent(this.inbenta.chatWindow, historyItem);
            }).filter(event => !!event);

            this.inbenta.chatWindow.renderChatWindow();
            this.inbenta.dispatchEvent(Window.Methods.unsetLoading());

            for (const messageEvent of messageEvents) {
                this.inbenta.dispatchEvent(messageEvent);
            }

            this.inbenta.util.renderInputBox();

        }

        // enable the delay feature
        this.inbenta.api.dispatchEvent(Messaging.Methods.enableMessageQueue(this.inbenta.getDefaultConfig().BotDelay));
        this.inbenta.dispatchEvent(Utility.Methods.scrollToBottom(false, true));

        return this.currentConversation;

    };


    public async getConversationHistory(): Promise<ConversationHistoryEntry[]> {

        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/conversation/history`;

        const response = await fetch(requestUrl, {
            method: 'GET',
            headers: this.requestHeaders
        });
        const jsonBody = await response.json();
        if (response.status === 200) {
            return jsonBody;
        } else if (response.status === 400) {
            await this.startConversation(true);
            return []
        }

    };

    /**
     * Sends a message
     * https://developers.inbenta.io/chatbot/api/api-routes
     */
    public async sendMessage(attributes: sendMessageAttributes, withoutTracking?: boolean): Promise<SendMessageResponse> {

        // track the interaction
        this.inbenta.api.trackInteraction();

        const {ignoreResponse, isLatest, ...apiAttributes} = attributes;
        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/conversation/message`;
        const requestBody = {
            ...apiAttributes
        };

        // allow to skip tracking for initial empty message
        if(!withoutTracking) {
            // only track if not initial message call
            if(attributes.message) {
                // do tracking
                this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Inbound, (attributes.message));
            } else if(attributes.directCall) {
                this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Inbound, (attributes.directCall));
            }
        }

        if((!!attributes.message || !!attributes.option) && !this.hasAnsweredFirstQuestion && !localStorage.getItem(this.conversation_local_storage_key)) {
            this.hasAnsweredFirstQuestion = true;
            this.currentConversation = this.temporaryConversation;
        }

        if(this.livepersonBridgeEnabled) {
            (this.inbenta.api.currentProvider as LivepersonProvider).sdk.sendMessage(attributes.message, attributes.option)
            return null
        } else {

            const response = await fetch(requestUrl, {
                method: 'POST',
                headers: this.requestHeaders,
                body: JSON.stringify(requestBody)
            }).then(res => res.json());

            // renders the message response
            if(!attributes.ignoreResponse) {
                this.inbenta.messageRendering.handleMessageResponse(response);
            }

            // check if it latest message to prevent double rendering of the message box
            if(!attributes.isLatest) {
                this.inbenta.dispatchEvent(new InbentaMessageResponseEvent(response));
            }

            return response;
        }
    };

    /**
     * Sends the initial message to receive the welcome message delivered by inbenta
     */
    public async sendInitialMessage(): Promise<SendMessageResponse> {

        // welcome direct call configuration
        let directCall = '';
        if(this.inbenta.chatWindow.configuration.inbenta.directCall) {
            directCall = this.inbenta.chatWindow.configuration.inbenta.directCall
        } else if(window.inbentaInitialDirectCall) {
            directCall = window.inbentaInitialDirectCall
        }

        const response = await this.sendMessage(directCall ? {
            directCall: directCall,
            ignoreResponse: true
        } : {
            message: '',
            option: '',
            ignoreResponse: true
        }, true);

        this.inbenta.chatWindow.renderChatWindow();
        this.inbenta.dispatchEvent(Window.Methods.unsetLoading());

        return response;
    }

    /**
     * Returns the URL of a survey identified with a surveyId parameter with unrestricted use.
     */
    public async fetchSurvey(surveyId: string): Promise<FetchSurveyResponse> {
        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/survey/${surveyId}`;
        return await fetch(requestUrl, {
            method: 'GET',
            headers: this.requestHeaders,
        }).then(res => res.json());
    }

    public async setConversationVariable(sessionId: string, variable: ConversationVariable) {

        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/conversation/variables`

        try {
            const rawResponse = await fetch(requestUrl, {
                method: 'POST',
                headers: {...this.inbenta.util.baseHeaders, 'x-inbenta-session': `Bearer ${sessionId}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                  ...variable
                })
            });

            return rawResponse.status === 200;
        } catch (e) {
            console.warn('could not post variable', e);
            return false;
        }

    }

    public async setConversationVariables(sessionId: string, variables: ConversationVariable[]) {

        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/conversation/variables/multiple`

        try {
            const rawResponse = await fetch(requestUrl, {
                method: 'POST',
                headers: {...this.inbenta.util.baseHeaders, 'x-inbenta-session': `Bearer ${sessionId}`, 'Content-Type': 'application/json'},
                body: JSON.stringify({
                    variables
                })
            });

            return rawResponse.status === 200;
        } catch (e) {
            console.warn('could not post variable', e);
            return false;
        }

    }

    get requestHeaders() {
        return {
            ...this.inbenta.util.baseHeaders,
            'x-inbenta-session': `Bearer ${this.currentConversation.sessionToken}`
        }
    }

    private getVariables() {

        const preContext = this.inbenta.chatWindow.configuration.inbenta.context;
        let context: object = {};
        if(typeof preContext === 'string') {
            context = {
                'Quelle': preContext
            }
        } else if(typeof preContext === 'object') {
            context = preContext;
        }

        const variables: ConversationVariable[] = [];

        if(this.inbenta.chatWindow.configuration.inbenta.context) {
            for(const variableName of Object.keys(context)) {
                const variableValue = context[variableName];
                variables.push({name: variableName, value: variableValue});
            }
        }

        const aemContext = getAemChatBotConfiguration();
        if(aemContext && aemContext.context.length) {
            for(const aemContextItem of aemContext.context) {
                for(const itemKey of Object.keys(aemContextItem)) {
                    const itemValue = aemContextItem[itemKey] as string;
                    const matchedExistingVariable = variables.find(item => item.name === itemKey);
                    if(matchedExistingVariable) {
                        variables[variables.indexOf(matchedExistingVariable)].value = itemValue
                    } else {
                        variables.push({
                            name: itemKey,
                            value: itemValue
                        })
                    }
                }
            }
        }

        variables.push({
            name: 'device',
            value: Environment.detectDeviceType()
        })

        return variables
    }

    initialize = async () => {
        await this.startConversation();
    };
}

export default ConversationManager;
