import {ApiProvider} from "../api/providers/providers";
import {ChatWindowConfiguration} from "../service/ConfigurationService";

export type environment = 'AEM'|'EXTERNAL';

class DependencyInjectionHelper {

    /**
     * Injects required dependencies
     * @param configuration
     */
    static async injectDependencies(configuration: ChatWindowConfiguration) {

        if(configuration.provider === ApiProvider.LivePerson) {
            await DependencyInjectionHelper.injectLpChatLib();
            if(configuration.environment === 'EXTERNAL') {
                await DependencyInjectionHelper.injectExternalDependencies();
            }
        }

    }

    /**
     * Injects the LivePerson Chat JavaScript SDK
     */
    static async injectLpChatLib() {

        const sourcePath = "https://lpcdn.lpsnmedia.net/api/chat/public/lpChat.min.js";

        return new Promise((resolve, reject) => {

            const scriptTag = document.createElement('script');
            scriptTag.src = sourcePath;
            scriptTag.onload = resolve;
            scriptTag.onerror = reject;

            document.head.appendChild(scriptTag);

        });

    }

    /**
     * Injects non aem dependencies
     */
    static async injectExternalDependencies() {

    }

}

export default DependencyInjectionHelper;
