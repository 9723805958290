/**
 * Use this class to create some awesome events
 *
 * @example Creating an event
 *
 * interface UserMessageEventData {
 *     message: string
 * }
 *
 * class UserMessageEvent extends AbstractEvent<UserMessageEventData> {
 *     static NAME = 'conversation/user-message'
 * }
 *
 * export default UserMessageEvent;
 *
 * @example Initializing and dispatching an event
 *
 * const myEvent = new UserMessageEvent({message: 'Hello'})
 * this.dispatchEvent(myEvent);
 */
import ChatWindow from "../ChatWindow";

export abstract class AbstractEvent<T> {

    // this name returns the event's identifier and must be unique
    static NAME = '';

    // holds the event's data
    private _data: T = null;

    // sets the event cancelled
    private _cancelled: boolean = false;

    public onDispatch?: () => void;
    public onDispatchExecuted?: boolean;

    constructor(data: T = null) {
        this.data = data;
    }

    get data(): T {
        return this._data;
    }

    set data(value: T) {
        this._data = value;
    }

    get cancelled(): boolean {
        return this._cancelled;
    }

    set cancelled(value: boolean) {
        this._cancelled = value;
    }

    static _isEvent(object: any) {
        return !!object.constructor.NAME;
    }

    public transform(chatWindow: ChatWindow) {
    }
}