import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";
import {DirectCall} from "../../definition/directCall";

class ContactAgency1Handler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ContactAgency1);
    }

    public handle(answer: Answer) {
        this.openUserAssistant();
    }

}

export default ContactAgency1Handler;
