import {AbstractEvent} from '../../AbstractEvent';
import {MessageQueueConfiguration} from '../types';
import {MessagingEvents} from '../events';
import onEnableMessageQueue = MessagingEvents.onEnableMessageQueue;

class EnableMessageQueueEvent extends AbstractEvent<MessageQueueConfiguration> {
    static NAME = onEnableMessageQueue;
}

export default EnableMessageQueueEvent;
