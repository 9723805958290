// To parse this data:
//
//   import { Convert, MultipleChoiceQuestion, PolarQuestion, Answer } from "./answerTypes";
//
//   const multipleChoiceQuestion = Convert.toMultipleChoiceQuestion(json);
//   const polarQuestion = Convert.toPolarQuestion(json);
//   const answer = Convert.toAnswer(json);

export enum AnswerType {
    Answer = 'answer',
    PolarQuestion = 'polarQuestion',
    MultipleChoiceQuestion = 'multipleChoiceQuestion'
}

export interface ActionField {
    fieldType:     string;
    disableInput:  boolean;
    actionFieldId: string;
    variableName:  string;
    listValues?: {
        displayType: ListValueDisplayType,
        values: {
            label: string[],
            option: string,
        }[]
    };
}

export interface TrackingAttribute {
    "Step-Number": number,
    "Step-Name": string,
    "Processname": string,
    "Processcategory": string,
    "BusinessEvent": string,
    "opening"?: string
}

export enum ListValueDisplayType {
    Buttons = 'buttons',
}

export interface Answer {
    type: AnswerType;
    message: string;
    messageList: string[];
    options: MultipleChoiceQuestionOption[] | null;
    parameters: Parameters;
    flags: any[];
    attributes: {
        [key:string]: any
        TRACKING?: string
    };
    actionField?: ActionField;
    source: Source;
}

export interface MultipleChoiceQuestionOption {
    value: number|string;
    label: string;
    attributes: Attributes;
    intent?: Intent;
}

export interface Attributes {
    SIDEBUBBLE_TEXT?: string;
    CHECK_QUESTION?: string;
    ANSWER_TEXT: string;
    title: string;
}

export interface Intent {
    type: string;
    score: number;
}

export interface Source {
    type: string;
    name: string;
}

export interface PolarQuestion {
    type: string;
    message: string;
    messageList: string[];
    options: PolarQuestionOption[];
    parameters: null;
    flags: any[];
    attributes: Attributes;
    source: Source;
}

export interface PolarQuestionOption {
    value: string;
    label: string;
}

export interface Parameters {
    contents: Contents;
}

export interface Contents {
    title:        string;
    attributes:   any[];
    trackingCode: TrackingCode;
    related:      Related;
}

export interface Related {
    relatedTitle:    string;
    relatedContents: RelatedContent[];
}

export interface RelatedContent {
    id:              string;
    title:           string;
    attributes:      RelatedContentAttributes;
    directCall:      string;
    revisitableLink: string;
}

export interface RelatedContentAttributes {
    ANSWER_TEXT: string;
    RATINGS:     string;
    DIRECT_CALL: string;
    title:       string;
}

export interface TrackingCode {
    clickCode: string;
    rateCode:  string;
}

export interface Source {
    type: string;
    name: string;
}


// Converts JSON strings to/from your types
export class Convert {
    public static toMultipleChoiceQuestion(json: string): Answer {
        return JSON.parse(json);
    }

    public static multipleChoiceQuestionToJson(value: Answer): string {
        return JSON.stringify(value);
    }

    public static toPolarQuestion(json: string): PolarQuestion {
        return JSON.parse(json);
    }

    public static polarQuestionToJson(value: PolarQuestion): string {
        return JSON.stringify(value);
    }

    public static toAnswer(json: string): Answer {
        return JSON.parse(json);
    }

    public static answerToJson(value: Answer): string {
        return JSON.stringify(value);
    }
}
