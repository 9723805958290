import JSX from "../../../jsx";
import GeneralMessage from "./GeneralMessage";

import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import AnimationHelper from "../../../helpers/AnimationHelper";
import {ProductIcon} from "../../../types";
import {ErrorLevel} from "../../../events/error-handling/types";
import {ErrorHandling} from "../../../events/error-handling";

export interface ErrorMessageProps {
    message: string,
    level: ErrorLevel
}

class ErrorMessage extends GeneralComponent<ErrorMessageProps> implements ComponentInterface {

    protected onConstructor() {
        this.registerEventHandler(ErrorHandling.Events.onHideErrors, this.handleHideErrorsEvent.bind(this));
    }

    public render(parentElement: HTMLElement | Element) {
        this.generateMessage();
        parentElement.appendChild(this.domElement);
    }

    private generateMessage() {

        this.domElement =

            <GeneralMessage
                type={this.props.level}
                icon={this.props.level === 'critical' ? ProductIcon.ErrorCritical : ProductIcon.ErrorMajor}
                text={this.props.message}
            />;

        AnimationHelper.animateReceivedMessage(this.domElement);

    }

    private handleHideErrorsEvent() {
        this.domElement.remove();
    }

}

export default ErrorMessage;
