import {InbentaConfiguration} from "../service/ConfigurationService";
import StartupHelper from "./StartupHelper";

export interface AemChatBotConfiguration {
    domainKey: string
    apiKey: string
    environment: string
    context: AemChatBotContext[]|null
}

export interface AemChatBotContext {
    [key:string]: string
}

export enum ApiKeyCompareAction {
    Continue = "continue",
    Restart = "restart",
    Close = "close"
}

/**
 * <div class="c-user-assistant__chatbot-action js-user-assistant__chatbot-config" data-domain-key="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJwcm9qZWN0IjoiYWxsaWFuel9hemRfcGh2X2NoYXRib3RfZGUiLCJkb21haW5fa2V5X2lkIjoiQmNkNHVrQnZjdy1kal9abGNQS1gwQTo6In0.YAV_MmpU6EShyr0DObq2fek17jrA645UQV32LgpARVDFN0DAL-s1eYQ2FvHB8LpuafqIm1f24JtdgbjopZRe_Q" data-api-key="Bcck1URLJ4QOIlxJjCszhKpWKpb2oXZozlvqrHr8VXU=" data-environment="production" data-chatbot-name="Allie-PHV" data-chatbot-header="Allie, Ihre digitale Expertin" data-chatbot-context="[{"chatbotKey":"Quelle","chatbotKeyTag":"phv_pds"}]">
 <div class="c-message c-message--error c-message--no-shadow c-message--max-width c-user-assistant__chatbot-action-error-message">
 <div class="c-message__content">
 <em aria-hidden="true" class="c-message__item-icon c-icon c-icon--info-circle"></em>
 <div class="c-copy">
 Leider haben wir gerade ein technisches Problem.<br>
 Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder wählen Sie einen anderen Kontaktweg.<br>
 Vielen Dank.
 </div>
 </div>
 </div>
 */

export const getAemChatBotConfiguration = (): AemChatBotConfiguration|null => {
    const aemElement = document.querySelector('.c-user-assistant__chatbot-action.js-user-assistant__chatbot-config');
    if(!aemElement) {
        return null;
    }

    let context = null

    try {
        context = JSON.parse(aemElement.getAttribute('data-chatbot-context'));
    } catch (e) {
        console.warn('could not parse context', aemElement.getAttribute('data-chatbot-context'))
    }

    let chatbotKey = "";
    let chatbotKeyTag = "";
    if(Array.isArray(context)) {
        for(const item of context) {
            if(item["chatbotKey"]) {
                chatbotKey = item["chatbotKey"]
            }
            if(item["chatbotKeyTag"]) {
                chatbotKeyTag = item["chatbotKeyTag"]
            }
        }
    }

    if(chatbotKey && chatbotKeyTag) {
        context = [
            {
                [chatbotKey]: chatbotKeyTag
            }
        ]
    }

    return {
        domainKey: aemElement.getAttribute('data-domain-key'),
        apiKey: aemElement.getAttribute('data-api-key'),
        environment: aemElement.getAttribute('data-environment'),
        context: Array.isArray(context) ? context : null
    }

}

export const compareApiKeys = (currentConfiguration: InbentaConfiguration): ApiKeyCompareAction => {
    
    if(process.env.NODE_ENV === 'development') {
        return ApiKeyCompareAction.Continue;
    }

    const pageConfiguration = getAemChatBotConfiguration();
    if(!pageConfiguration) {
        return ApiKeyCompareAction.Close;
    }
    console.debug('extracted aem configuration', pageConfiguration)
    if(pageConfiguration.apiKey !== currentConfiguration.apiKey || pageConfiguration.domainKey !== currentConfiguration.domainKey) {
        console.debug('removing inbenta_converation because of a new api or domain key')
        localStorage.removeItem('inbenta_conversation');
        localStorage.removeItem('inbenta_authorization');
        return ApiKeyCompareAction.Restart;
    }
    return ApiKeyCompareAction.Continue
}

export const restartViaUA = () => {
    StartupHelper.currentConfiguration = null;
    StartupHelper.open = false

    setTimeout(() => {
        const checkInterval = setInterval(() => {
            const launchButton = document.querySelector('.c-user-assistant__inbenta-bot-action__link.c-user-assistant__contact-actions__link') as HTMLButtonElement;
            if(launchButton) {
                launchButton.click();
                clearInterval(checkInterval)
            } else {
                console.debug('could not locate launch button .c-user-assistant__inbenta-bot-action__link.c-user-assistant__contact-actions__link')
            }
        }, 200)

        setTimeout(() => {
            clearInterval(checkInterval)
            console.debug('stopping launch sequence because element could not be located')
        }, 5 * 1000)
    }, 1500)
}