import AbstractSubscriber from "./AbstractSubscriber";
import {NotificationInteractionConfig, NotificationType} from "../events/interaction/types";
import {Interaction} from "../events/interaction";

class OnlineStateSubscriber extends AbstractSubscriber {

    private isOnline: boolean = true;
    private onLineCheckInterval: number = null;

    protected onConstructor() {
        window.addEventListener('online', this.handleOnlineEvent.bind(this));
        window.addEventListener('offline', this.handleOfflineEvent.bind(this));
        this.isOnline = navigator.onLine;
    }

    private handleOnlineEvent() {

        this.isOnline = true;

        if(this.onLineCheckInterval) {
            clearInterval(this.onLineCheckInterval);
        }

        this.dispatchEvent(Interaction.Methods.removeCachedInteractionsConditional(cachedActions => {
            return cachedActions.filter(action => {
                //@ts-ignore
                const eventName = action.constructor.NAME;
                if(eventName === Interaction.Events.onNotification) {
                    const eventData = action.data as NotificationInteractionConfig;
                    if(eventData.type && eventData.type === NotificationType.InternetConnectionLost) {
                        return false;
                    }
                }
                return true;
            });
        }));

        this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction())

    }

    private handleOfflineEvent() {
        this.isOnline = false;
        this.dispatchEvent(Interaction.Methods.showNotification(
            this.chatWindow.translation.translate('notification.offline-state'),
            NotificationType.InternetConnectionLost)
        );

        // @ts-ignore
        this.onLineCheckInterval = setInterval(() => {
            if(navigator.onLine && !this.isOnline) {
                this.handleOnlineEvent();
            }
        });

    }

}

export default OnlineStateSubscriber;
