import {AbstractEvent} from '../../AbstractEvent';
import {InteractionEvents} from '../events';

class SetPlaceholderEvent extends AbstractEvent<{placeholder: string}> {

    static NAME = InteractionEvents.onSetPlaceholder;

}

export default SetPlaceholderEvent;
