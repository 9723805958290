import AbstractSubscriber from "./AbstractSubscriber";
import DisplayErrorEvent from "../events/error-handling/events/DisplayErrorEvent";
import ErrorMessage from "../components/General/Messages/ErrorMessage";
import {SingleChoiceAction} from "../events/interaction/types";
import {Window} from "../events/window";
import {Interaction} from "../events/interaction";
import {Messaging} from "../events/messaging";
import {ErrorHandling} from "../events/error-handling";

/**
 * This subscriber listens to display error events and renders them
 */
class DisplayErrorEventSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(ErrorHandling.Events.onDisplayError, [
            this.renderErrorMessage.bind(this),
            this.handleCriticalError.bind(this),
            this.handleMajorError.bind(this)
        ]);
    }

    /**
     * Renders the error message
     * @param event
     */
    private renderErrorMessage(event: DisplayErrorEvent) {

        // generate the message component
        const errorMessageComponent = new ErrorMessage(this.chatWindow,  event.data);

        // unset the window's loading state
        this.dispatchEvent(Window.Methods.unsetLoading());

        // render the generated component
        this.dispatchEvent(Messaging.Methods.renderComponentAsMessage(errorMessageComponent));

    }

    /**
     * Handles an critical error
     * - Displays the required direct call actions
     * @param event
     */
    private handleCriticalError(event: DisplayErrorEvent) {

        if (event.data.level !== 'critical') {
            return;
        }

        if (event.data.fixable && typeof event.data.fixable === 'function') {

            const singleChoiceActions: SingleChoiceAction[] = [
                {
                    label: this.chatWindow.translation.translate('wordings.try-again'),
                    onClick: () => {
                        this.dispatchEvent(Interaction.Methods.preventInteraction({
                            doNotRestore: true
                        }));
                        this.dispatchEvent(ErrorHandling.Methods.hideErrors());
                        this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction());
                        this.dispatchEvent(ErrorHandling.Methods.resetMaxRetries(event.data.identifier));
                        event.data.fixable();
                    },
                    className: 'cw__button-critical'
                }
            ];

            this.dispatchEvent(Interaction.Methods.showSingleChoice(singleChoiceActions, {
                doNotRestore: true
            }));

        } else {
            this.dispatchEvent(Interaction.Methods.preventInteraction({
                doNotRestore: true
            }));
        }

    }

    /**
     * Handles an major error
     * - TODO
     * @param event
     */
    private handleMajorError(event: DisplayErrorEvent) {
        if (event.data.level !== 'major') {
            return;
        }
    }

}

export default DisplayErrorEventSubscriber;