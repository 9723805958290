import InbentaSubscriber from './InbentaSubscriber';
import {Window} from '../../../../events/window';
import {Interaction} from '../../../../events/interaction';
import {SurveyType} from '../definition/survey';
import {DirectCall} from '../definition/directCall';
import {ToolDirection} from '../../../service/tracking/types';

class ChatWindowCloseSubscriber extends InbentaSubscriber {

    register() {
        this.registerEventHandler(Window.Events.onClose, this.handleClose.bind(this))
        this.registerEventHandler(Window.Events.onCloseRequest, this.handleCloseRequest.bind(this))
    }

    unregister() {
        this.unloadEventHandler(Window.Events.onClose)
    }

    private async handleCloseRequest() {
        const persistanceKey = SurveyType.Post.toString();
        if (this.inbenta.api.persistence.get(persistanceKey) || this.inbenta.directClose) {
            await this.closeChatWindow(persistanceKey);
        }
    }

    private async handleClose() {
        const persistanceKey = SurveyType.Post.toString();
        if (this.inbenta.api.persistence.get(persistanceKey) || this.inbenta.directClose) {
            await this.closeChatWindow(persistanceKey);
        } else {
            this.inbenta.api.persistence.set(persistanceKey, true);
            this.inbenta.chatBotService.triggerChatbot(DirectCall.SurveyQuestion);
        }
    }

    private async closeChatWindow(postChatPersistanceKey?: string) {
        this.dispatchEvent(Interaction.Methods.hideInteraction());
        if (postChatPersistanceKey) {
            this.inbenta.api.persistence.remove(postChatPersistanceKey);
        }
        await this.inbenta.api.shutdownService.executeShutdown();
    }

}

export default ChatWindowCloseSubscriber;
