import {AbstractEvent} from "../AbstractEvent";
import {Asset, ProductIcon} from '../../types';
import {RatingsIcon, RatingsType} from "../../api/providers/inbenta/definition/ratings";

export enum InteractionType {
    SingleChoice,
    MultipleChoice,
    StarRating,
    TextInput,
    Notification
}

export interface BasicInteractionConfig {
    // the label to display
    label?: string

    labelTop?: boolean

    labelAlignLeft?: boolean

    // executed when the label is clicked
    onLabelClick?: () => void

    // display the element on the top ?
    displayTop?: boolean

    // if true, the action is not restoreable
    doNotRestore?: boolean,

    // allows to add an image
    asset?: Asset

    // disables primary buttons
    allowPrimary?: boolean
}
/**
 * The basic interaction config
 */
export interface InteractionConfig extends BasicInteractionConfig {

    // show's / hide's the interaction element
    show?: boolean,

    // declares the interaction type
    interactionType?: InteractionType

}

/**
 * Interaction config for TextInput
 */
export interface TextInputInteractionConfig extends InteractionConfig {
    placeholder: string,
    onSubmit?: (text: string) => void
}

/**
 * Interaction config for single choice
 */
export interface SingleChoiceInteractionConfig extends InteractionConfig {
    actions: SingleChoiceAction[]
}

/**
 * Interaction config for multiple choice
 */
export interface MultipleChoiceInteractionConfig extends InteractionConfig {
    options: MultipleChoiceOption[],
    onSubmit?: (filledOptions: MultipleChoiceOption[]) => void
}

export interface RatingsInteractionConfig extends InteractionConfig {
    actions: RatingsAction[]
}

/**
 * Interaction config for a notification
 */
export interface NotificationInteractionConfig extends InteractionConfig {
    type?: NotificationType
}

export interface AgentChangeInteractionConfig extends InteractionConfig {
    type: AgentType
}

export interface StarRatingInteractionConfig extends InteractionConfig {
    data: StarOption[]
    onSubmit?: (selectedValue: StarOption) => void
}

export interface RestoreInteractionConfig {
    removeCached?: boolean,
    removeConditional?: FilterCachedInteractions;
    reInitOnDispatch?: boolean
}

export interface PreventInteractionConfig extends InteractionConfig {
    resizeContainer?: boolean
}

export type FilterCachedInteractions = (cachedActions: AbstractEvent<InteractionConfig>[]) => AbstractEvent<InteractionConfig>[];

export interface SingleChoiceAction {
    label: string
    onClick: () => void
    className?: string
}

export interface MultipleChoiceOption {
    value: string
    displayValue: string
    checked: boolean
}

export interface RatingsAction {
    onClick: () => void,
    type: RatingsType,
    icon?: RatingsIcon,
    hideComponentOnClick?: boolean
}

export interface StarOption {
    value: string
    displayValue: string
}

export enum NotificationType {
    InternetConnectionLost = 1,
}

export enum AgentType {
    DIGITAL,
    HUMAN,
}
