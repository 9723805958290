import InbentaService from './InbentaService';

import {SendEventAttributes, SendEventResponse, SendEventType} from '../definition/events';
import {ToolDirection} from "../../../service/tracking/types";

class TrackingEventService extends InbentaService {

    public async sendEvent(attributes: SendEventAttributes): Promise<SendEventResponse> {

        const requestUrl = `${this.inbenta.util.chatBotBaseUrl}/tracking/events`;
        const requestHeaders = this.inbenta.conversation.requestHeaders;

        // check if is rate, then send track rating
        if(attributes.type === SendEventType.Rate) {
            if(attributes.value === 1) {
                // positive
                this.inbenta.chatWindow.tracking.trackSurvey(1, 1);
            } else if(attributes.value === 2) {
                // negative
                this.inbenta.chatWindow.tracking.trackSurvey(0, 1);
            }

        }

        // tracking thumbs up / down
        this.inbenta.chatWindow.tracking.trackToolUsage(ToolDirection.Inbound, "")

        return new Promise((resolve, reject) => {
            fetch(requestUrl, {
                method: 'POST',
                headers: requestHeaders,
                body: JSON.stringify({
                    type: attributes.type,
                    data: {
                        code: attributes.code,
                        value: attributes.value,
                        comment: attributes.comment
                    }
                })
            }).then(res => ({status: res.status})).then((eventResponse: SendEventResponse) => {
                if (eventResponse.status !== 200) {
                    return reject;
                }
                return resolve(eventResponse);
            }).catch(reject);
        });
    }
}

export default TrackingEventService;
