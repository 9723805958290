import {AbstractEvent} from '../../AbstractEvent';
import {TrackingEvents} from '../events';
import onConfigureTracking = TrackingEvents.onConfigureTracking;
import {ChatState, ToolCategory, TriggerOpenType, TriggerType} from '../../../api/service/tracking/types';

export interface ConfigureTrackingEventProps {
    enabled?: boolean
    toolName?: string
    toolCategory?: ToolCategory
    chatStart?: number
    chatState?: ChatState
    triggerType?: TriggerType
    triggerOpenType?: TriggerOpenType
    trackingToolName?: string
    trackingOpenType?: string
    pageName?: string
}

class ConfigureTrackingEvent extends AbstractEvent<ConfigureTrackingEventProps> {
    static NAME = onConfigureTracking;
}

export default ConfigureTrackingEvent;
