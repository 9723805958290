import Api from '../api';
import {ApiProvider, ProviderInterface} from './providers';
import {GeneralComponent} from '../../components/GeneralComponent';
import {ProductIcon} from '../../types';

export default abstract class AbstractProvider extends GeneralComponent<any> implements ProviderInterface {

    private _api: Api;
    private _directClose = false;
    private _allowScrollToLatestFirstMessage = false;

    public name: ApiProvider;

    constructor(api: Api, name: ApiProvider) {
        super(api.chatWindow);
        this._api = api;
        this.name = name;
    }

    get api(): Api {
        return this._api;
    }

    set api(value: Api) {
        this._api = value;
    }

    // this hook is called, when the provider gets initialized
    // @ts-ignore
    public abstract async initialize(): Promise<any>;

    // this hook is called, when the provider gets ejected
    // @ts-ignore
    public abstract async eject(): Promise<any>;

    public getProviderInputConfig() {
        return {
            InputMaxLength: 250,
            InputMaxLines: 5,
        }
    }

    public getProviderChatWindowConfig() {
        return {
            isHeaderPermanent: false
        }
    }

    public getAgentIcon(): ProductIcon {
        return ProductIcon.Agent
    }

    get directClose(): boolean {
        return this._directClose;
    }

    set directClose(value: boolean) {
        this._directClose = value;
    }

    get allowScrollToLatestFirstMessage(): boolean {
        return this._allowScrollToLatestFirstMessage;
    }

    set allowScrollToLatestFirstMessage(value: boolean) {
        this._allowScrollToLatestFirstMessage = value;
    }

    public closeAfterSeconds(): number {
        return 0;
    }

    public closeConfirmationEnabled(): boolean {
        return false;
    }

}
