import JSX from "../../../../../jsx"

import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";

interface BodyProps {
    content?: string
}
export default class Body extends GeneralComponent<BodyProps> implements ComponentInterface {

    protected onConstructor() {}

    render(parentElement: HTMLElement | Element) {
        this.generateBody();
        parentElement.appendChild(this.domElement);
    }

    private generateBody() {
        this.domElement = (
            <div class="cw__user-assistant_side_window__body">
                {
                    this.props.content
                }
            </div>
        );
    }
}
