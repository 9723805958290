import AbstractSubscriber from './AbstractSubscriber';
import ErrorEvent from '../events/error-handling/events/ErrorEvent';
import {ErrorHandlingEvents} from '../events/error-handling/events';

class ErrorReportingSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        //this.registerEventHandler(ErrorHandlingEvents.onError, this.handleError.bind(this));
        this.registerEventHandler(ErrorHandlingEvents.onDisplayError, this.handleError.bind(this));
    }

    private handleError(error: ErrorEvent) {

        const {
            data,
            identifier,
            level,
            message,
        } = error.data;

        try {
            this.chatWindow.errorReporting.trackError(message, 0, {
                ...data,
                identifier,
                level
            });
        } catch(e) {
            console.warn('Could not track error')
        }

    }


}

export default ErrorReportingSubscriber;
