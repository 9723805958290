import AbstractProvider from "./AbstractProvider";
import StartupHelper from "../../helpers/StartupHelper";
import InbentaProvider from "./inbenta/inbenta";

export enum ApiProvider {
    Inbenta = 'inbenta',
    LivePerson = 'liveperson',
    Testing = 'testing'
}

export interface ProviderInterface {
    initialize(): Promise<any>
    eject(): Promise<any>
}

/**
 * Returns the provider class based on it's name
 * @param provider
 */
export const getProvider = (provider: ApiProvider): AbstractProvider => {

    /**
     * Uncomment the non required providers so webpack does not include them in the bundle
     */

    const providers = {
        [ApiProvider.Inbenta]: InbentaProvider
    };

    if (providers[provider]) {
        //@ts-ignore
        return providers[provider];
    }

    StartupHelper.open = false;
    StartupHelper.currentConfiguration = null;

    throw new Error(`Could not find the given provider (${provider}). Available Providers: ${Object.keys(providers).join(', ')}`);

};
