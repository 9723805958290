import UserTypingEvent from "./events/UserTypingEvent";
import {TypingState} from "./types";
import AgentTypingEvent from "./events/AgentTypingEvent";

export namespace TypingMethods {

    /**
     * Sets the user typing state
     */
    export const setUserTyping = () => {
        return new UserTypingEvent(TypingState.Typing);
    };

    /**
     * Un-sets the user typing state
     */
    export const unsetUserTyping = () => {
        return new UserTypingEvent(TypingState.NotTyping);
    };

    /**
     * Sets the agent's typing state
     */
    export const setAgentTyping = () => {
        return new AgentTypingEvent(TypingState.Typing);
    };

    /**
     * Un-sets the agent's typing state
     */
    export const unsetAgentTyping = () => {
        return new AgentTypingEvent(TypingState.NotTyping);
    }

}