import InbentaSubscriber from './InbentaSubscriber';
import RatingsEvent from "../../../../events/interaction/events/RatingsEvent";
import RatingsComponent from "../components/Interaction/Ratings";
import {Messaging} from "../../../../events/messaging";

class RenderRatingsSubscriber extends InbentaSubscriber {

    protected onConstructor() {

        this.registerEventHandler(RatingsEvent.NAME, this.handleRenderRatings.bind(this));
    }

    handleRenderRatings(event: RatingsEvent) {
        const ratingsComponent = new RatingsComponent(this.inbenta.chatWindow, {
            text: this.chatWindow.translation.translate('rating.answer_helpful'),
            ratingsActions: event.data.actions
        });

        this.dispatchEvent(Messaging.Methods.renderComponentAsMessage(ratingsComponent));
    }

}

export default RenderRatingsSubscriber;
