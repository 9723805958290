import {GeneralComponent} from '../../../components/GeneralComponent';
import {
    ChatState,
    EndType,
    LocalStorageChatState,
    ToolCategory,
    ToolDirection,
    TrackingEventName,
    TriggerOpenType,
    TriggerType
} from './types';
import StartupHelper from '../../../helpers/StartupHelper';
import {TrackingAttribute} from "../../providers/inbenta/definition/answerTypes";

export interface CustomWindow extends Window {
    digitalData: any;
    om: any;
    businessConfig: any;
    trackingService: any;
    Optanon: any;
    ps20LatestTrackingStep: any;
    ps20LatestTrackingNumber: any;
    ps20TrackingService: any;  // The trackingService of the insurance the chatbot is embedded in
    AllianzTrackingLibrary: any;
}

export interface CustomEvent extends Event {
    detail: any;
}

declare let window: CustomWindow;

/**
 * The Tracking service is used to track bot usage
 */
class TrackingService extends GeneralComponent<any> {

    // must be enabled to allow tracking
    public enabled: boolean;

    // holds the tool name
    public toolName: string;

    // configureable tracking vars
    public trackingAppName: string;
    public trackingOpenType: string;
    public trackingPageView: string;

    // holds the tool category
    public toolCategory: ToolCategory;

    // holds the chat start date
    public chatStart: number;

    // holds the chat state
    public chatState: ChatState = ChatState.Open;

    // holds the trigger type
    public triggerType: TriggerType;

    // holds the trigger open type
    public triggerOpenType: TriggerOpenType;

    // holds the chat idle timeout
    public chatIdleTimeout: number = 120;

    // holds the tracking service instance
    public trackingService: any;

    // checks if tool start triggered
    public toolStartTriggered: any;

    // sets the idle timeout
    public idleTimeout: any;

    // sets the close timeout
    public closeTimeout: any;

    // do not send tracking direcly after each other
    public currentTrackingQue: number = 0;

    // holds the tool usage que
    public toolUsageQue: number = 0;

    // only track start once
    private toolStartSent = false;

    protected async onConstructor() {

        // set the tracking service instance
        this.trackingService = await this.resolveTrackingService();

        // initialize a new chat state object
        if(!StartupHelper.open || !this.storedChatState) {
            this.storedChatState = {
                chatState: ChatState.Active,
                outboundTimestamp: 0,
                inboundTimestamp: 0,
                chatStart: 0
            }
        }

        this.chatState = this.storedChatState.chatState;

        if (this.chatWindow.configuration.inbenta.isContinued) {
            this.setChatWindowActive();
        }

    }

    /**
     * Resolves the tracking service
     * @private
     */
    private resolveTrackingService() {
        return new Promise((resolve, reject) => {
            const uaTrackingServiceName = this?.chatWindow?.configuration?.inbenta?.trackingAppName;
            const uaTrackingService = window.AllianzTrackingLibrary?.utils?.getTrackingService(uaTrackingServiceName);
            if (uaTrackingService) {
                resolve(uaTrackingService);
            } else {
                const onTrackingServiceCreated = (event: CustomEvent) => {
                    const ts = event?.detail?.trackingService;
                    if (ts?.name === uaTrackingServiceName) {
                        document.removeEventListener("trackingServiceCreated", onTrackingServiceCreated);
                        resolve(ts);
                    }
                };
                document.addEventListener("trackingServiceCreated", onTrackingServiceCreated);
            }
        });
    }

    /**
     * Tracks the tool start, this needs to be executed always when the provider starts
     * this function takes care if the tracking call should be executed and sets the chatStart variable
     */
    public trackToolStart() {

        if(this.toolStartSent) {
            return;
        }

        if(this.chatWindow.configuration.configuration.implicitShutdown) {
            return;
        }

        // check if the tracking is enabled
        if (!this.enabled) {
            return;
        }

        this.setChatWindowActive();

        // check if the chat has been already startet
        this.storedChatState = {
            ...this.storedChatState,
            chatStart: this.getTimestamp(),
            chatState: ChatState.Open,
        }

        // sets the tracking context
        this.setTrackingContext();

        this.setValue('azde.pagesource.sourceelement.type', this.triggerOpenType);

        this.setValue('azde.interactiveTool.start.type', this.trackingOpenType || "user_indirect");
        this.setValue('azde.interactiveTool.start.timestamp', this.chatStart.toString());
        this.setValue('azde.interactiveTool.state', this.chatState);
        this.setValue('azde.interactiveTool.name', this.toolName);
        this.setValue('azde.interactiveTool.type', this.toolCategory);

        this.sendTracking(
            TrackingEventName.Open
        )

        this.toolStartSent = true;
        this.toolStartTriggered = true;

    }

    /**
     * Tracks the tool usage
     * @param direction
     * @param message
     * @param trackingAttributeString
     * @param skipTrackingEvent
     */
    public trackToolUsage(direction: ToolDirection, message: string, trackingAttributeString?: string, skipTrackingEvent?: boolean) {

        if(this.chatWindow.configuration.configuration.implicitShutdown) {
            return;
        }

        const tempHtmlElement = document.createElement("div");
        tempHtmlElement.innerHTML = message;
        message = tempHtmlElement.textContent || tempHtmlElement.innerText || "";

        this.toolUsageQue = this.toolUsageQue + 1;

        setTimeout(() => {
            this.toolUsageQue = this.toolUsageQue -1;
            const doTracking = () => {
                // check if the tracking is enabled
                if(!this.enabled) {
                    return;
                }

                const storedChatState = this.storedChatState;
                if(!this.storedChatState) {
                    return;
                }

                // sets the tracking context
                this.setTrackingContext();

                // extract the number of words
                const numberOfWords = message.replace(/\s+/g, ' ').split(' ').length;
                const currentTimestamp = this.getTimestamp();

                if(direction === ToolDirection.Outbound) {
                    this.storedChatState = {
                        ...this.storedChatState,
                        inboundTimestamp: this.getTimestamp(),
                        chatState: ChatState.Active
                    }
                } else if(direction === ToolDirection.Inbound) {
                    this.storedChatState = {
                        ...this.storedChatState,
                        outboundTimestamp: this.getTimestamp(),
                        chatState: ChatState.Active
                    }
                }

                // calculates the response times
                if(direction === ToolDirection.Outbound && storedChatState.outboundTimestamp) {
                    const {outboundTimestamp} = storedChatState;
                    const responseTime = currentTimestamp-outboundTimestamp;
                    this.setValue('azde.interactiveTool.usage.responsetime', (responseTime).toString())
                    this.storedChatState = {
                        ...this.storedChatState,
                        inboundTimestamp: this.getTimestamp(),
                        chatState: ChatState.Active
                    }
                } else if(direction === ToolDirection.Inbound && storedChatState.inboundTimestamp) {
                    const {inboundTimestamp} = storedChatState;
                    const responseTime = currentTimestamp-inboundTimestamp;
                    this.setValue('azde.interactiveTool.usage.responsetime', (responseTime).toString())
                    this.storedChatState = {
                        ...this.storedChatState,
                        outboundTimestamp: this.getTimestamp(),
                        chatState: ChatState.Active
                    }

                    // track idle via timeout
                    if(this.idleTimeout) {
                        clearTimeout(this.idleTimeout);
                    }
                    this.idleTimeout = setTimeout(() => {
                        this.trackIdleCall();
                    }, this.chatIdleTimeout*1000);

                    if(this.closeTimeout) {
                        clearTimeout(this.closeTimeout);
                    }
                    // 10 minutes wait before close
                    this.closeTimeout = setTimeout(() => {
                        this.chatWindow.api.shutdownService.executeShutdown();
                    }, 10 * 60 * 1000);

                }


                this.setValue('azde.interactiveTool.state', ChatState.Active);
                this.setValue('azde.interactiveTool.name', this.toolName);
                this.setValue('azde.interactiveTool.type', this.toolCategory);
                this.setValue('azde.interactiveTool.usage.direction', direction);
                this.setValue('azde.interactiveTool.usage.payloadWords', numberOfWords.toString());
                this.setValue('azde.interactiveTool.usage.timestamp', this.getTimestamp().toString());

                if(trackingAttributeString) {

                    try {
                        const trackingAttribute = JSON.parse(trackingAttributeString) as TrackingAttribute

                        this.setValue('process.stepNumber', trackingAttribute["Step-Number"].toString()); // e.g. 01.
                        this.setValue('process.stepName', trackingAttribute["Step-Name"]); // e.g. Uebersicht (Sale-Start)
                        this.setValue('process.processName', trackingAttribute["Processname"]);
                        this.setValue('process.category', trackingAttribute["Processcategory"]);

                        this.sendTracking(trackingAttribute["BusinessEvent"] as TrackingEventName || TrackingEventName.Usage)
                    } catch (e) {
                        console.error("could not parse TRACKING attribute as JSON. Using default tracking: " + e.message)
                        if (skipTrackingEvent) {
                            return;
                        }
                        this.sendTracking(
                            TrackingEventName.Usage
                        )
                    }

                } else {
                    if (skipTrackingEvent) {
                        return;
                    }
                    this.sendTracking(
                        TrackingEventName.Usage
                    )
                }

            }

            setTimeout(() => {
                if(this.toolStartTriggered) {
                    setTimeout(() => {
                        this.toolStartTriggered = false;
                        doTracking();
                    }, 500);
                    return;
                }

                doTracking();
            }, 50);
        }, this.toolUsageQue * 1500);

    }

    /**
     * This function extends the trackToolUsage track
     * @param direction
     * @param message
     * @param marketingCampaign
     * @param externalLink
     */
    public trackToolLinkUsage(direction: ToolDirection, message: string, marketingCampaign: string, externalLink: string) {

        if(this.chatWindow.configuration.configuration.implicitShutdown) {
            return;
        }

        // check if the tracking is enabled
        if(!this.enabled) {
            return;
        }

        // set the tracking context
        this.setTrackingContext();

        this.setValue('azde.marketingCampaign.internal', marketingCampaign);
        this.setValue('azde.externalLink.url', externalLink);

        // first track tool usage
        this.trackToolUsage(direction, message);

    }

    public trackIdleCall() {

        // idle tracking is currently disabled
        return;

        this.storedChatState = {
            ...this.storedChatState,
            chatState: ChatState.Idle
        }

        this.setValue('azde.interactiveTool.state', this.chatState);
        this.setValue('azde.interactiveTool.name', this.toolName);
        this.setValue('azde.interactiveTool.type', this.toolCategory);

    }

    /**
     * Tracks the user leaving the tool
     * Should be executed on tool exit
     * @param endType
     */
    public trackToolExit(endType: EndType) {

        if(!this.storedChatState) {
            return;
        }

        // delete the stored chat state
        this.storedChatState = null;

        // check if the tracking is enabled
        if(!this.enabled && !this.chatWindow.configuration.configuration.implicitShutdown) {
            return;
        }

        // track idle via timeout
        if(this.idleTimeout) {
            clearTimeout(this.idleTimeout);
        }

        if(this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }


        // sets the tracking context
        this.setTrackingContext();

        // get the exit timestamp
        const exitTimestamp = this.getTimestamp();

        this.setValue('azde.interactiveTool.state', ChatState.Closed);
        this.setValue('azde.interactiveTool.name', this.toolName);
        this.setValue('azde.interactiveTool.type', this.toolCategory);
        this.setValue('azde.interactiveTool.exit.type', endType);
        this.setValue('azde.interactiveTool.exit.timestamp', exitTimestamp.toString());

        this.sendTracking(
            TrackingEventName.Close
        )

    }

    /**
     * Tracks a survey rating
     * @param givenRating
     * @param maxRating
     */
    public trackSurvey(givenRating: number, maxRating: number) {

        // check if the tracking is enabled
        if(!this.enabled) {
            return;
        }

        // sets the tracking context
        this.setTrackingContext();

        const storedChatState = this.storedChatState;
        if(!this.storedChatState) {
            return;
        }

        const currentTimestamp = this.getTimestamp();


        this.storedChatState = {
            ...this.storedChatState,
            outboundTimestamp: this.getTimestamp(),
            chatState: ChatState.Active
        }

        const {inboundTimestamp} = storedChatState;
        const responseTime = currentTimestamp-inboundTimestamp;
        this.setValue('azde.interactiveTool.usage.responsetime', (responseTime).toString())
        this.storedChatState = {
            ...this.storedChatState,
            outboundTimestamp: this.getTimestamp(),
            chatState: ChatState.Active
        }

        // track idle via timeout
        if(this.idleTimeout) {
            clearTimeout(this.idleTimeout);
        }
        this.idleTimeout = setTimeout(() => {
            this.trackIdleCall();
        }, this.chatIdleTimeout*1000);

        if(this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        // 10 minutes wait before close
        this.closeTimeout = setTimeout(() => {
            this.chatWindow.api.shutdownService.executeShutdown();
        }, 10 * 60 * 1000);


        /**
         * Numeric – range: [1-100]: 100: Best. Note: has to be normalized, eg. If Rating is 1-6 (and 6 is worst) it has to be mapped: 6 → 1 in Range, 1 → 100 in Range etc.
         */
        const ratingSteps = 100 / maxRating;
        const normalizedRating = Math.floor(givenRating * ratingSteps) || 1;

        this.setValue('azde.interactiveTool.rating', normalizedRating.toString());
        this.setValue('azde.interactiveTool.name', this.toolName);
        this.setValue('azde.interactiveTool.type', this.toolCategory);
        this.setValue('azde.interactiveTool.state', this.chatState);
        this.setValue('azde.interactiveTool.type', this.toolCategory);
        this.setValue('azde.interactiveTool.usage.direction', ToolDirection.Inbound);
        this.setValue('azde.interactiveTool.usage.timestamp', this.getTimestamp().toString());

        // track idle via timeout
        if(this.idleTimeout) {
            clearTimeout(this.idleTimeout);
        }
        this.idleTimeout = setTimeout(() => {
            this.trackIdleCall();
        }, this.chatIdleTimeout*1000);

        if(this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
        // 10 minutes wait before close
        this.closeTimeout = setTimeout(() => {
            this.chatWindow.api.shutdownService.executeShutdown();
        }, 10 * 60 * 1000);

        this.sendTracking(
            TrackingEventName.Usage
        )

    }

    /**
     * Gets the current epoch
     */
    private getTimestamp() {
        return Math.floor((new Date).getTime()/1000);
    }

    /**
     * Sets the tracking context
     */
    private setTrackingContext() {
        const uaTrackingServiceName = this?.chatWindow?.configuration?.inbenta?.trackingAppName;

        this.trackingService.setValue("azde.genericApp.appName", "User-Assistant");
        this.trackingService.setValue("azde.genericApp.screenName", "Chatbot");

        if (uaTrackingServiceName) {
            this.setValue('page.pageInfo.pageID', `${uaTrackingServiceName}/Chat-Bot`)
        } else {
            console.warn('this?.chatWindow?.configuration?.inbenta?.trackingAppName is not defined, therefore cannot set page.pageInfo.pageId')
        }
    }

    /**
     * Sets a tracking param
     * @param key
     * @param value
     */
    private setValue(key: string, value: string) {
        if(process.env.NODE_ENV === 'development') {
            console.debug('tracking', 'setValue', key, value)
        }
        if(this.trackingService) {
            this.trackingService.setValue(key, value);
        }
    }

    /**
     * Gets a tracking value
     * @param key
     * @private
     */
    private getValue(key: string): string {
        if(this.trackingService) {
            return this.trackingService.getValue(key);
        }
        return null;
    }

    /**
     * Sends the tracking over to az
     * @param eventName
     */
    private sendTracking(eventName: TrackingEventName) {
        if(process.env.NODE_ENV === 'development') {
            console.debug('tracking', 'sendTracking', eventName)
            console.debug('tracking', '--------------------------------')
        }
        if(this.trackingService) {
            this.trackingService.track({
                name: eventName
            })
        }
    }

    private set storedChatState(state: LocalStorageChatState|null) {
        if(!state) {
            localStorage.removeItem('chatState');
        } else {
            this.chatStart = state.chatStart;
            localStorage.setItem('chatState', JSON.stringify(state));
        }
    }

    private get storedChatState(): LocalStorageChatState|null {

        const rawData = localStorage.getItem('chatState');
        if(!rawData) {
            return null;
        }

        return JSON.parse(rawData) as LocalStorageChatState;

    }

    private setChatWindowActive() {
        window.AllianzTrackingLibrary.utils.getTrackingService('User-Assistant').modifyActiveStatus(true);
    }

}

export default TrackingService;
