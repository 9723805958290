import {ChatState, ChatStateContext} from '../types';

class ContextService {

    private _chatState: ChatStateContext;

    constructor() {
        this._chatState = {
            state: ChatState.Initializing,
            surveyType: null
        }
    }

    get chatState(): ChatStateContext {
        return this._chatState;
    }

    set chatState(value: ChatStateContext) {
        this._chatState = value;
    }
}

export default ContextService;
