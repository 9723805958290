import AbstractProvider from '../AbstractProvider';
import Api from '../../api';

import ContextService from './services/ContextService';
import AuthorizationService from './services/AuthorizationService';
import ConversationService from './services/ConversationService';
import UtilService from './services/UtilService';
import TrackingEventService from './services/TrackingEventService';

import UserMessageSubscriber from './subscribers/UserMessageSubscriber';
import InbentaSubscriber from './subscribers/InbentaSubscriber';
import ChatWindowCloseSubscriber from './subscribers/ChatWindowCloseSubscriber';

import {ApiProvider} from '../providers';
import {Window} from '../../../events/window';
import MessageRenderingService from './services/MessageRenderingService';
import ChatBotService from './services/ChatBotService';
import HandleDirectCallSubscriber from './subscribers/HandleDirectCallSubscriber';
import {SurveyType} from './definition/survey';

import RatingService from './services/RatingService';

import RenderRatingsSubscriber from './subscribers/RenderRatingsSubscriber';
import SideWindowSubscriber from './subscribers/SideWindowSubscriber';
import {SidewindowStorageKey} from './definition/sidewindow';
import RenderRelatedContentSubscriber from './subscribers/RenderRelatedContentSubscriber';
import {DefaultConfig} from '../../../const';
import {TrackingMethods} from '../../../events/tracking/methods';
import {ToolCategory, TriggerOpenType, TriggerType} from '../../service/tracking/types';
import {ProductIcon} from '../../../types';
import CloseChatbotHandler from './services/DirectCallHandler/CloseChatbotHandler';
import AbstractHandler from './services/DirectCallHandler/AbstractHandler';
import ContactAgency1Handler from './services/DirectCallHandler/ContactAgency1Handler';
import ContactAgency2Handler from './services/DirectCallHandler/ContactAgency2Handler';
import ContactAgencyTransitionHandler from './services/DirectCallHandler/ContactAgencyTransitionHandler';
import ContactCallbackHandler from './services/DirectCallHandler/ContactCallbackHandler';
import ContactHotline1Handler from './services/DirectCallHandler/ContactHotline1Handler';
import ContactHotline2Handler from './services/DirectCallHandler/ContactHotline2Handler';
import SurveyFreeTextHandler from './services/DirectCallHandler/SurveyFreeTextHandler';
import SurveyNoHandler from './services/DirectCallHandler/SurveyNoHandler';
import SurveyYesHandler from './services/DirectCallHandler/SurveyYesHandler';
import ContactAgency3Handler from './services/DirectCallHandler/ContactAgency3Handler';
import ContactAgency4Handler from './services/DirectCallHandler/ContactAgency4Handler';
import ContactAgency5Handler from './services/DirectCallHandler/ContactAgency5Handler';
import ChatAcceptedHandler from './services/DirectCallHandler/ChatAcceptedHandler';
import ContactHotline3Handler from './services/DirectCallHandler/ContactHotline3Handler';
import ContactHotline4Handler from './services/DirectCallHandler/ContactHotline4Handler';
import ContactHotline5Handler from './services/DirectCallHandler/ContactHotline5Handler';
import ContactLivechat1Handler from './services/DirectCallHandler/ContactLivechat1Handler';
import ContactLivechat2Handler from './services/DirectCallHandler/ContactLivechat2Handler';
import ContactLivechat3Handler from './services/DirectCallHandler/ContactLivechat3Handler';
import ContactLivechat4Handler from './services/DirectCallHandler/ContactLivechat4Handler';
import ContactLivechat5Handler from './services/DirectCallHandler/ContactLivechat5Handler';
import configureTracking = TrackingMethods.configureTracking;
import ContactPhvLiveChatHandler from "./services/DirectCallHandler/ContactPhvLiveChatHandler";
import ContactRlvOpenCalculatorHandler from "./services/DirectCallHandler/ContactRlvOpenCalculatorHandler";

export default class InbentaProvider extends AbstractProvider {

    util: UtilService;
    context: ContextService;
    authorization: AuthorizationService;
    conversation: ConversationService;
    messageRendering: MessageRenderingService;
    chatBotService: ChatBotService;
    trackingEventService: TrackingEventService;
    ratingService: RatingService;
    directCallHandler: AbstractHandler[]

    eventSubscribers: InbentaSubscriber[];

    private _allowBackOnClose = false;

    constructor(api: Api) {

        super(api, ApiProvider.Inbenta);

        this.util = new UtilService(this);
        this.context = new ContextService(this);
        this.authorization = new AuthorizationService(this);
        this.conversation = new ConversationService(this);
        this.messageRendering = new MessageRenderingService(this);
        this.chatBotService = new ChatBotService(this);
        this.trackingEventService = new TrackingEventService(this);
        this.ratingService = new RatingService(this);
        this.directCallHandler = [
            new ChatAcceptedHandler(this),
            new CloseChatbotHandler(this),
            new ContactAgency1Handler(this),
            new ContactAgency2Handler(this),
            new ContactAgency3Handler(this),
            new ContactAgency4Handler(this),
            new ContactAgency5Handler(this),
            new ContactAgencyTransitionHandler(this),
            new ContactCallbackHandler(this),
            new ContactHotline1Handler(this),
            new ContactHotline2Handler(this),
            new ContactHotline3Handler(this),
            new ContactHotline4Handler(this),
            new ContactHotline5Handler(this),
            new ContactLivechat1Handler(this),
            new ContactLivechat2Handler(this),
            new ContactLivechat3Handler(this),
            new ContactLivechat4Handler(this),
            new ContactLivechat5Handler(this),
            new ContactPhvLiveChatHandler(this),
            new ContactRlvOpenCalculatorHandler(this),
            new SurveyFreeTextHandler(this),
            new SurveyNoHandler(this),
            new SurveyYesHandler(this)
        ]
    }

    initialize = async () => {
        this.configureTracking();
        if(this.chatWindow.configuration.configuration.implicitShutdown) {
            return;
        }
        this.dispatchEvent(Window.Methods.setLoading(this.chatWindow.translation.translate('loading.chat')));
        await this.initializeServices();
        await this.registerEventSubscribers();
        this.dispatchEvent(Window.Methods.setTitle(this.chatWindow.configuration.inbenta.toolHeader || this.chatWindow.configuration.inbenta.toolTitle || this.chatWindow.translation.translate('general.header')));
        this.dispatchEvent(Window.Methods.setReady(!!this.chatWindow.configuration.inbenta.isContinued));
    };

    public async eject() {
        this.conversation.currentConversation = null;
        this.authorization.currentAuthorization = null;
        this.api.persistence.remove(SurveyType.Post.toString());
        this.authorization.clearAuthorizationTimeout();
        this.api.persistence.remove(SidewindowStorageKey);
        await this.unregisterEventSubscribers();
    };

    initializeServices = async () => {
        const services = [
            this.chatBotService,
            // 1
            this.context,
            // 2
            this.authorization,
            // 3, utility depends on authorization
            this.util,
            // 4
            this.conversation,
        ];

        for (const service of services) {
            if (service.initialize) {
                try {
                    await service.initialize();
                } catch (e) {
                    await this.api.shutdownService.executeShutdown();
                    break
                }
            }
        }
    };

    registerEventSubscribers = async () => {

        this.eventSubscribers = [
            new UserMessageSubscriber(this),
            new ChatWindowCloseSubscriber(this),
            new RenderRelatedContentSubscriber(this),
            new RenderRatingsSubscriber(this),
            new SideWindowSubscriber(this),
            new HandleDirectCallSubscriber(this),
        ];

        for (const subscriber of this.eventSubscribers) {
            subscriber.register();
        }
    };

    unregisterEventSubscribers = async () => {
        for (const subscriber of this.eventSubscribers) {
            subscriber.unregister();
        }
    };

    public getProviderInputConfig() {
        return {
            ...super.getProviderInputConfig(),
            InputMaxLength: 250
        }
    }

    public getProviderChatWindowConfig() {
        return {
            ...super.getProviderChatWindowConfig(),
            isHeaderPermanent: true
        }
    }

    public getDefaultConfig() {

        if(this.chatWindow.configuration.inbenta.waitBetweenMessages) {

            const wait = parseInt(this.chatWindow.configuration.inbenta.waitBetweenMessages)

            return {
                ...DefaultConfig,
                BotDelay: wait
            }

        } else {
            return {
                ...DefaultConfig,
                BotDelay: 2000
            }
        }
    }

    public getAgentIcon(): ProductIcon {
        return ProductIcon.Bot
    }

    private configureTracking() {

        const {trackingPageView, trackingOpenType, trackingAppName} = this.chatWindow.configuration.inbenta;
        const appName = this.chatWindow.configuration.inbenta.toolName;

        const trackingConfiguration = configureTracking({
            enabled: true,
            toolCategory: ToolCategory.ChatBot,
            toolName: appName,
            trackingToolName: trackingAppName,
            triggerType: TriggerType.UserTriggered,
            triggerOpenType: TriggerOpenType.Button,
            trackingOpenType: trackingOpenType || "",
            pageName: trackingPageView || ""
        });

        this.dispatchEvent(trackingConfiguration);
    }

    get allowBackOnClose(): boolean {
        return this._allowBackOnClose;
    }

    set allowBackOnClose(value: boolean) {
        this._allowBackOnClose = value;
    }

    // close inbenta after 5 minutes automatically
    closeAfterSeconds(): number {
        return 60 * 5;
    }

    closeConfirmationEnabled(): boolean {
        return true;
    }

}
