import InbentaSubscriber from './InbentaSubscriber';
import InbentaMessageResponseEvent from '../events/InbentaMessageResponseEvent';
import {Interaction} from "../../../../events/interaction";
import {Messaging} from "../../../../events/messaging";
import {ChatState} from "../definition/chatState";
import {SendEventType} from "../definition/events";
import {ProductIcon} from "../../../../types";
import {DirectCall} from "../definition/directCall";

class HandleDirectCallSubscriber extends InbentaSubscriber {

    protected onConstructor() {
        this.registerEventHandler(InbentaMessageResponseEvent.NAME, this.handleInbentaMessageResponse.bind(this));
    }

    handleInbentaMessageResponse(event: InbentaMessageResponseEvent) {

        const answers = event.data.answers;
        if (!answers) {
            return;
        }

        for(const answer of answers) {

            if (!answer.attributes || !Object.keys(answer.attributes).length || typeof answer.attributes['DIRECT_CALL'] === 'undefined') {
                continue;
            }

            for (const directCallHandler of this.inbenta.directCallHandler) {
                if (directCallHandler.canHandle(answer)) {
                    directCallHandler.handle(answer);
                }
            }
        }
    }
}

export default HandleDirectCallSubscriber;
