import JSX from "../../../jsx";

import {RelatedContent as RelatedContentInterface} from "../../../events/messaging/types";
import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import Icon from "../Content/Icon";
import {ProductIcon} from "../../../types";

class RelatedContent extends GeneralComponent<RelatedContentInterface> implements ComponentInterface {

    private isExpanded: boolean = false;

    public render(parentElement: HTMLElement | Element): void {
        this.generateElement();
        parentElement.appendChild(this.domElement);
    }

    private generateElement() {
        this.domElement = (
            <div class="cw__related-content related-content__container">
                <div class="cw__related-content related-content__header">
                    <button onclick={() => {
                        if(this.isExpanded) {
                            this.collapse();
                        } else {
                            this.expand();
                        }
                    }}><p class="c-copy">{this.props.label}</p><Icon icon={ProductIcon.ChevronDown}/></button>
                </div>
                <div class="cw__related-content related-content__content" style="display: none">
                    {this.props.entries.map(entry => {
                        return (
                            <div class="cw__related-content related-content__entry">
                                <button onclick={() => {
                                    entry.onClick(entry);
                                    this.collapse();
                                }}><a class="c-copy">{entry.label}</a></button>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }

    private getContent(): HTMLElement {
        return this.domElement.querySelector('.cw__related-content.related-content__content');
    }


    /**
     *
     */
    private expand() {
        this.isExpanded = true;
        const content = this.getContent();
        content.style.display = '';
        this.domElement.classList.add('related-content__container--expanded');
    }

    /**
     *
     */
    private collapse() {
        this.isExpanded = false;
        const content = this.getContent();
        content.style.display = 'none';
        this.domElement.classList.remove('related-content__container--expanded');
    }

}

export default RelatedContent;
