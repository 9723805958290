import {ShowSideWindowEvent, CloseSideWindowEvent} from "./events/SideWindowEvent";
import {SideWindowOptions} from "./types";
import RenderRelatedContentEvent from "./events/RenderRelatedContentEvent";
import {RelatedContent} from "../../../../../events/messaging/types";

export namespace InteractionMethods {
    export const showSideWindow = (options: SideWindowOptions) => {
        return new ShowSideWindowEvent({
            ...options
        })
    };

    export const closeSideWindow = (options: SideWindowOptions) => {
        return new CloseSideWindowEvent({
            ...options
        });
    }

    export const renderRelatedContent = (options: RelatedContent) => {
        return new RenderRelatedContentEvent({
            ...options
        });
    }
}

