import JSX from "../../../jsx";

interface CtaMessageButtonProps {
    onClick: () => void,
    label: string
}

const CtaMessageButton = (props: CtaMessageButtonProps) => {
    return <button class="c-button c-button--secondary cw__button-skip" onclick={props.onClick}>{props.label}</button>
};

export default CtaMessageButton;
