import {AbstractEvent} from "../../AbstractEvent";
import {WindowEvents} from "../events";

/**
 * Is dispatched when the close button is pressed
 */
class CloseEvent extends AbstractEvent<boolean> {
    static NAME = WindowEvents.onClose;
}

export default CloseEvent;