import InbentaProvider from "../inbenta";

abstract class InbentaService {

    inbenta: InbentaProvider;

    constructor(inbenta: InbentaProvider) {
        this.inbenta = inbenta;
    }

    async initialize() {}

}


export default InbentaService;
