import AbstractSubscriber from "./AbstractSubscriber";
import {Window} from "../events/window";

class BeforeUnloadSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this))
    }

    handleBeforeUnload() {
        this.dispatchEvent(Window.Methods.setBrowserUnloading());
    }

}

export default BeforeUnloadSubscriber;