import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import LoadingEvent from "../../events/window/events/LoadingEvent";
import {Window} from "../../events/window";

/**
 * This class represents the chat window loading animation
 */
class Loader extends GeneralComponent<any> implements ComponentInterface {

    public onConstructor() {
        this.registerEventHandler(Window.Events.onLoading, this.handleChatWindowLoadingEvent.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.generateComponent();
        parentElement.appendChild(this.domElement);
    }

    private generateComponent() {

        this.domElement = (
            <div class={'cw__messages-container cw__loader'}>
                <div class="cw__loader__container">
                    <div class="cw__loader__block">
                        <div class="cw__loader__start"/>
                        <div class="cw__loader__end"/>
                    </div>
                </div>
                <p class="c-copy cw__loading-text" />
            </div>
        );
        this.domElement.style.display = 'none';

    }

    /**
     * Handles the chat window loading event
     * @param event
     */
    private handleChatWindowLoadingEvent(event: LoadingEvent) {

        // display / not display the loading animation
        this.visible = event.data.loading;

        if(event.data.loading) {
            // set the loading text based on the given message
            this.text = event.data.message;
        } else {
            // unset the text if the loading animation is hidden
            this.text = '';
        }

    }

    /**
     * Sets the visibility state
     * @param visible
     */
    private set visible(visible: boolean) {
        this.domElement.style.display = visible ? '' : 'none';
    }

    /**
     * Sets the loading state
     * @param text
     */
    private set text(text: string) {
        (this.domElement.querySelector('.cw__loading-text') as HTMLElement).innerText = text || '';
    }
}

export default Loader;
