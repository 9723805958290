import {GeneralComponent} from '../components/GeneralComponent';
import {ApiProvider} from '../api/providers/providers';
import {ContentGuruConst, DefaultConfig, InbentaConst, LivepersonConst} from '../const';
import StartupHelper from '../helpers/StartupHelper';
import {environment} from '../helpers/DependencyInjectionHelper';
import {EngagementFlow} from '../api/providers/liveperson/definition/const';
import {Environment} from '../api/providers/inbenta/definition/environment';
import {TriggerOpenType, TriggerType} from '../api/service/tracking/types';

/**
 * The basic chat window configuration
 */
export interface ChatWindowConfiguration {

    /**
     * The provider name
     * In case of liveperson, use "liveperson"
     */
    provider: ApiProvider,

    /**
     * Sets the chat-window's environment
     * AEM = The chat window is used in AEM
     * EXTERNAL =  The chat window is used outside of AEM
     */
    environment?: environment // default = 'AEM'

    // holds the trigger type
    triggerType?: TriggerType,

    // holds the trigger open type
    triggerOpenType?: TriggerOpenType,

    // holds the tool name
    toolName?: string

    heartbeat?: number
    lastInteraction?: number

    // is set to true to reset the session on startup
    resetSession?: boolean

    // overwrites the header name
    // deprecated
    toolTitle?: string;
    // the new one
    toolHeader?: string;

    // tracking vars
    trackingAppName?: string
    trackingOpenType?: string
    trackingPageView?: string

    implicitShutdown?: boolean
    isContinued?: boolean

}

export interface LivePersonConfiguration extends ChatWindowConfiguration {

    /**
     * The liveperson account number
     */
    accountNumber: string

    /**
     * The liveperson engagement id
     *
     * Use this parameter to manually set the fetched engagement
     * If no engagement is set, liveperson decides which engagement to use
     */
    engagementId?: string // default = ''

    /**
     * Enables / disables the use of liveperson surveys
     * Every value defaults to true
     */
    preChatSurveyEnabled?: boolean // default = true
    postChatSurveyEnabled?: boolean // default = true
    offlineSurveyEnabled?: boolean // default = true

    /**
     * Allows to set the survey's name
     * If no name is set, liveperson decides which survey to provide
     *
     * Those parameters should be used in order to guarantee the correct survey to displayed
     */
    preChatSurveyName?: string // default = ''
    postChatSurveyName?: string // default = ''
    offlineSurveyName?: string // default = ''

    // the engagement flow, defaults to monitored
    engagementFlow: EngagementFlow

    skillId?: string;

}

export interface InbentaConfiguration extends ChatWindowConfiguration {

    // the inbenta survey id, hallo configured
    surveyId?: string

    // holds the inbenta api key
    apiKey?: string

    // holds the inbenta domain key
    domainKey?: string,

    // holds the inbenta environment
    inbentaEnvironment?: Environment,

    // also allows to send feedback for thumbs up rating
    enableFeedbackForAllRatings?: boolean,

    // allows to set the message que delay in MILISECONDS
    waitBetweenMessages?: string,

    // when passing a direct call
    // this direct call is sent instead of the initial empty message
    directCall?: string

    // holds inbenta context variables
    context?: {
        [key:string]: string|string[]
    };

}

export interface ContentGuruConfiguration extends ChatWindowConfiguration {
    apiKey?: string;

    apiSecret?: string;

    skillName?: string;
    context?: string;
    policyNumber?: string;
    claimNumber?: string;
    customerId?: string;
    userType?: string;

    /**
     * The dummy
     */
    // Provide default value

    accountNumber: string;

    /**
     * The liveperson engagement id
     *
     * Use this parameter to manually set the fetched engagement
     * If no engagement is set, liveperson decides which engagement to use
     */
    engagementId?: string // default = ''

    /**
     * Enables / disables the use of liveperson surveys
     * Every value defaults to true
     */
    preChatSurveyEnabled?: boolean // default = true
    postChatSurveyEnabled?: boolean // default = true
    offlineSurveyEnabled?: boolean // default = true

    /**
     * Allows to set the survey's name
     * If no name is set, liveperson decides which survey to provide
     *
     * Those parameters should be used in order to guarantee the correct survey to displayed
     */
    preChatSurveyName?: string // default = ''
    postChatSurveyName?: string // default = ''
    offlineSurveyName?: string // default = ''

    // the engagement flow, defaults to monitored
    engagementFlow: EngagementFlow

    skillId?: string;

}

export interface TestingConfiguration extends ChatWindowConfiguration {

}

/**
 * This service allows to read the configuration, passed to the root component
 *
 * Make sure to call ConfigurationService.configuration, after the Document has loaded! Otherwise this function will throw an exception
 *
 * @example
 * HTML Node: <div class="centered" id="liveperson" data-config='{"provider":"liveperson","localstoragePrefix":"liveperson"}'></div>
 *
 * Usage:
 *      ConfigurationService.configuration.provider -> "liveperson"
 *      ConfigurationService.configuration.localstoragePrefix -> "liveperson"
 */
class ConfigurationService extends GeneralComponent<any> {

    protected onConstructor() {
        StartupHelper.currentConfiguration = this.configuration;
    }

    get configuration(): ChatWindowConfiguration {

        const encodedConfigurationData = this.chatWindow.domContainer.hasAttribute(DefaultConfig.ConfigurationAttributeKey) ? this.chatWindow.domContainer.getAttribute(DefaultConfig.ConfigurationAttributeKey) : '{}';
        let parsedConfiguration: ChatWindowConfiguration = JSON.parse(encodedConfigurationData);

        if(parsedConfiguration.provider === ApiProvider.LivePerson) {

            const livepersonConfig = parsedConfiguration as LivePersonConfiguration;

            return {
                ...livepersonConfig,
                environment: livepersonConfig.environment || 'AEM',
                provider: livepersonConfig.provider || ApiProvider.LivePerson,
                accountNumber: livepersonConfig.accountNumber || LivepersonConst.AccountNumber,
                engagementFlow: livepersonConfig.engagementFlow || EngagementFlow.Monitored
            } as LivePersonConfiguration;
        } else if(parsedConfiguration.provider === ApiProvider.Inbenta) {

            const inbentaConfig = parsedConfiguration as InbentaConfiguration;

            return {
                ...inbentaConfig,
                apiKey: inbentaConfig.apiKey || InbentaConst.ApiKey,
                domainKey: inbentaConfig.domainKey || InbentaConst.DomainKey,
                inbentaEnvironment: inbentaConfig.inbentaEnvironment || Environment.Production
            } as InbentaConfiguration

        } else if(parsedConfiguration.provider == ApiProvider.ContentGuru) {

            const contentGuruConfig = parsedConfiguration as ContentGuruConfiguration;

            return {
                ...contentGuruConfig,
                apiKey: contentGuruConfig.apiKey ||ContentGuruConst.ApiKey,
                apiSecret: contentGuruConfig.apiSecret || ContentGuruConst.ApiSecret,
                skillName: contentGuruConfig.skillName || null,
                context: contentGuruConfig.context || null,
                policyNumber: contentGuruConfig.policyNumber || null,
                claimNumber: contentGuruConfig.claimNumber || null,
                customerId: contentGuruConfig.customerId || null,
                userType: contentGuruConfig.userType || null
            } as ContentGuruConfiguration
        }

        else {
            return parsedConfiguration;
        }
    }

    /**
     * Returns the current configuration as liveperson configuration
     */
    get liveperson(): LivePersonConfiguration {
        return this.configuration as LivePersonConfiguration;
    }

    /**
     * Returns the current configuration as inbenta configuration
     */
    get inbenta(): InbentaConfiguration {
        return this.configuration as InbentaConfiguration;
    }

    get contentGuru(): ContentGuruConfiguration {
        return this.configuration as ContentGuruConfiguration;
    }

    /**
     * Returns the current configuration as testing configuration
     */
    get testing(): TestingConfiguration {
        return this.configuration as TestingConfiguration;
    }

}

export default ConfigurationService;
