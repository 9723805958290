import JSX from "../../../jsx";
import {ComponentInterface, GeneralComponent} from "../../GeneralComponent";
import {MessageType, ProductIcon} from "../../../types";
import GeneralMessage from "./GeneralMessage";
import {DirectCallContainer} from "../Content/DirectCallWrapper";
import {Messaging} from "../../../events/messaging";
import {Interaction} from "../../../events/interaction";

interface DropdownSearchProps {
    data: string[]
}

class DropdownSearchMessage extends GeneralComponent<DropdownSearchProps> implements ComponentInterface {

    protected onConstructor() {
        this.registerEventHandler(Messaging.Events.onUserMessage, this.handleUserInteractionEvent.bind(this));
        this.registerEventHandler(Messaging.Events.onUserInteraction, this.handleUserInteractionEvent.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.dispatchEvent(Interaction.Methods.hideInteraction());
        this.generateContainer();
        parentElement.appendChild(this.domElement);
    }

    private generateContainer() {
        this.domElement = (
            <GeneralMessage
                type={MessageType.Received}
                icon={ProductIcon.Bot}
            >
                <DirectCallContainer ariaLabel={"DropdownSearch"}>
                    <div class="cw__dropdown-search">
                        <input type="text" placeholder="Suchen Sie hier die Tierrasse." className="cw__dropdown-search-input" onkeyup={this.filter.bind(this)} /> {/* TODO: use xss protection TODO: placeholder dynamic */}
                        <div className="cw__dropdown-search-options">
                            {this.props.data.map((option, index) => {
                                return (
                                    <span onclick={this.generateMessage.bind(this)}>{option}</span>
                                )
                            })}
                        </div>
                        <p className="cw__dropdown-search-default-message c-copy cw__message__content-content__text">Keine Rassen gefunden.</p>
                    </div>
                </DirectCallContainer>
            </GeneralMessage>
        );
    }

    private generateMessage(event) {
        if (!event.target.textContent) {
            return;
        }
        this.dispatchEvent(Interaction.Methods.showInteraction());
        this.dispatchEvent(Messaging.Methods.submitUserMessage(event.target.textContent));
        this.domElement.remove();
    }

    private handleUserInteractionEvent() {
        this.domElement.remove();
    }

    private getDropdownSearchOptions(): HTMLCollectionOf<HTMLElement> {
        return document.getElementsByClassName('cw__dropdown-search-options')[0]?.children as HTMLCollectionOf<HTMLElement>;
    }

    private filter(event): void {
        const items = this.getDropdownSearchOptions();
        let hiddenElementCounter = 0;
        if (items?.length === 0) {
            return;
        }
        for (let i = 0; i < items.length; i++) {
            const value = items[i].textContent;
            if (value.toLowerCase().indexOf(event.currentTarget.value.toLowerCase()) > -1) {
                items[i].style.display = "";
            } else {
                hiddenElementCounter++;
                items[i].style.display = "none";
            }
        }
        const errorMessageElement = document.getElementsByClassName('cw__dropdown-search-default-message')[0] as HTMLElement;
        if (errorMessageElement) {
            errorMessageElement.style.display = hiddenElementCounter === items.length ? "block" : "none";
        }
    }
}

export default DropdownSearchMessage;
