import {GeneralComponent} from '../components/GeneralComponent';
import {Window} from '../events/window';

/**
 * This service methods should be used to control the chat window using other javascript
 */
class PublicMethodsService extends GeneralComponent<any> {

    /**
     * Opens the print dialog
     */
    public print() {
        this.chatWindow.api.printService.print();
    }

    /**
     * Minimizes the chat window
     */
    public minimize() {
        this.dispatchEvent(Window.Methods.minimizeWindow());
    }

    /**
     * Un minimizes the chat window
     */
    public unMinimize() {
        this.dispatchEvent(Window.Methods.unMinimizeWindow());
    }

    /**
     * Closes the ChatWindow
     */
    public close() {
        this.chatWindow.api.shutdownService.executeShutdown().then(() => {

        });
    }

}

export default PublicMethodsService;
