import Environment from "../helpers/Environment";

class AgentMessageService {
    static scrollToLatestFirstMessage() {
        const firstMessageList = document.querySelectorAll('.cw__message-received.first');
        const body = document.querySelector('.cw__body');
        if (firstMessageList.length) {
            const latestFirstMessage = firstMessageList[firstMessageList.length - 1] as HTMLElement;
            if (latestFirstMessage) {
                setTimeout(() => {
                    body.scrollTop = body.scrollHeight;
                }, 0);

                setTimeout(() => {
                    latestFirstMessage.scrollIntoView();
                }, 0)
            }
        }
    }

    static removeFirstMessageFlag() {
        document.querySelectorAll('.cw__message-received').forEach(message => {
            message.classList.remove('first');
        })
    }
}

export default AgentMessageService;
