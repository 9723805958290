/**
 * Should be emitted when the agent is typing
 */

import {AbstractEvent} from "../../AbstractEvent";
import {TypingState} from "../types";
import {TypingEvents} from "../events";

/**
 * Is dispatched by the provider, when the agent / bot is typing
 */
class AgentTypingEvent extends AbstractEvent<TypingState> {
    static NAME = TypingEvents.onAgentTyping;
}

export default AgentTypingEvent;