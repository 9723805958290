/**
 * Will be emitted when the user submits a message in the message dialog
 */

import {AbstractEvent} from "../../AbstractEvent";
import {MessagingEvents} from "../events";
import {UserMessageEventConfig} from "../types";

/**
 * This event is dispatched, when the user submits a message into the message box
 */
class UserMessageEvent extends AbstractEvent<UserMessageEventConfig> {
    static NAME = MessagingEvents.onUserMessage;
}

export default UserMessageEvent;