import AbstractSubscriber from "../../../../events/AbstractSubscriber";
import InbentaProvider from "../inbenta";

abstract class InbentaSubscriber extends AbstractSubscriber {

    inbenta: InbentaProvider;

    constructor(liveperson: InbentaProvider) {
        super(liveperson.chatWindow, null);
        this.inbenta = liveperson;
    }

}

export default InbentaSubscriber;