import JSX from "../../../jsx";

interface DirectCallSubmitProps {
    label: string
    onClick?: (event) => void
}

export const DirectCallSubmit = (props: DirectCallSubmitProps) => {
    return <div class={`cw__direct-call direct-call__call-container single`}>
        <button type="submit" class="c-button cw__direct-call direct-call__button direct-call__submit"
                onclick={props.onClick}>{props.label}</button>
    </div>
};
