import {AbstractEvent} from '../../AbstractEvent';
import {UtilityEvents} from '../events';
import onScrollToBottom = UtilityEvents.onScrollToBottom;

interface ScrollToBottomEventConfig {
    withDelay: boolean,
    force?: boolean
}

class ScrollToBottomEvent extends AbstractEvent<ScrollToBottomEventConfig> {
    static NAME = onScrollToBottom;
}

export default ScrollToBottomEvent;
