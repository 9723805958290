import AbstractSubscriber from '../AbstractSubscriber';
import ReadyEvent from '../../events/window/events/ReadyEvent';
import {WindowEvents} from '../../events/window/events';
import onReady = WindowEvents.onReady;

class ChatOpenSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(onReady, this.handleReadyEvent.bind(this));
    }

    private handleReadyEvent(event: ReadyEvent) {
        if(!event.data.isRestored) {
            this.chatWindow.tracking.trackToolStart();
        }
    }

}

export default ChatOpenSubscriber;
