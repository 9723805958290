import {ApiProvider} from "../../api/providers/providers";
import ProviderChangeEvent from "./events/ProviderChangeEvent";
import ApiReadyEvent from "./events/ApiReadyEvent";

export namespace ApiMethods {

    /**
     * Sets the current provider
     * @param provider
     */
    export const setProvider = (provider: ApiProvider) => {
        return new ProviderChangeEvent(provider);
    }

    export const apiReady = (domElement: HTMLElement) => {
        return new ApiReadyEvent({domElement});
    }
}