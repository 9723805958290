import JSX from '../jsx';

import {Asset} from '../types';
import Environment from './Environment';

class AssetHelper {

    static getAssetUrl(asset: Asset) {
        const url = Environment.isDevelopment() ? '../public/img' : (process.env.ASSET_URL ? process.env.ASSET_URL : 'https://content.allianz.de/bundles/allianzchatwindow/assets');
        return `${url}${asset}`;
    }

    static getAssetAltTag(asset: Asset): string {
        switch (asset) {
            case Asset.Welcome:
                return "Mann und Frau vor Laptop.";
            case Asset.Survey:
                return "Frau mit erhobenen Armen. Im Hintergrund eine Umfrage und ein Taschenrechner.";
            case Asset.ConversationEnd:
                return "Zwei Personen die sich einen Handschlag geben.";
            case Asset.Question:
                return "Mann mit Fragezeichen in Sprechblase";
            default: return "";
        }
    }

    static renderAssetAsImage(asset: Asset) {
        const assetImage = <img src={AssetHelper.getAssetUrl(asset)} alt={AssetHelper.getAssetAltTag(asset)} />;
        return assetImage.outerHTML;
    }

}

export default AssetHelper;
