import JSX from '../../jsx';
import {GeneralComponent} from '../../components/GeneralComponent';
import {BasicMessageType, ProductIcon, SurveyType} from '../../types';
import {Interaction} from '../../events/interaction';
import {ChatState, ChatStateContext} from '../types';
import {Messaging} from '../../events/messaging';
import {getIconHTML} from '../../components/General/Content/Icon';

export interface PrintServiceConfiguration {
    agentName: string
    visitorName: string
}

export interface PrintableMessage {
    source: BasicMessageType,
    sourceName?: string
    timestamp?: Date
    message: string,
}

export interface AdditionalSurveyFeature {
    label: string,
    execute: () => void
}

const initialPrintingConfiguration: PrintServiceConfiguration = {
    agentName: 'Agent',
    visitorName: 'User'
};

class PrintService extends GeneralComponent<any> {

    private additionalFeatures: AdditionalSurveyFeature[];
    private originalChatStateContext: ChatStateContext;
    private onFinishHook: (usedMethod: string) => void;

    private configuration: PrintServiceConfiguration;
    private conversation: PrintableMessage[];

    public visitorName: string;
    public agentName: string;
    public systemName: string;

    private currentWindow: Window;


    /**
     * Configures the printing service
     * @param configuration
     */
    public configure(configuration?: PrintServiceConfiguration) {
        this.configuration = configuration || initialPrintingConfiguration;
        this.visitorName = this.chatWindow.translation.translate('printing.visitor-name.default');
        this.agentName = this.chatWindow.translation.translate('printing.agent-name.default');
        this.systemName = this.chatWindow.translation.translate('printing.system-name.default');
        this.conversation = [];
        this.additionalFeatures = [];
    }

    /**
     * Resets the printing service
     */
    public reset() {
        this.configuration = null;
        this.conversation = [];
    }

    /**
     * Pushes a message to the printing service
     * @param printableMessage
     */
    public pushMessage(printableMessage: PrintableMessage) {

        if(!printableMessage.sourceName) {
            // adds the current name to the message
            if(printableMessage.source === BasicMessageType.Agent) {
                printableMessage.sourceName = this.agentName;
            } else if(printableMessage.source === BasicMessageType.User) {
                printableMessage.sourceName = this.visitorName;
            } else if(printableMessage.source === BasicMessageType.System) {
                printableMessage.sourceName = this.systemName;
            }
        }

        // sets the current date if not set
        if(!printableMessage.timestamp) {
            printableMessage.timestamp = new Date();
        }

        // push it into the memory
        this.conversation.push(printableMessage);

    }

    /**
     * Prints the conversation
     */
    public print() {

        this.currentWindow = window.open("", "chat-window-printing-dialog", "width=1200,height=800");


        const paperCss = this.currentWindow.document.createElement('link');
        paperCss.rel = 'stylesheet';
        paperCss.href = 'https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css';

        const bootstrap = this.currentWindow.document.createElement('link');
        bootstrap.rel = 'stylesheet';
        bootstrap.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';

        const styleConfiguration = this.currentWindow.document.createElement('style');
        styleConfiguration.innerHTML = '* {font-family: arial;}';

        paperCss.onload = () => {

            const timestamp = new Date();

            const day = ('0' + timestamp.getDate()).slice(-2);
            const month = ('0' + (timestamp.getMonth() + 1)).slice(-2);
            const year = timestamp.getFullYear();

            this.currentWindow.document.body.classList.add('A4');

            const bodyComponent = (
                <div>
                    <section class="sheet padding-5mm" style="height: fit-content !important; padding: 0 1rem 1rem 1rem;">
                        <article>
                            <h3>{this.chatWindow.translation.translate('printing.headline', day, month, year)}</h3><br/>
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" width="200px">{this.chatWindow.translation.translate('printing.header.name')}</th>
                                    <th scope="col" width="100px">{this.chatWindow.translation.translate('printing.header.timestamp')}</th>
                                    <th scope="col">{this.chatWindow.translation.translate('printing.header.message')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.conversation.map(message => {

                                    const timestamp = message.timestamp;

                                    const hour = timestamp.getHours() < 10 ? '0' + timestamp.getHours() : timestamp.getHours();
                                    const minutes = timestamp.getMinutes() < 10 ? '0' + timestamp.getMinutes() : timestamp.getMinutes();

                                    return (
                                        <tr>
                                            <td>{message.sourceName}</td>
                                            <td>{this.chatWindow.translation.translate('printing.message.timestamp', hour, minutes)}</td>
                                            <td>{message.message}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </article>

                    </section>
                </div>
            ) as HTMLElement;

            this.currentWindow.document.body.innerHTML = bodyComponent.innerHTML;
            this.currentWindow.document.title = this.chatWindow.translation.translate('printing.window.title');

            this.currentWindow.focus();
            this.currentWindow.print();

            this.currentWindow.onclose = () => {
                this.currentWindow = null;
            }

        };

        this.currentWindow.document.head.appendChild(paperCss);
        this.currentWindow.document.head.appendChild(styleConfiguration);

    }

    /**
     * Registers an additional survey feature
     * @param feature
     */
    public registerAdditionalFeature(feature: AdditionalSurveyFeature) {
        this.additionalFeatures.push(feature);
    }

    /**
     * Stars the printing survey
     */
    public startSurvey(onFinishHook?: (usedMethod: string) => void) {

        this.onFinishHook = onFinishHook ? onFinishHook : null;

        this.originalChatStateContext = this.chatWindow.api.context.chatState;

        this.chatWindow.api.context.chatState = {
            state: ChatState.Survey,
            surveyType: SurveyType.Print
        };

        const [yes, no] = [
            this.chatWindow.translation.translate('wordings.yes'),
            this.chatWindow.translation.translate('wordings.no')
        ];

        this.dispatchEvent(Interaction.Methods.showSingleChoice([
            {
                label: yes,
                onClick: () => {
                    this.dispatchEvent(Messaging.Methods.renderUserMessage(yes));
                    this.renderSurveyFeatures();
                }
            },
            {
                label: no,
                onClick: () => {
                    this.dispatchEvent(Messaging.Methods.renderUserMessage(no));
                    if(this.originalChatStateContext.state === ChatState.Chatting) {
                        this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction());
                    } else {
                        this.dispatchEvent(Interaction.Methods.hideInteraction());
                    }
                    this.surveyFulfilled('Frage übersprungen');
                }
            }
        ], {}))

    }

    private renderSurveyFeatures() {
        const printFeature: AdditionalSurveyFeature = {
            label: this.chatWindow.translation.translate('printing.feature.print.label'),
            execute: () => {
                this.dispatchEvent(Interaction.Methods.hideInteraction());
                this.dispatchEvent(Messaging.Methods.renderUserMessage(this.chatWindow.translation.translate('printing.feature.print.user-message')));
                this.dispatchEvent(Messaging.Methods.renderAgentMessage(this.chatWindow.translation.translate('printing.feature.print.on-click'), {
                    icon: ProductIcon.Survey
                }));
                this.chatWindow.messageTransforming.withDelay(() => {
                    this.print();
                    this.handleFinishedSurvey('Print');
                });
            }
        };

        const features = [
            ...this.additionalFeatures,
            printFeature,
        ];

        this.dispatchEvent(Messaging.Methods.renderAgentMessage(this.chatWindow.translation.translate('printing.survey.header'), {
            icon: ProductIcon.Survey
        }));

        this.dispatchEvent(Interaction.Methods.showSingleChoice(features.map(feature => {
            return {
                label: feature.label,
                onClick: feature.execute,
            }
        })))
    }

    public surveyFulfilled(usedMethod: string, callback?: (chatState: ChatStateContext) => void) {
        if(callback && typeof callback === 'function') {
            callback(this.originalChatStateContext);
        }

        if(this.originalChatStateContext && this.chatWindow.api.context.chatState !== this.originalChatStateContext) {
            this.chatWindow.api.context.chatState = this.originalChatStateContext;
        }

        if(this.onFinishHook && typeof this.onFinishHook === 'function') {
            this.onFinishHook(usedMethod);
        }
    }

    /**
     * Is executed when the print dialog finishes
     */
    public handleFinishedSurvey(usedMethod: string) {

        const chatState = this.chatWindow.api.context.chatState;
        if(chatState.state === ChatState.Survey) {
            this.surveyFulfilled(usedMethod, newChatState => {
                if(newChatState.state === ChatState.Chatting) {
                    this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction());
                }
            })
        }

    }

}

export default PrintService;
