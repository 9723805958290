import {AbstractEvent} from "../../AbstractEvent";
import {MessagingEvents} from "../events";
import {RenderMessageComponentEventConfig} from "../types";

/**
 * This event is used to pass a Messages component to the message box
 * Use this event to render custom speech bubbles like agent or user messages
 *
 * @example
 *
 * const myAwesomeComponent = new AwesomeComponent(this.chatWindow)
 * this.dispatchEvent({component: myAwesomeComponent})
 *
 *      -> This renders the awesome component into the messages box
 */
class RenderMessageComponentEvent extends AbstractEvent<RenderMessageComponentEventConfig> {
    static NAME = MessagingEvents.onRenderMessageComponent
}

export default RenderMessageComponentEvent;