import InbentaSubscriber from './InbentaSubscriber';
import {CloseSideWindowEvent, ShowSideWindowEvent} from "../events/interaction/events/SideWindowEvent";
import {Interaction as InbentaInteraction} from "../events/interaction";
import {Interaction} from "../../../../events/interaction";
import {SidewindowStorageKey} from "../definition/sidewindow";
import SideWindow from "../components/SideWindow/SideWindow";
import ApiReadyEvent from "../../../../events/api/events/ApiReadyEvent";
import {SideWindowOptions} from "../events/interaction/types";

class RenderRatingsSubscriber extends InbentaSubscriber {

    private isSideWindowRendered: boolean;

    protected onConstructor() {
        this.registerEventHandler(ApiReadyEvent.NAME, this.onApiReady.bind(this));
        this.registerEventHandler(CloseSideWindowEvent.NAME, this.handleCloseSideWindow.bind(this));
        this.registerEventHandler(ShowSideWindowEvent.NAME, this.handleShowSideWindow.bind(this));
    }

    onApiReady(event: ApiReadyEvent) {
        if(!this.isSideWindowRendered) {
            const sideWindow = new SideWindow(this.chatWindow);
            sideWindow.render(event.data.domElement);
            this.isSideWindowRendered = true;

            const sideWindowStorage = this.inbenta.api.persistence.get(SidewindowStorageKey) as SideWindowOptions;
            if (!!sideWindowStorage) {
                this.inbenta.dispatchEvent(InbentaInteraction.Methods.showSideWindow(sideWindowStorage))
            }
        }
    }

    handleShowSideWindow(event: ShowSideWindowEvent) {
        this.inbenta.api.persistence.set(SidewindowStorageKey, {...event.data});
    }

    handleCloseSideWindow(event: CloseSideWindowEvent) {
        if (!event.data.preventShowingTextInputOnClose) {
            this.inbenta.util.renderInputBox(true);
        }
        this.inbenta.api.persistence.remove(SidewindowStorageKey);
    }

}

export default RenderRatingsSubscriber;
