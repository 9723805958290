import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";
import { DirectCall } from "../../definition/directCall";

class ContactZzvOpenCalculatorHandler extends AbstractHandler {

    canHandle(answer: Answer): boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ZzvRedirect);
    }

    handle(answer: Answer) {
        setTimeout(() => {
            window.location.href = "https://www.allianz.de/gesundheit/zahnzusatzversicherung/rechner/"
        }, 3000);
    }

}

export default ContactZzvOpenCalculatorHandler
