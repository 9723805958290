import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";
import {DirectCall} from "../../definition/directCall";

class ContactLivechat3Handler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.ContactLivechat3);
    }

    public handle(answer: Answer) {
        setTimeout(() => {
            this.inbenta.chatWindow.api.shutdownService.executeShutdown();

            const url = "https://www.allianz.de/service/chat/?ua=Chat";
            let win = window.open(url, '_blank')
            if (win) {
                win.focus();
            }
        }, 1500);
    }

}

export default ContactLivechat3Handler;
