export enum TriggerType {
    Auto = 'auto',
    UserTriggered = 'user_triggered',
}

export enum TriggerOpenType {
    Tooltip = 'launchChatTooltip',
    Button = 'launchChatButton',
}

export enum ChatState {
    Open = 'open',
    Active = 'active',
    Idle = 'idle',
    Closed = 'closed'
}

export enum ToolCategory {
    ChatBot = 'Chatbot',
    LiveChat = 'Livechat',
    FaqTool = 'FAQTool',
}

/**
 * Generates the tool usage
 * @param stateHistory
 */
export const generateToolUsage = (stateHistory: ChatState[]): string => {

    if(!stateHistory.length) {
        return '';
    }

    return `- ${stateHistory.join('- ')}`
};

export enum ToolDirection {
    Outbound = 'outbound',
    Inbound = 'inbound',
}

export const generateToolDirection = (toolDirectionHistory: ToolDirection[]): string => {

    if(!toolDirectionHistory.length) {
        return '';
    }

    return `- ${toolDirectionHistory.join('- ')}`

};

export enum EndType {
    Explicit = 'explicit',
    Implicit = 'implicit',
    Timeout = 'timeout',
}

export enum TrackingEventName {
    Open = 'open',
    Close = 'close',
    Interaction = 'interaction',
    Usage = 'usage',
}

export interface LocalStorageChatState {
    chatState: ChatState
    chatStart: number
    outboundTimestamp: number
    inboundTimestamp: number
}
