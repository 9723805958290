import {ChatWindowConfiguration} from './ConfigurationService';
import {GeneralComponent} from '../components/GeneralComponent';

import {name, version} from '../../../../package.json';
import Environment from '../helpers/Environment';

export interface AppData {
    name: string
    version: string
    uri: string
    configuration: ChatWindowConfiguration
}

export interface EventData {
    message: string
    code: number
    stacktrace: string
    data?: any
}

export interface RequestData {
    app: AppData,
    event: EventData
}

class ErrorReportingService extends GeneralComponent<any> {

    private get requestUrl(): string {
        return `${Environment.isDevelopment() ? '' : 'https://content.allianz.de'}/spa-api/error-reporting`;
    }

    /**
     * Builds the app data
     */
    public buildAppData(): AppData {
        return {
            name,
            version,
            uri: window.location.href,
            configuration: this.chatWindow.configuration.configuration
        }
    }

    public async trackError(message: string, code: number, data?: any) {
        const stack = new Error().stack;

        const eventData: EventData = {
            message,
            code,
            stacktrace: stack,
            data
        };

        const request: RequestData = {
            app: this.buildAppData(),
            event: eventData
        };

        await fetch(this.requestUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(res => res.json());

        return request

    }

}

export default ErrorReportingService;
