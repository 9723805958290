import {InteractionEvents} from "../events";
import {AbstractEvent} from "../../../../../../events/AbstractEvent";
import {SideWindowOptions} from "../types";

export class ShowSideWindowEvent extends AbstractEvent<SideWindowOptions> {
    static NAME = InteractionEvents.showSideWindowShowInteraction;
}

export class CloseSideWindowEvent extends AbstractEvent<SideWindowOptions> {
    static NAME = InteractionEvents.closeSideWindowShowInteraction
}