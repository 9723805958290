import {AbstractEvent} from "../../AbstractEvent";
import {ApiProvider} from "../../../api/providers/providers";
import {ApiEvents} from "../events";

/**
 * This event is dispatched, when the provider changes
 */
class ProviderChangeEvent extends AbstractEvent<ApiProvider> {
    static NAME = ApiEvents.onProviderChange;
}

export default ProviderChangeEvent;