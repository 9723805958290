/**
 * Strips a string
 * @param html
 */
export const stripHtml = (html: string): string => {
    return html
        .replace(/\s{2,}/gm, "")
        // .replace(/[\t ]+\</g, "<") // remove whitespace (space and tabs) before tags
        .replace(/\>[\t ]+\</g, "><") // remove whitespace between tags
        // .replace(/\>[\t ]+$/g, ">") // remove whitespace after tags
        .replace(/(\r\n|\n|\r)/gm,"") // remove line breaks
        .replace(/[\t]+/gm,"") // remove line breaks
        .replace(/&nbsp;/gi,"\xa0");
};
