import {GeneralComponent} from "../components/GeneralComponent";

abstract class AbstractSubscriber extends GeneralComponent<any> {

    // is fired when the provider is initialized
    public register() {
    }

    // is fired when the provider is ejected
    public unregister() {
    }

}

export default AbstractSubscriber;