import {define, withLocale, withPrefix, withScope} from "../helpers/TranslationHelpers";

export default withScope(withLocale([

    ...withPrefix('general', [
        define('chars-count', "%s von %s Zeichen", "Zähler für die Zeichenanzahl, wenn der Nutzer eine Nachricht eingibt"),
        define('max-chars-reached', 'Bitte senden Sie eine weitere Nachricht.', "Hinweis für den Nutzer, wenn das Zeichenlimit erreicht ist"),
        define('default-header', "Allianz Live-Chat", "Headline, die angezeigt wird, wenn weder der Agentenname noch ein Chatbot angezeigt wird"),
    ]),

    ...withPrefix('loading', [
        define('chat', 'Die Verbindung wird hergestellt ...', "Text beim Übergang zwischen UA und Chat Window"),
        define('agent', 'Sie werden verbunden ...', "Text bei der Übergabe zum Service Agenten")
    ]),

    ...withPrefix('in-chat', [
        define('input-box.placeholder', 'Bitte geben Sie Ihre Frage ein.', "Standard Platzhalter im Textfeld")
    ]),

    ...withPrefix('wordings', [
        define('skip', 'Überspringen', "Frage überspringen in Post und Prechat Survey"),
        define('invalid-answer', 'Ungültige Antwort', "Hinweis bei einer ungültigen Antwort"),
        define('try-again', 'Bitte versuchen Sie es erneut.', "Aufforderung nach einer ungültigen Antwort, die Eingabe zu wiederholen"),
        define('yes', "Ja", 'Antwort-Button "Ja" bei einer Chat Abfrage'),
        define('no', "Nein", 'Antwort-Button "Nein" bei einer Chat Abfrage'),
        define('submit', "Abschicken", 'zentraler Button ganz unten, um die Nachricht des Nutzers zu verschicken'),
        define('back', "Zurück", 'Zurück-Button'),
        define('misstyped', 'Anderes Anliegen? Hier geht es <span class="highlight">zurück</span>.', 'Wording in der Post Chat Survey'),
        define('accept', 'Annehmen', 'Buttong "Annehmen" bei Datenschutzerklärung'),
        define('decline', 'Ablehnen', 'Buttong "Ablehnen" bei Datenschutzerklärung'),
        define('continue', 'Weiter', 'Weiter bei survey overlays')
    ]),

    ...withPrefix('predefined', [

        define('after-chat-closed', 'Die Verbindung wurde getrennt. Sie können das Chat-Fenster nun schließen.', 'Message nach Beedigung des Chats'),
        define('close-window', 'Fenster jetzt schließen', 'Schließen CTA Button'),
        define('close-confirmation', 'Möchten Sie die Unterhaltung wirklich beenden?', 'Abfrage nach dem der Nutzer auf d"X" geklickt hat'),

        ...withPrefix('star-rating', [
            define('bad', "Vielen Dank für Ihre Bewertung. Wir werden uns stets bemühen, unseren Service zu optimieren.", "Nachricht nach der Vergabe einer schlechten Bewertung"),
            define('good', "Vielen Dank für die gute Bewertung, wir wissen Ihre Meinung zu schätzen.", "Nachricht nach der Vergabe einer guten Bewertung")
        ])

    ]),

    ...withPrefix('notification', [
        define('offline-state', 'Es besteht keine Verbindung zum Internet. Wir versuchen, die Verbindung wiederherzustellen.', "Das Chat Window überprüft die Verbindung des Browsers. Dieser Hinweis wird angezeigt, wenn keine Verbindung erkannt wurde. (Wenn die Verbindung wiederhergestellt wird, verbindet sich auch der Chat automatisch)"),
        define('user-away', 'Dieser Chat schließt sich automatisch innerhalb der nächsten 30 Sekunden.')
    ]),

    ...withPrefix('printing', [

        define('window.title', 'Allianz Chat', "Name des Fensters im Print Dialog"),

        define('header.name', 'Name', "Überschrift der Namensspalte im Chat Ausdruck (Anbieter unabhänig)"),
        define('header.timestamp', 'Uhrzeit', "Überschrift der Uhrzeitspalte im Chat Ausdruck (Anbieter unabhänig)"),
        define('header.message', 'Nachricht', "Überschrift der Nachrichtspalte im Chat Ausdruck (Anbieter unabhänig)"),

        define('message.timestamp', '%s:%s Uhr', "Uhrzeit im Chat Ausdruck (%s ist ein Platzhalter für die konkreten Daten, Anbieter unabhängig)"),
        define('headline', 'Allianz-Chat - Ihre Konversation am %s.%s.%s', 'Headline des ausgedruckten Chat Verlaufs (%s sind Platzhalter für die Werte von Agenten Name (Allianz Chat) und das Datum (Anbieter unabhängig)'),

        ...withPrefix('feature', [
            ...withPrefix('print', [
                define('label', 'Drucken', 'Drucken CTA Button'),
                define('user-message', 'Ich möchte den Chat-Verlauf drucken.', 'Nachricht des Users, wenn er den Drucken CTA Button geklickt hat'),
                define('on-click', 'Der Chat-Verlauf wird in wenigen Sekunden in einem neuen Fenster geöffnet.', 'Antwort des Agent, wenn der Chat-Verlauf gedruckt wird')
            ])
        ]),

    ]),

    ...withPrefix('sidewindow', [
        define('re-open', 'Inhalt\xa0erneut\xa0anzeigen', 'Sidewindow: Inhalt erneut anzeigen'),
    ]),

], 'de'), 'global')
