import JSX from "../../../../../jsx"

import {ComponentInterface, GeneralComponent} from "../../../../../components/GeneralComponent";
import {RatingsAction} from "../../../../../events/interaction/types";
import {MessageType, ProductIcon} from "../../../../../types";
import GeneralMessage from "../../../../../components/General/Messages/GeneralMessage";
import Environment from "../../../../../helpers/Environment";
import AgentMessageService from "../../../../../service/AgentMessageService";

export interface RatingsProps {
    text: string
    ratingsActions: RatingsAction[]
}

class RatingsComponent extends GeneralComponent<RatingsProps> implements ComponentInterface {

    private parentElement: HTMLElement;

    public render(parentElement: HTMLElement | Element): void {
        this.generateHTML();
        parentElement.appendChild(this.domElement);

        if (this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage) {
            AgentMessageService.scrollToLatestFirstMessage();
        }
    }

    public generateHTML() {
        const actions = this.props.ratingsActions;
        this.domElement =
            <GeneralMessage
                type={MessageType.Received}
                icon={ProductIcon.Bot}
            >
                <div class={`${Environment.isIE11() ? "cw__rating__content float" : "cw__rating__content flex"}`}>
                    <div class={"cw__rating__message"}>
                        <p class="c-copy cw__message__content-content__text">
                            {
                                this.props.text
                            }
                        </p>
                    </div>
                    <div class={"cw__rating__wrapper"}>
                        {
                            actions.map((action) => {
                                return this.generateRating(action);
                            })
                        }
                    </div>
                </div>
            </GeneralMessage>
    }

    private generateRating(ratingAction: RatingsAction) {

        const rating = (
            <span class={"cw__rating_item cw__message__avatar"}>
                {
                    <div class={"cw__rating_item_icon cw__custom-icon"}
                        innerHTML={ratingAction.icon}/>
                }
            </span>
        );

        rating.addEventListener('click', () => {
            this.chatWindow.api.currentProvider.allowScrollToLatestFirstMessage = true;
            ratingAction.onClick();
            if (ratingAction.hideComponentOnClick) {
                this.domElement.remove();
            }
        });

        return rating;
    }

}

export default RatingsComponent;
