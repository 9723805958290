import InbentaSubscriber from './InbentaSubscriber';
import RatingsEvent from "../../../../events/interaction/events/RatingsEvent";
import RatingsComponent from "../components/Interaction/Ratings";
import {Messaging} from "../../../../events/messaging";
import RenderRelatedContentEvent from "../events/interaction/events/RenderRelatedContentEvent";
import RelatedContent from "../components/Messages/RelatedContent";

class RenderRelatedContentSubscriber extends InbentaSubscriber {

    protected onConstructor() {

        this.registerEventHandler(RenderRelatedContentEvent.NAME, this.handleRenderRelatedContent.bind(this));
    }

    handleRenderRelatedContent(event: RenderRelatedContentEvent) {
        const relatedContentComponent = new RelatedContent(this.inbenta.chatWindow, {...event.data});
        this.dispatchEvent(Messaging.Methods.renderComponentAsMessage(relatedContentComponent));
    }

}

export default RenderRelatedContentSubscriber;
