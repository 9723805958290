import {define, withLocale, withPrefix, withScope} from "../helpers/TranslationHelpers";

export default withScope(withLocale([

    ...withPrefix('survey', [
        ...withPrefix('offline', [
            define('header', `
Leider ist zur Zeit kein Agent verfügbar.
Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder rufen uns an unter 0800 / 452 01 04.

Vielen Dank.
            `, 'Nachricht, wenn kein Agent Online ist (z.B. außerhalb der Service-Zeiten). Abfrage vor der Pre Chat Survey (Anbieter abhängig)')
        ])
    ])

], 'de'), 'liveperson-survey')
