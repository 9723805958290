export namespace InteractionEvents {

    export const onPreventInteraction = "events/interaction/prevent";
    export const onRestoreInteraction = "events/interaction/restore";

    export const onAgentChange = "events/interaction/agent-change";
    export const onNotification = "events/interaction/notification";
    export const onStarRating = "events/interaction/star-rating";
    export const onSingleChoice = "events/interaction/single-choice";
    export const onRatings = "events/interaction/ratings";
    export const onMultipleChoice = "events/interaction/multiple-choice";
    export const onTextInput = "events/interaction/text-input";
    export const onTextInputReset = "events/interaction/text-input-reset";
    export const onEnableDateMask = "events/interaction/enable-date-mask";
    export const onDisableInputMask = "events/interaction/disable-input-mask";

    export const onShowInteraction = "events/show-interaction";
    export const onHideInteraction = "events/hide-interaction";
    export const onHideChoiceInteraction = "events/hide-choice-interaction";

    export const onSetPlaceholder = "events/set-placeholder";

}
