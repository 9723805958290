import {GeneralComponent} from "../components/GeneralComponent";
import ErrorEvent from "../events/error-handling/events/ErrorEvent";
import ResetMaxRetriesEvent from "../events/error-handling/events/ResetMaxRetriesEvent";
import {ErrorEventData, ErrorLevel} from "../events/error-handling/types";
import {ErrorHandling} from "../events/error-handling";

/**
 * This service is used to handle every kind of error which could happen in a provider
 *
 * @example
 *
 *  let coolBoolean = false;
 *
 *  const awesomeFunction = () => {
 *      if(coolBoolean === true) {
 *          console.log('successfully function')
 *          this.dispatchEvent(new ResetMaxRetriesEvent(awesomeFunction.name))
 *      } else {
 *          console.log('cool boolean must be true, lets try again')
 *          this.dispatchEvent(new ErrorEvent({
 *              level: 'minor',
 *              message: 'coolBoolean should be true',
 *              identifier: awesomeFunction.name,
 *              fixable: () => {
 *                  // sets coolBoolean to true
 *                  coolBoolean = true
 *              },
 *              maxAttempts: 5,
 *              timeBetweenAttempts: 150
 *          }))
 *      }
 *  }
 *
 */
class ProviderErrorHandlingService extends GeneralComponent<any> {

    /**
     * The maxRetriesStore holds the maximum retries left for each function identifier
     * @example
     *
     *      maxRetriesStore = {
     *          'liveperson/sdk/requestChat': 23,
     *          'liveperson/sdk/requestEngagement': 9
     *      }
     */
    private maxRetriesStore: any;

    public onConstructor() {
        // resets the max retries store
        this.maxRetriesStore = {};

        // registers the event handlers
        this.registerEventHandler(ErrorHandling.Events.onError, this.handleProviderError.bind(this));
        this.registerEventHandler(ErrorHandling.Events.onResetMaxRetries, this.handleMaxRetriesReset.bind(this));
    }

    /**
     * This handler handles the ResetMaxRetriesEvent
     * It should be called when the function ran through successfully.
     *
     * This makes sure the max retries are set to it's default
     * @param event
     */
    private handleMaxRetriesReset(event: ResetMaxRetriesEvent) {
        if (Object.keys(this.maxRetriesStore).indexOf(event.data) !== -1) {
            delete this.maxRetriesStore[event.data];
        }
    }

    /**
     * Handles the Provider Error event
     * @param providerError
     */
    private async handleProviderError(providerError: ErrorEvent) {
        await this.retryFixable(providerError.data);
    }

    /**
     * Returns the max retries for the specific error / function identifier
     * @param providerError
     */
    private getMaxRetries(providerError: ErrorEventData): number {
        if (Object.keys(this.maxRetriesStore).indexOf(providerError.identifier) !== -1) {
            return parseInt(this.maxRetriesStore[providerError.identifier]);
        } else {
            this.maxRetriesStore[providerError.identifier] = providerError.maxAttempts || 25;
        }
        return parseInt(this.maxRetriesStore[providerError.identifier]);
    }

    /**
     * Retries the fixable reference, passed to the ErrorEvent
     * This reference should be a function, which could fix the error which just appeared
     * @param providerError
     */
    private async retryFixable(providerError: ErrorEventData): Promise<boolean> {

        // reads the max retries left from the retries store
        const maxRetries = this.getMaxRetries(providerError);

        // check if there are any retries left
        if (maxRetries > 0) {

            this.maxRetriesStore[providerError.identifier]--;

            // check if the fixable reference is type of a function
            if (providerError.fixable && typeof providerError.fixable === 'function') {
                try {

                    // await the time between the attempts
                    await setTimeout(() => {

                        // fix it
                        providerError.fixable(providerError.data);

                    }, providerError.timeBetweenAttempts);
                    return true;
                } catch (e) {
                    return false;
                }
            }

        } else if (providerError.notFixable && typeof providerError.notFixable === 'function') {
            try {
                providerError.notFixable();
            } catch (e) {

            }
        } else {
            providerError.level = ErrorLevel.Critical;
            this.dispatchEvent(ErrorHandling.Methods.displayError(providerError))
        }
    }

}

export default ProviderErrorHandlingService;