import {AbstractEvent} from "../../AbstractEvent";
import ChatWindow from "../../../ChatWindow";
import {ErrorEventData} from "../types";
import {ErrorHandlingEvents} from "../events";


class DisplayErrorEvent extends AbstractEvent<ErrorEventData> {

    static NAME = ErrorHandlingEvents.onDisplayError;

    public transform(chatWindow: ChatWindow) {
        this.data.message = chatWindow.api.chatWindow.translation.translate(this.data.message);
    }

}

export default DisplayErrorEvent;