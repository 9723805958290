import ChatWindow from "../ChatWindow";
import {AbstractEvent} from "../events/AbstractEvent";
import Environment from "../helpers/Environment";
import {InteractionEvents} from '../events/interaction/events';
import {ApiMethods} from "../events/api/methods";

export interface ComponentInterface {
    render(parentElement: HTMLElement | Element): void

    domElement?: HTMLElement
}

type EventCallbackSignature = (event: any) => void;

export abstract class GeneralComponent<T> {

    // the component's dom element
    // not every component must have a domElement
    // for example the API is also a Component
    domElement: HTMLElement;

    // the event name, which is used to dispatch the events
    static chat_window_event_name = 'chat_window:global-event';

    // holds the chat window instance
    public chatWindow: ChatWindow;

    // the components props
    protected props: T;

    // a key function array of event handlers
    // example:
    //      [
    //         'window/window-initialized': [() => {}]
    //       ]
    protected eventHandlers: any;

    // holds the cancelled event listeners to listen for
    protected cancelledEventHandlers: string[];

    /**
     * The GeneralComponent constructor
     * @param chatWindow
     * @param props
     */
    constructor(chatWindow: ChatWindow, props: T = null) {

        // execute the beforeConstructor hook
        this.beforeConstructor();

        this.chatWindow = chatWindow;
        this.props = props;
        this.eventHandlers = {};
        this.cancelledEventHandlers = [];

        // execute the onConstructor hook
        this.onConstructor();

        // initializes the event listeners
        this.initGlobalEventListener();

        // execute the afterConstructor hook
        this.afterConstructor();
    }

    /**
     * Checks if the given component implements the ComponentInterface
     * @param component
     * @private
     */
    static _implementsComponentInterface(component: any): component is ComponentInterface {
        return true;
    }

    /**
     * Initializes the global event listener
     * @private
     */
    private initGlobalEventListener() {

        // first check if the current instance of this object is instance of the ComponentInterface
        // this is needed since we are calling child.handleEvent
        if (GeneralComponent._implementsComponentInterface(this)) {
            this.chatWindow.domElement.addEventListener(GeneralComponent.chat_window_event_name, (customEvent: CustomEvent) => {

                // this is the abstract event
                const dispatchedWindowEvent = customEvent.detail.windowEvent as AbstractEvent<any>;
                //@ts-ignore
                const eventName = dispatchedWindowEvent.constructor.NAME;

                // check if a event handler exists for this event
                if (this.eventHandlers[eventName] && Array.isArray(this.eventHandlers[eventName])) {

                    // check the cancelled state
                    if ((dispatchedWindowEvent.cancelled && this.cancelledEventHandlers.indexOf(eventName) !== -1) || (!dispatchedWindowEvent.cancelled)) {

                        // execute each handler
                        for (const eventHandler of this.eventHandlers[eventName]) {
                            if (typeof eventHandler === 'function') {
                                eventHandler(dispatchedWindowEvent);
                            }
                        }
                    }
                }

            });
        }

    }

    /**
     * Dispatches the event
     * @param event
     * @param onDispatch
     */
    public dispatchEvent(event: any, onDispatch?: () => void) {

        event = event as AbstractEvent<any>;

        // first check if the given event is instance of a abstract event
        if (AbstractEvent._isEvent(event)) {

            if (Environment.isDevelopment()) {
                try {
                    throw new Error();
                } catch (e) {
                    console.debug(event.constructor.NAME, event.data)
                }
            }

            // executes the transform hook
            event.transform(this.chatWindow);

            if (onDispatch && typeof onDispatch === 'function') {
                event.onDispatch = onDispatch;
                event.onDispatchExecuted = false;
            }

            if (event.onDispatch && typeof event.onDispatch === 'function' && !event.onDispatchExecuted) {
                event.onDispatch();
                event.onDispatchExecuted = true;
            }

            // build the custom event
            const customEvent = new CustomEvent(GeneralComponent.chat_window_event_name, {
                detail: {
                    windowEvent: event
                }
            });

            // dispatch it on the window component / dom element
            this.chatWindow.domElement.dispatchEvent(customEvent);

        }
    }

    /**
     * Registers a event handler
     * @param eventName
     * @param callback
     * @param allowCancelled
     */
    public registerEventHandler(eventName: string, callback: EventCallbackSignature | EventCallbackSignature[], allowCancelled = false) {

        if (!this.eventHandlers[eventName]) {
            this.eventHandlers[eventName] = [];
        }

        if (Array.isArray(callback)) {
            for (const currentCallback of callback) {
                if (Array.isArray(this.eventHandlers[eventName])) {
                    this.eventHandlers[eventName].push(currentCallback);
                }
            }
        } else {
            if (Array.isArray(this.eventHandlers[eventName])) {
                this.eventHandlers[eventName].push(callback);
            }
        }

        if (allowCancelled && this.cancelledEventHandlers.indexOf(eventName) === -1) {
            this.cancelledEventHandlers.push(eventName);
        }

    }

    /**
     * Unloads a event handler
     * @param eventName
     */
    public unloadEventHandler(eventName: string) {
        if (this.eventHandlers[eventName]) {
            delete this.eventHandlers[eventName];
        }
    }

    // this hook is called before the constructor gets initialized
    protected beforeConstructor() {
    }

    // this hook is called when the major variables have been set
    protected onConstructor() {
    }

    // this hook is called after the constructor has been run
    protected afterConstructor() {
    }

}
