import AbstractSubscriber from './AbstractSubscriber';
import RenderBasicMessageEvent from '../events/messaging/events/RenderBasicMessageEvent';
import {Messaging} from '../events/messaging';
import {ChatState} from '../api/types';

/**
 * This subscriber collects all the message renderings and passes them to the printing service
 */
class MessagePrintingSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(Messaging.Events.onRenderBasicMessage, this.handleMessageRenderingEvent.bind(this));
    }

    /**
     *
     * @param event
     */
    private handleMessageRenderingEvent(event: RenderBasicMessageEvent) {

        if(this.chatWindow.api.context.chatState.state === ChatState.Chatting) {
            this.chatWindow.api.printService.pushMessage({
                source: event.data.type,
                message: event.data.message,
                timestamp: event.data.timestamp
            });
        }

    }

}

export default MessagePrintingSubscriber;
