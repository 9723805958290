export interface ChatStateContext {
    state: ChatState,
    surveyType?: any
}

export enum ChatState {
    Initializing,
    Chatting,
    Survey,
    Finished
}
