import {GeneralComponent} from "../components/GeneralComponent";

import Environment from "../helpers/Environment";
import GlobalTranslations from '../translations/global';
import InbentaTranslations from '../translations/inbenta';
import LivepersonTranslations from '../translations/liveperson';
import LivepersonSurveyTranslations from '../translations/liveperson-survey';

export interface DefinedTranslation {
    translationKey: string,
    translationValue: string
    description: string,
    locale?: string,
    scope?: string
}

export class TranslationService extends GeneralComponent<any> {

    public static SCOPE_GLOBAL = 'global';
    private translationStorage: DefinedTranslation[] = [
        ...GlobalTranslations,
    ];

    protected onConstructor() {
        this.translationStorage = [
            ...GlobalTranslations,
            ...InbentaTranslations,
            ...LivepersonTranslations,
            ...LivepersonSurveyTranslations
        ];
    }

    /**
     * Translates the given string
     * @param translationKey
     * @param args
     */
    public translate(translationKey: string, ...args): string {

        const locale = 'de';
        const allowedScopes = [TranslationService.SCOPE_GLOBAL, this.chatWindow.api.currentProvider.name];

        // using for loop for performance increase
        // https://github.com/dg92/Performance-Analysis-JS/blob/master/formulas.js

        let foundTranslations: DefinedTranslation[] = [];
        for (let i = 0; i < this.translationStorage.length; i++) {

            const currentTranslation = this.translationStorage[i];
            if (currentTranslation.translationKey === translationKey && currentTranslation.locale === locale && allowedScopes.indexOf(currentTranslation.scope) !== -1) {
                foundTranslations.push(currentTranslation);
            }

        }

        if (foundTranslations.length) {
            return TranslationService.format(foundTranslations[0].translationValue, ...args)
        } else {
            return translationKey;
        }

    }

    /**
     * Generates a csv dump of the translation storage
     */
    public toCsv() {
        if(Environment.isDevelopment()) {

            const array = typeof this.translationStorage !== 'object' ? JSON.parse(this.translationStorage) : this.translationStorage;
            let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

            const csvContents = array.reduce((str, next) => {
                str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
                return str;
            }, str);

            const link = this.createDownloadableCsvFile('chatwindow-translations.csv', csvContents);

            link.click();

        }
    }

    private createDownloadableCsvFile(fileName, content) {
        let link = document.createElement("a");
        link.download = fileName;
        link.href = `data:application/octet-stream,${encodeURI(content)}`;
        return link;
    }

    /**
     * Formats the given string in a php like syntax
     * @param fmt
     * @param args
     */
    private static format(fmt, ...args): string {
        return fmt
            .split("%s")
            .reduce((aggregate, chunk, i) =>
                aggregate + chunk + (args[i] || ""), "");
    }

}
