import AbstractHandler from "./AbstractHandler";
import {DirectCall} from "../../definition/directCall";
import {Answer} from "../../definition/answerTypes";

class SurveyNoHandler extends AbstractHandler {

    public canHandle(answer: Answer) : boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return directCall.__startsWith(DirectCall.SurveyNo);
    }

    public handle(answer: Answer) {
        this.inbenta.allowBackOnClose = true;
    }

}

export default SurveyNoHandler;
