import ChatOpenSubscriber from './ChatOpenSubscriber';
import ChatRatingSubscriber from './ChatRatingSubscriber';
import ChatUsageSubscriber from './ChatUsageSubscriber';
import ConfigureTrackingSubscriber from './ConfigureTrackingSubscriber';

const TrackingSubscribers = [
    ConfigureTrackingSubscriber,
    ChatOpenSubscriber,
    ChatRatingSubscriber,
    ChatUsageSubscriber,
];

export default TrackingSubscribers
