import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import {FooterWrapper} from "../General/Content/FooterWrapper";
import {DirectCallContainer} from "../General/Content/DirectCallWrapper";
import {DirectCallSubmit} from "../General/Content/DirectCallSubmit";
import AnimationHelper from "../../helpers/AnimationHelper";
import {MultipleChoiceInteractionConfig, MultipleChoiceOption} from "../../events/interaction/types";
import {Interaction} from "../../events/interaction";
import MultipleChoiceEvent from "../../events/interaction/events/MultipleChoiceEvent";

class MultipleChoice extends GeneralComponent<any> implements ComponentInterface {

    private currentConfiguration: MultipleChoiceInteractionConfig;
    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.onMultipleChoice, this.handleShowDirectCallMultipleEvent.bind(this));
        this.registerEventHandler(Interaction.Events.onHideInteraction, this.onHide.bind(this));
        this.registerEventHandler(Interaction.Events.onHideChoiceInteraction, this.onHide.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.parentElement = parentElement as HTMLElement;
    }

    private generateContainer(configuration: MultipleChoiceInteractionConfig): HTMLElement {

        return (
            <FooterWrapper {...configuration}>
                <form onsubmit={this.handleFormSubmit.bind(this)}>
                    <DirectCallContainer ariaLabel={"Mehrfachauswal"}>
                        <div class="cw__direct-call direct-call__checkbox-container">
                            {configuration.options.map((option, index) => {

                                const identifier = `direct_call_multiple_${index}`;
                                const identifierLabel = `${identifier}-label`;

                                return (
                                    <div
                                        class={`cw__direct-call direct-call__call-container single`}>
                                        <div class="c-checkbox cw__direct-call direct-call__checkbox">
                                            <input type="checkbox" value={option.value}
                                                   displayValue={option.displayValue} id={identifier}
                                                   aria-labelledby={identifierLabel} class="c-checkbox__input"
                                                   checked={option.checked}/>
                                            <label for={identifier} class="c-checkbox__label c-icon c-icon--check">
                                        <span id={identifierLabel}>
                                            {option.displayValue}
                                        </span>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <DirectCallSubmit label={this.chatWindow.translation.translate("wordings.submit")}/>
                    </DirectCallContainer>
                </form>
            </FooterWrapper>
        )
    }

    private handleFormSubmit(event) {

        event.preventDefault();

        const checkboxes = [...event.target.querySelectorAll('input[type=checkbox]')];
        const selectedOptions: MultipleChoiceOption[] = checkboxes.map(checkbox => {
            return {
                checked: !!checkbox.checked,
                value: checkbox.getAttribute('value'),
                displayValue: checkbox.getAttribute('displayValue')
            }
        });

        if (this.currentConfiguration.onSubmit && typeof this.currentConfiguration.onSubmit === 'function') {
            this.currentConfiguration.onSubmit(selectedOptions);
        }

    }

    private handleShowDirectCallMultipleEvent(event: MultipleChoiceEvent) {

        this.chatWindow.messageTransforming.withDelay(() => {
            this.domElement = this.generateContainer(event.data);
            AnimationHelper.animateDirectCall(this.domElement);
            this.currentConfiguration = event.data;
            this.visible = true;
        });

    }

    private onHide() {
        this.visible = false;
        this.domElement = null;
    }

    set visible(visible: boolean) {
        if (visible && !this.parentElement.contains(this.domElement)) {
            this.parentElement.appendChild(this.domElement);
        } else if (!visible && this.parentElement.contains(this.domElement)) {
            this.domElement.remove();
        }
    }


}

export default MultipleChoice;
