/**
 * This file just exports all the subscribers in a simple array
 */

import ProviderChangeSubscriber from "./ProviderChangeSubscriber";
import BeforeUnloadSubscriber from "./BeforeUnloadSubscriber";
import MessageTransformingSubscriber from "./MessageTransformingSubscriber";
import FooterEventsSubscriber from "./FooterEventsSubscriber";
import DisplayErrorEventSubscriber from "./DisplayErrorEventSubscriber";
import ChatWindowCloseSubscriber from "./ChatWindowCloseSubscriber";
import OnlineStateSubscriber from "./OnlineStateSubscriber";
import MessagePrintingSubscriber from "./MessagePrintingSubscriber";
import ChatWindowMinimizeSubscriber from "./ChatWindowMinimizeSubscriber";
import ErrorReportingSubscriber from './ErrorReportingSubscriber';
import TrackingSubscribers from './tracking';

export default [
    ...TrackingSubscribers,
    ProviderChangeSubscriber,
    BeforeUnloadSubscriber,
    DisplayErrorEventSubscriber,
    ChatWindowCloseSubscriber,
    OnlineStateSubscriber,
    MessagePrintingSubscriber,
    ChatWindowMinimizeSubscriber,
    ErrorReportingSubscriber,
]
