export enum DirectCall {
    SystemWelcome = 'sys-welcome',
    ChatAccepted = 'ja_gerne',
    SurveyQuestion = 'survey_frage',
    SurveyFreeText = 'survey-free-text',
    SurveyStars = 'survey_stars',
    SurveyNo = 'survey_nein',
    SurveyYes = 'survey_ja',
    SurveyComplete = 'survey_abschluss',
    ContactAgency1 = 'kontakt_agentur1',
    ContactAgency2 = 'kontakt_agentur2',
    ContactAgency3 = 'kontakt_agentur3',
    ContactAgency4 = 'kontakt_agentur4',
    ContactAgency5 = 'kontakt_agentur5',
    ContactHotline1 = 'kontakt_hotline1',
    ContactHotline2 = 'kontakt_hotline2',
    ContactHotline3 = 'kontakt_hotline3',
    ContactHotline4 = 'kontakt_hotline4',
    ContactHotline5 = 'kontakt_hotline5',
    ContactLivechat1 = 'kontakt_livechat1',
    ContactLivechat2 = 'kontakt_livechat2',
    ContactLivechat3 = 'kontakt_livechat3',
    ContactLivechat4 = 'kontakt_livechat4',
    ContactLivechat5 = 'kontakt_livechat5',
    ContactPhvLiveChat = 'kontakt_phv_live-chat'
}