import {GeneralComponent} from "../components/GeneralComponent";

/**
 * This class should be implemented when using a subscriber
 * It fully depends on the GeneralComponent class but we may change things in the future
 *
 * Make sure to .bind(this) the function reference you are passing, when registering your event handler
 *
 * @example
 *
 class ProviderErrorSubscriber extends AbstractSubscriber {

            public onConstructor() {
                this.registerEventHandler(ErrorEvent.NAME, this.handleProviderError.bind(this))
            }

            private async handleProviderError(event: ErrorEvent) {
                await this.chatWindow.providerErrorHandling.handleProviderError(event.data);
            }

        }

 */
abstract class AbstractSubscriber extends GeneralComponent<any> {

}

export default AbstractSubscriber;