export enum ErrorLevel {
    Minor = "minor",
    Major = "major",
    Critical = "critical",
}

export interface ErrorEventData {
    level: ErrorLevel,
    message: string
    identifier: string
    fixable?: (data?: any) => void
    notFixable?: () => void
    maxAttempts?: number
    timeBetweenAttempts?: number
    loading?: boolean,
    data?: any
}