import JSX from "../../jsx";
import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import SetTitleEvent from "../../events/window/events/SetTitleEvent";
import SetIconEvent from "../../events/window/events/SetIconEvent";
import {WindowMethods} from "../../events/window/methods";
import {Window} from "../../events/window";
import MinimizeEvent from "../../events/window/events/MinimizeEvent";
import StartupHelper from "../../helpers/StartupHelper";
import DirectHeaderCloseEvent from "../../events/window/events/DirectHeaderCloseEvent";

class Header extends GeneralComponent<any> implements ComponentInterface {

    private minimized: boolean = StartupHelper.minimized;

    private permanent: boolean = this.chatWindow.api.currentProvider.getProviderChatWindowConfig().isHeaderPermanent;

    private directHeaderClose: boolean = false;

    protected onConstructor() {
        this.registerEventHandler(Window.Events.onSetTitle, this.handleSetWindowTitleEvent.bind(this));
        this.registerEventHandler(Window.Events.onSetIcon, this.handleHeaderIconEvent.bind(this));
        this.registerEventHandler(Window.Events.onMinimize, this.handleMinimizeEvent.bind(this));
        this.registerEventHandler(Window.Events.onMinimize, this.handleMinimizeEvent.bind(this));
        this.registerEventHandler(Window.Events.directHeaderClose, this.handleDirectHeaderCloseEvent.bind(this));
    }

    render(parentElement: HTMLElement | Element): void {
        this.generateHeader();
        parentElement.appendChild(this.domElement);
    }

    private generateHeader() {
        this.domElement = (
            <div class={(this.permanent === true ? "cw__header cw__header--permanent": "cw__header") +( this.minimized ? ' cw__header-minimized' : '')}>
                <div class="cw__header-title" onclick={this.handleTitleClick.bind(this)}>
                    <h3/>
                </div>
                <div class="cw__header-actions" aria-label="Aktionen">
                    <button aria-label="Fenster minimieren" tabindex="4" onclick={this.handleMinimizeClick.bind(this)} class={`c-icon c-icon--${this.minimized ? 'plus' : 'minus'} cw__header-minimize-button cw__header-spacer`}/>
                    <button aria-label="Fenster schließen" tabindex="5" onclick={this.handleCloseButtonClick.bind(this)} class="c-icon c-icon--close cw__header-close-button"/>
                </div>
            </div>
        )
    }

    private handleDirectHeaderCloseEvent(event: DirectHeaderCloseEvent) {
        const directClose = event.data;
        this.directHeaderClose = directClose;

        if (directClose === true) {
            (this.domElement.querySelector('.cw__header-title h3') as HTMLElement).style.display = "none";
        } else {
            (this.domElement.querySelector('.cw__header-title h3') as HTMLElement).style.display = "block";
        }
    }

    private handleCloseButtonClick() {

        if (this.directHeaderClose || this.chatWindow.api.currentProvider.directClose) {
            this.chatWindow.api.currentProvider.directClose = true;
            this.dispatchEvent(WindowMethods.close());
        }

        const executeCloseRequest = () => {
            const currentDialog = this.chatWindow.domElement.querySelector('.cw__footer__wrapper.cw__footer--display-top');
            if(!currentDialog) {
                return this.dispatchEvent(WindowMethods.closeRequest());
            }

            if (!this.permanent) {
                if(currentDialog.querySelector('p.c-copy') && (currentDialog.querySelector('p.c-copy') as HTMLElement).innerText === this.chatWindow.translation.translate('predefined.close-confirmation')) {
                    return;
                }
            }

            this.dispatchEvent(WindowMethods.closeRequest());
        };

        if(this.minimized) {
            this.dispatchEvent(Window.Methods.unMinimizeWindow());
            setTimeout(() => {
                executeCloseRequest();
            }, 300);
        } else {
            executeCloseRequest();
        }

    }

    set title(title: string) {
        (this.domElement.querySelector('.cw__header-title h3') as HTMLElement).innerText = title;
    }

    private handleSetWindowTitleEvent(event: SetTitleEvent) {
        this.title = event.data || '';
    }

    private handleHeaderIconEvent(event: SetIconEvent) {
        /*const iconContainer = this.domElement.querySelector('.cw__header-icon');
        iconContainer.innerHTML = '';
        iconContainer.appendChild(<Icon icon={event.data} />);*/
    }

    private handleTitleClick() {
        if(this.minimized) {
            this.handleMinimizeClick();
        }
    }

    private handleMinimizeClick() {

        const minimizeIcon = this.domElement.querySelector('.cw__header-minimize-button');

        if(this.minimized) {
            this.dispatchEvent(Window.Methods.unMinimizeWindow());
            minimizeIcon.setAttribute('aria-label', 'Fenster Minimieren')
            this.domElement.classList.remove('cw__header-minimized')
        } else {
            this.dispatchEvent(Window.Methods.minimizeWindow());
            minimizeIcon.setAttribute('aria-label', 'Fenster Maximieren')
            this.domElement.classList.add('cw__header-minimized')
        }
    }

    private handleMinimizeEvent(event: MinimizeEvent) {

        this.minimized = event.data;

        const minimizeIcon = this.domElement.querySelector('.cw__header-minimize-button');
        if(event.data) {
            minimizeIcon.classList.remove('c-icon--minus');
            minimizeIcon.classList.add('c-icon--plus');
        } else {
            minimizeIcon.classList.remove('c-icon--plus');
            minimizeIcon.classList.add('c-icon--minus');
        }
    }

}

export default Header;
