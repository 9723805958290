import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import StartupHelper from "../../helpers/StartupHelper";
import {Window} from "../../events/window";
import MinimizeEvent from "../../events/window/events/MinimizeEvent";
import RenderBasicMessageEvent from "../../events/messaging/events/RenderBasicMessageEvent";
import {BasicMessageType} from "../../types";
import {Messaging} from "../../events/messaging";

class UnreadMessages extends GeneralComponent<any> implements ComponentInterface {

    private isMinimized: boolean = StartupHelper.minimized;
    private _unreadMessagesCount: number = 0;
    private initialized: boolean = false;

    protected onConstructor() {
        this.registerEventHandler(Window.Events.onMinimize, this.handleMinimizeEvent.bind(this));
        this.registerEventHandler(Messaging.Events.onRenderBasicMessage, this.handleMessageRenderingEvent.bind(this));
    }

    render(parentElement: HTMLElement | Element): void {
        this.domElement = (
            <div class="cw__unread-messages unread-messages__container" style="display: none">
                <div class="cw__unread-messages unread-messages__count">{this.unreadMessagesCount}</div>
            </div>
        );
        setTimeout(() => {
            this.initialized = true;
        }, 2000);
        this.unreadMessagesCount = 0;
        parentElement.appendChild(this.domElement);
    }

    set unreadMessagesCount(count: number) {

        const counter = this.domElement.querySelector('.cw__unread-messages.unread-messages__count') as HTMLElement;

        if(this.initialized) {
            this._unreadMessagesCount = count;
            counter.innerText = this.unreadMessagesCount.toString();
        }

        if(this.unreadMessagesCount > 0 && this.initialized){
            this.domElement.style.display = '';
        } else {
            this.domElement.style.display = 'none';
        }
    }

    get unreadMessagesCount(): number {
        return this._unreadMessagesCount;
    }

    private handleMinimizeEvent(event: MinimizeEvent) {
        this.isMinimized = event.data;
        if(!this.isMinimized) {
            this.unreadMessagesCount = 0;
        }
    }

    private handleMessageRenderingEvent(event: RenderBasicMessageEvent) {

        if(event.data.type !== BasicMessageType.Agent) {
            return;
        }

        if(this.isMinimized) {
            this.unreadMessagesCount = this.unreadMessagesCount+1;
        }

    }



}

export default UnreadMessages;
