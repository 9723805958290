import {InbentaConfiguration, LivePersonConfiguration, TestingConfiguration} from "../service/ConfigurationService";
import ChatWindow from "../ChatWindow";
import {ApiProvider} from '../api/providers/providers';
import {ApiKeyCompareAction, compareApiKeys, restartViaUA} from "./AemExtractor";

export type LaunchingConfiguration = LivePersonConfiguration | InbentaConfiguration | TestingConfiguration;

export const CONFIG_ATTR_KEY = 'customconfiguration';

export const EVENT_LAUNCH_FROM_CONFIGURATION = 'chat-window:launch:from-configuration';
export const EVENT_LAUNCH_FROM_ELEMENT = 'chat-window:launch:from-element';
export const EVENT_LAUNCHER_REINITIALIZE = 'chat-window:launcher:reinitialize';

export const EVENT_BEFORE_SHUTDOWN = 'chat-window:before-shutdown';
export const EVENT_ON_STARTUP = 'chat-window:on-startup';

/**
 * StartupHelper class is used to render the chat window
 */
class StartupHelper {

    public static _isOpenLocalStorageKey = 'cw__is-open';
    private static _isMinimized = 'cw__is-minimized';
    private static _currentConfigurationLocalStorageKey = 'cw__current-configuration';
    public static launchButtonIdentifier = 'data-chatwindow-init';

    public static _containerHeight = 490;

    static _init() {
        //StartupHelper.validateClientConfiguration();
        StartupHelper.initializeLaunchEventListeners();
        StartupHelper.findTaggedLaunchers();
        StartupHelper.checkOpenState();
        StartupHelper.listenForUserAssistantHeightChange();
    }

    public static findTaggedLaunchers() {
        document.querySelectorAll(`[${StartupHelper.launchButtonIdentifier}]`).forEach(element => {
            if (element.hasAttribute(CONFIG_ATTR_KEY)) {
                try {
                    element.addEventListener('click', () => {
                        const rawConfiguration = element.getAttribute(CONFIG_ATTR_KEY);
                        const parsedConfiguration = JSON.parse(rawConfiguration);
                        const launchFromConfigurationEvent = new CustomEvent(EVENT_LAUNCH_FROM_CONFIGURATION, {
                            detail: parsedConfiguration
                        });
                        document.dispatchEvent(launchFromConfigurationEvent);
                    });
                } catch (e) {
                    console.warn('could not parse chatwindow configuration');
                }
            }
        });
    }

    public static initializeLaunchEventListeners() {

        // add the event listeners
        document.addEventListener(EVENT_LAUNCH_FROM_CONFIGURATION, StartupHelper.handleLaunchFromConfiguration);
        document.addEventListener(EVENT_LAUNCH_FROM_ELEMENT, StartupHelper.handleLaunchFromElement);
        document.addEventListener(EVENT_LAUNCHER_REINITIALIZE, StartupHelper.handleReInitializeEvent);

    };

    // Method called when page is loaded (it interacts with local storage with the open() and currentConfiguration() getters and setters)
    public static checkOpenState() {
        if (StartupHelper.open && StartupHelper.currentConfiguration) {
            const currentDate = Math.floor(Date.now() / 1000);
            const currentHeartbeat = StartupHelper.currentConfiguration.heartbeat || 0;
            const currentProvider = StartupHelper.currentConfiguration.provider;

            switch (currentProvider) {
                case ApiProvider.Inbenta:

                    const action = compareApiKeys(StartupHelper.currentConfiguration);
                    switch (action) {
                        case ApiKeyCompareAction.Restart:
                            restartViaUA();
                            break;
                        case ApiKeyCompareAction.Continue:
                        case ApiKeyCompareAction.Close:
                            // check if heartbeat is set and last interaction was 5 minutes ago
                            if (!currentHeartbeat || (currentDate - currentHeartbeat) < 60 * 5) {
                                if (action === ApiKeyCompareAction.Continue) {
                                    StartupHelper.renderFromConfiguration({
                                        ...StartupHelper.currentConfiguration,
                                        isContinued: true
                                    });
                                } else {
                                    StartupHelper.renderFromConfiguration({
                                        ...StartupHelper.currentConfiguration,
                                        implicitShutdown: true
                                    });
                                }
                            } else {
                                StartupHelper.open = false;
                                StartupHelper.currentConfiguration = null;
                            }
                            break;
                    }

                    break;
                case ApiProvider.LivePerson:
                    // check if the window was closed lass than 10 seconds ago
                    if (!currentHeartbeat || (currentDate - currentHeartbeat) < 20) {
                        StartupHelper.renderFromConfiguration(StartupHelper.currentConfiguration);
                    } else {
                        StartupHelper.open = false;
                        StartupHelper.currentConfiguration = null;
                    }
                    break;
                default:
                    console.warn('not starting chat since the current provider is not one of [inbenta, liveperson, contentGuru]')
                    StartupHelper.open = false;
                    StartupHelper.currentConfiguration = null;
                    break;
            }

        } else {
            StartupHelper.open = false;
            StartupHelper.currentConfiguration = null;
        }
    }

    public static handleLaunchFromConfiguration(event: CustomEvent) {
        if (!StartupHelper.open) {
            const domElement = event.detail as LaunchingConfiguration;
            StartupHelper.renderFromConfiguration(domElement);
        }
    }

    private static handleLaunchFromElement(event: CustomEvent) {
        if (!StartupHelper.open) {
            const configuration = event.detail as HTMLElement;
            StartupHelper.renderFromDomElement(configuration);
        }
    }

    P

    private static handleReInitializeEvent(event: CustomEvent) {

        // remove the event listeners to prevent initializing them twice
        document.removeEventListener(EVENT_LAUNCH_FROM_CONFIGURATION, StartupHelper.handleLaunchFromConfiguration);
        document.removeEventListener(EVENT_LAUNCH_FROM_ELEMENT, StartupHelper.handleLaunchFromElement);
        document.removeEventListener(EVENT_LAUNCHER_REINITIALIZE, StartupHelper.handleReInitializeEvent);

        // re initialize the startup listener
        StartupHelper._init();

    }

    /**
     * Renders the chat window as a child of the given element
     * The element must hold the provider configuration
     * @param element
     */
    private static renderFromDomElement(element: HTMLElement) {
        new ChatWindow(element).render();
    }

    /**
     * Creates the parent element using the given configuration and then renders the chat window
     * @param configuration
     */
    public static renderFromConfiguration(configuration: LaunchingConfiguration) {

        // create the dom element
        const domElement = document.createElement('div');
        domElement.setAttribute(CONFIG_ATTR_KEY, JSON.stringify(configuration));
        domElement.setAttribute('id', 'chat-window-config-holder');
        document.body.appendChild(domElement);
        // render it from the created element
        StartupHelper.renderFromDomElement(domElement);
    }

    public static initialize() {
        if (document.readyState !== 'loading') {
            StartupHelper._init();
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                StartupHelper._init();
            });
        }
    }

    public static emitBeforeShutdownEvent() {
        const beforeShutdownEvent = new CustomEvent(EVENT_BEFORE_SHUTDOWN);
        document.dispatchEvent(beforeShutdownEvent);
    }

    public static emitOnStartupEvent() {
        const onStartupEvent = new CustomEvent(EVENT_ON_STARTUP);
        console.log(onStartupEvent)
        document.dispatchEvent(onStartupEvent);
    }

    static get open(): boolean {
        const openValue = localStorage.getItem(StartupHelper._isOpenLocalStorageKey);
        if (openValue) {
            return openValue === 'true';
        }
        return false;
    }

    static set open(open: boolean) {
        if (!open) {
            const configHolder = document.getElementById('chat-window-config-holder');
            if (configHolder) {
                setTimeout(() => {
                    // wait animation to finish
                    configHolder.remove();
                }, 1000);
            }
        }
        localStorage.setItem(StartupHelper._isOpenLocalStorageKey, open.toString());
    }

    static get currentConfiguration(): LaunchingConfiguration | null {

        const configValue = localStorage.getItem(StartupHelper._currentConfigurationLocalStorageKey);
        if (configValue) {
            return JSON.parse(configValue)
        }

        return null;

    }

    static set currentConfiguration(configuration: LaunchingConfiguration) {
        if (configuration) {
            localStorage.setItem(StartupHelper._currentConfigurationLocalStorageKey, JSON.stringify(configuration));
        } else {
            localStorage.removeItem(StartupHelper._currentConfigurationLocalStorageKey);
        }
    }

    static set minimized(minimized: boolean) {
        localStorage.setItem(StartupHelper._isMinimized, minimized.toString());
    }

    static get minimized(): boolean {
        const value = localStorage.getItem(StartupHelper._isMinimized);
        if (!value) {
            return false;
        }

        return value === 'true';
    }

    static listenForUserAssistantHeightChange() {
        const launcher = document.querySelector('.c-user-assistant__avatar');
        if (launcher) {
            launcher.addEventListener('click', () => {
                setTimeout(() => {
                    const userAssistantWindow = document.querySelector('.c-user-assistant__stage.c-user-assistant__module');
                    if (userAssistantWindow) {
                        this._containerHeight = userAssistantWindow.clientHeight;
                    }
                }, 500)
            });
        }
    }

    static get containerHeight(): number {
        return StartupHelper._containerHeight;
    }

    /**
     * Prevents the use of outdated configuration files
     */
    static validateClientConfiguration() {

    }

}

export default StartupHelper;
