import AbstractHandler from "./AbstractHandler";
import {Answer} from "../../definition/answerTypes";

class ContactRlvOpenCalculatorHandler extends AbstractHandler {

    canHandle(answer: Answer): boolean {
        const directCall = answer.attributes['DIRECT_CALL'];
        return [
            'kontakt_as1',
            'kontakt_as2',
            'kontakt_as3',
            'kontakt_as4',
            'kontakt_as5'
        ].indexOf(directCall) !== -1;
    }

    handle(answer: Answer) {
        setTimeout(() => {
            window.location.href = "https://www.allianz.de/vorsorge/rechner/risikoleben/antrag/"
        }, 3000);
    }

}

export default ContactRlvOpenCalculatorHandler