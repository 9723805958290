import AbstractSubscriber from "./AbstractSubscriber";
import MinimizeEvent from "../events/window/events/MinimizeEvent";
import StartupHelper from "../helpers/StartupHelper";
import {Window} from "../events/window";

class ChatWindowMinimizeSubscriber extends AbstractSubscriber {

    protected onConstructor() {
        this.registerEventHandler(Window.Events.onMinimize, this.handleChatWindowMinimize.bind(this));
    }

    private handleChatWindowMinimize(event: MinimizeEvent) {
        StartupHelper.minimized = event.data;
    }

}

export default ChatWindowMinimizeSubscriber;