import {ApiProvider, getProvider} from './providers/providers';
import {GeneralComponent} from '../components/GeneralComponent';
import ChatWindow from '../ChatWindow';
import AbstractProvider from './providers/AbstractProvider';
import PersistenceService from '../service/PersistenceService';
import ShutdownService from './service/ShutdownService';
import PrintService from './service/PrintService';
import DependencyInjectionHelper from '../helpers/DependencyInjectionHelper';
import {Asset} from '../types';
import {WindowMethods} from '../events/window/methods';
import {Utility} from '../events/utility';
import {Interaction} from '../events/interaction';
import {ApiMethods} from '../events/api/methods';
import ContextService from './service/ContextService';
import {SingleChoiceAction} from '../events/interaction/types';
import {Window} from '../events/window';
import {ChatState} from './types';
import StartupHelper from '../helpers/StartupHelper';
import {EndType} from "./service/tracking/types";

class Api extends GeneralComponent<any> {

    // global based stuff
    declare chatWindow: ChatWindow;
    persistence: PersistenceService;
    currentProvider: AbstractProvider;
    shutdownService: ShutdownService;
    printService: PrintService;
    context: ContextService;

    public beforeCloseOpen: boolean = false;
    private beforeCloseTimeout: any = null;

    constructor(chatWindow: ChatWindow) {
        super(chatWindow, {});
        this.chatWindow = chatWindow;
        this.shutdownService = new ShutdownService(this.chatWindow);
        this.printService = new PrintService(this.chatWindow);
        this.context = new ContextService();
    }

    /**
     * Sets the current provider and initializes it
     * @param provider
     */
    changeProvider = async (provider: ApiProvider) => {

        if (this.currentProvider) {
            await this.currentProvider.eject();
        }

        await this.setProviderClass(provider);
        await this.initializeCurrentProvider();

        this.dispatchEvent(ApiMethods.setProvider(provider));

    };

    private setProviderClass(providerName?: ApiProvider) {
        providerName = providerName || this.chatWindow.configuration.configuration.provider;
        const providerBaseClass = getProvider(providerName);
        if (providerBaseClass) {
            //@ts-ignore
            this.currentProvider = new providerBaseClass(this);
        } else {
            delete this.currentProvider;
        }
    }

    public async initializeCurrentProvider() {
        if (!this.currentProvider) {
            return;
        }
        await this.initializePersistenceService();
        return await this.currentProvider.initialize();
    }

    private async initializePersistenceService() {
        this.persistence = new PersistenceService(this.chatWindow.configuration);
    }

    public makeChatWindowInteractive() {
        this.dispatchEvent(WindowMethods.unsetLoading());
    }

    public allowUserInput() {
        this.dispatchEvent(Interaction.Methods.showTextInput(this.chatWindow.translation.translate('in-chat.input-box.placeholder')))
    }

    public makeChatWindowInactive() {
        this.dispatchEvent(Interaction.Methods.preventInteraction());
        this.dispatchEvent(Utility.Methods.resizeContainer());
    }

    public renderClosingMessage() {

        return this.renderClosingDialog();

        /*this.dispatchEvent(Messaging.Methods.renderAgentMessage(this.chatWindow.translation.translate('predefined.after-chat-closed'), {
            icon: ProductIcon.Survey
        }));

        this.dispatchEvent(Messaging.Methods.renderAgentMessage(AssetHelper.renderAssetAsImage(Asset.ConversationEnd), {
            icon: ProductIcon.Survey
        }));

        this.dispatchEvent(Interaction.Methods.showSingleChoice([{
            label: this.chatWindow.translation.translate('predefined.close-window'),
            onClick: () => {
                this.chatWindow.api.shutdownService.executeShutdown();
            },
        }]))*/
    }

    public renderClosingDialog(allowBack: boolean = true, callback?: () => void) {

        const originalIsOpen = StartupHelper.open;
        const closed = false;

        localStorage.setItem(StartupHelper._isOpenLocalStorageKey, closed.toString());

        this.dispatchEvent(Interaction.Methods.preventInteraction({
            doNotRestore: true,
            resizeContainer: false
        }));

        let singleChoiceActions: SingleChoiceAction[] = [
            {
                label: this.chatWindow.translation.translate('predefined.close-window'),
                onClick: () => {
                    this.dispatchEvent(Window.Methods.close());
                }
            }
        ];

        if (allowBack) {
            singleChoiceActions.push({
                label: this.chatWindow.translation.translate('wordings.back'),
                onClick: () => {

                    this.dispatchEvent(Interaction.Methods.hideInteraction());
                    if (this.chatWindow.api.context.chatState.state !== ChatState.Finished) {
                        this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction())
                    }

                    localStorage.setItem(StartupHelper._isOpenLocalStorageKey, originalIsOpen.toString());

                    if (callback) {
                        callback();
                    }
                }
            })
        }

        this.dispatchEvent(Interaction.Methods.showSingleChoice(singleChoiceActions, {
            doNotRestore: true,
            displayTop: true,
            label: this.chatWindow.translation.translate('predefined.after-chat-closed'),
            asset: Asset.ConversationEnd,
            allowPrimary: true
        }));
    }

    public showConversationHistory() {
        this.dispatchEvent(WindowMethods.unsetLoading());
    }

    async warmup() {
        // No dependencies for contentGuru defined right now
        await DependencyInjectionHelper.injectDependencies(this.chatWindow.configuration.configuration);
        await this.setProviderClass();
    }

    async initialize() {
        await this.printService.configure();
        await this.initializeCurrentProvider();
        this.initializeHeartbeat();
    }

    private initializeHeartbeat() {
        this.shutdownService.heartbeatInterval = setInterval(() => {

            const currentConfiguration = StartupHelper.currentConfiguration;

            if (!currentConfiguration) {
                this.shutdownService.executeShutdown();
                return;
            }

            StartupHelper.currentConfiguration = {
                ...currentConfiguration,
                heartbeat: Math.floor(Date.now() / 1000)
            }

            // check if there was a last interaction
            // should be set upon start
            if (currentConfiguration.lastInteraction && this.currentProvider.closeAfterSeconds() && this.currentProvider.closeConfirmationEnabled()) {

                const closeAfter = this.currentProvider.closeAfterSeconds();
                const currentDate = Math.floor(Date.now() / 1000);

                if (currentDate - currentConfiguration.lastInteraction > (closeAfter - 30) && !this.beforeCloseOpen) {
                    this.dispatchEvent(Interaction.Methods.showSingleChoice([{
                        label: 'Weiter',
                        onClick: () => {
                            this.beforeCloseOpen = false;
                            this.trackInteraction();
                            this.dispatchEvent(Interaction.Methods.restoreLastCachedInteraction());
                            clearTimeout(this.beforeCloseTimeout)
                        }
                    }], {
                        asset: Asset.Timer,
                        displayTop: true,
                        doNotRestore: true,
                        label: this.chatWindow.translation.translate('notification.user-away')
                    }))
                    this.beforeCloseOpen = true;
                    this.beforeCloseTimeout = setTimeout(() => {
                        this.shutdownService.executeShutdown(EndType.Timeout);
                    }, 30 * 1000)
                }

            }

        }, 1500);
    }

    // when tracking any interaction the current timestamp is stored into the localstorage
    // this allows to check if the user is idle
    public trackInteraction() {
        StartupHelper.currentConfiguration = {
            ...StartupHelper.currentConfiguration,
            lastInteraction: Math.floor(Date.now() / 1000)
        }
    }

}

export default Api
