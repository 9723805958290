import InbentaService from './InbentaService';
import {ToolDirection} from "../../../service/tracking/types";
import {WindowMethods} from "../../../../events/window/methods";
import minimizeWindow = WindowMethods.minimizeWindow;
import unMinimizeWindow = WindowMethods.unMinimizeWindow;

export interface TriggerChatBotOptions {
    track?: boolean
}
/**
 * Direct port of INNO PLATFORM spa/src/app/services/chatbot.service.ts
 * DO NOT CHANGE ANY FUNCTION NAMES / FUNCTIONALITY
 */
class ChatBotService extends InbentaService {

    async initialize() {
        eval('window.chatbotservice=this;');
    }

    /**
     * Call the Chatbot to open
     * @param key
     * @param options
     */
    triggerChatbot(key, options: TriggerChatBotOptions = {}) {

        if(!options) {
            options = {
                track: true
            }
        }

        if(typeof options.track !== 'boolean') {
           options.track = true;
        }

        this.inbenta.conversation.sendMessage({
            directCall: key
        }, !options.track)

    }

    openAgencyCard() {
        const agencyCard = document.querySelector('.nxt-agency-card__trigger') as HTMLElement;
        if(agencyCard) {
            agencyCard.click();
        }
    }

    openUserAssistant() {
        const userAssistantToggle = document.querySelector('.nxt-user-assistant__toggle') as HTMLElement;
        if(userAssistantToggle) {
            userAssistantToggle.click();
            setTimeout(() => {
                const options = [].slice.call(document.querySelectorAll('.nxt-user-assistant__options .nxt-user-assistant__toggle'));
                if(options.length) {
                    // click last
                    options[options.length-1].click();
                }
            }, 1)
        }
    }

    openUserAssistantPhone() {
        const userAssistantToggle = document.querySelector('.nxt-user-assistant__toggle') as HTMLElement;
        if(userAssistantToggle) {
            userAssistantToggle.click();
            setTimeout(() => {
                const options = [].slice.call(document.querySelectorAll('.nxt-user-assistant__options .nxt-user-assistant__toggle'));
                if (options.length) {
                    //click first
                    options[0].click();
                }
            }, 1);
        }
    }

    public minimize() {
        this.inbenta.dispatchEvent(minimizeWindow());
    }

    public unMinimize() {
        this.inbenta.dispatchEvent(unMinimizeWindow());
    }

}

export default ChatBotService;
