import ConfigureTrackingEvent, {ConfigureTrackingEventProps} from './events/ConfigureTrackingEvent';
import TrackToolStartEvent from './events/TrackToolStartEvent';

export namespace TrackingMethods {

    /**
     * Configures the Tracking Service
     * @param config
     */
    export const configureTracking = (config: ConfigureTrackingEventProps) => {
        return new ConfigureTrackingEvent(config);
    };

    /**
     * Tracks the tool start
     */
    export const trackToolStart = () => {
        return new TrackToolStartEvent();
    }

}
