/*export enum ChatState {
    Initializing,
    Initialized,
    ChatRequested,
    Chatting,
    PreChatSurvey,
    PostChatSurvey,
    OfflineSurvey,
    Closed,
    SurveyFinished,
    PrintSurvey
}*/

export enum MessageSource {
    Agent = 'agent',
    Visitor = 'visitor',
    System = 'system',
}

export enum SurveyType {
    Pre = "liveperson/survey/pre-chat",
    Post = "liveperson/survey/post-chat",
    Offline = "liveperson/survey/offline",
}

export enum EngagementStatus {
    Available = 'Available',
    NotAvailable = 'NotAvailable'
}

export enum EngagementFlow {
    Monitored = "monitored",
    UnMonitored = "un_monitored"
}

export enum AgentName {
    Digital = "Digitaler Assistent",
    Human = "Kundenberater"
}
