import {ComponentInterface} from "./components/GeneralComponent";

import ChatWindowContent from "./components/General/ChatWindowContent";
import Api from "./api/api";
import ConfigurationService from "./service/ConfigurationService";
import ProviderErrorHandlingService from "./service/ProviderErrorHandlingService";
import {TranslationService} from "./service/TranslationService";
import ErrorReportingService from './service/ErrorReportingService';

import subscribers from './subscribers';
import StartupHelper from "./helpers/StartupHelper";

import './api';
import PublicMethodsService from './service/PublicMethodsService';
import MessageTransformingSubscriber from './subscribers/MessageTransformingSubscriber';
import FooterEventsSubscriber from './subscribers/FooterEventsSubscriber';
import {ApiMethods} from "./events/api/methods";
import TrackingService from './api/service/tracking/TrackingService';
import {EndType} from "./api/service/tracking/types";

export default class ChatWindow implements ComponentInterface {

    private isRendered: boolean;

    private isSideWindowRendered: boolean;

    domContainer: HTMLElement;
    domElement: HTMLElement;

    api: Api;
    configuration: ConfigurationService;
    providerErrorHandling: ProviderErrorHandlingService;
    publicMethods: PublicMethodsService;
    messageTransforming: MessageTransformingSubscriber;
    errorReporting: ErrorReportingService;
    tracking: TrackingService;

    footerEvents: FooterEventsSubscriber;

    translation: TranslationService;

    constructor(domContainer: HTMLElement) {
        this.domContainer = domContainer;

        this.domElement = document.createElement('div');

        this.configuration = new ConfigurationService(this);
        this.tracking = new TrackingService(this);
        this.api = new Api(this);
        this.providerErrorHandling = new ProviderErrorHandlingService(this);
        this.translation = new TranslationService(this);
        this.publicMethods = new PublicMethodsService(this);
        this.errorReporting = new ErrorReportingService(this);
        this.footerEvents = new FooterEventsSubscriber(this);

        //@ts-ignore
        window.chatWindow = this;

    }

    /**
     * Renders the ChatWindow once
     * Execute this function when the provider feels ready
     */
    public renderChatWindow() {
        if(!this.isRendered) {
            StartupHelper.emitOnStartupEvent();
            if(!this.configuration.configuration.implicitShutdown) {
                this.domContainer.appendChild(this.domElement);
                const chatWindowContent = new ChatWindowContent(this);
                chatWindowContent.render(this.domElement);
            } else {
                this.api.shutdownService.executeShutdown()
            }
            this.isRendered = true;
            StartupHelper.open = true;
        }
    }

    //@ts-ignore
    render() {

        this.registerEventSubscribers();
        this.api.warmup().then(() => {
            if(this.configuration.configuration.environment === 'EXTERNAL' || StartupHelper.open) {
                this.renderChatWindow()
            }

            this.api.initialize().then(() => {
                this.api.dispatchEvent(ApiMethods.apiReady(this.domElement));
            });

        });

    };

    registerEventSubscribers() {
        for(const subscriber of subscribers) {
            new subscriber(this, null);
        }

        // is initialized manually since we need to access it later
        this.messageTransforming = new MessageTransformingSubscriber(this, null);

    }

}
