import ClearHistoryEvent from "./events/ClearHistoryEvent";
import ResizeContainerEvent from "./events/ResizeContainerEvent";
import ScrollToBottomEvent from './events/ScrollToBottomEvent';

export namespace UtilityMethods {
    /**
     * Clears the message history
     */
    export const clearHistory = () => {
        return new ClearHistoryEvent();
    };

    /**
     * Re-sizes the messages container
     */
    export const resizeContainer = () => {
        return new ResizeContainerEvent();
    };

    /**
     * Scrolls the messages container to the bottom
     */
    export const scrollToBottom = (delay?: boolean, force?: boolean|false) => {
        return new ScrollToBottomEvent({
            withDelay: !!delay,
            force: force
        });
    }

}
