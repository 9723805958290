import JSX from "../../jsx";

import {ComponentInterface, GeneralComponent} from "../GeneralComponent";
import {FooterWrapper} from "../General/Content/FooterWrapper";
import AnimationHelper from "../../helpers/AnimationHelper";
import {DirectCallContainer} from "../General/Content/DirectCallWrapper";
import {SingleChoiceInteractionConfig} from "../../events/interaction/types";
import SingleChoiceEvent from "../../events/interaction/events/SingleChoiceEvent";
import {Interaction} from "../../events/interaction";
import {DefaultConfig} from '../../const';
import Environment from '../../helpers/Environment';
import {WindowMethods} from "../../events/window/methods";

class SingleChoice extends GeneralComponent<any> implements ComponentInterface {

    private parentElement: HTMLElement;

    protected onConstructor() {
        this.registerEventHandler(Interaction.Events.onSingleChoice, this.handleShowDirectCallEvent.bind(this));
        this.registerEventHandler(Interaction.Events.onHideInteraction, this.onHide.bind(this));
        this.registerEventHandler(Interaction.Events.onHideChoiceInteraction, this.onHide.bind(this));
    }

    public render(parentElement: HTMLElement | Element): void {
        this.parentElement = parentElement as HTMLElement;
    }

    private static generateDirectCallActions(configuration: SingleChoiceInteractionConfig): HTMLElement {

        const {actions} = configuration;

        const allowMultiple = actions.filter(action => {
           return action.label.length <= 10 || action.label.includes('<')
        }).length == actions.length;

        const animateVisibility = actions.length >= 3;

        const isEven = actions.length % 2 == 0;

        return (
            <FooterWrapper {...configuration}>
                <DirectCallContainer ariaLabel={"Ihnen steht eine Option zur Verfügung"} withoutFlex={Environment.isIE11() && actions.length === 1 ? true : allowMultiple ? !isEven && !isEven : true}>
                    {actions.map((action, index) => {
                        const isLast = allowMultiple ? false : index === actions.length - 1;
                        const className = actions.length === 1 ? 'single' : allowMultiple ? !isEven && !isEven && isLast ? 'single' : 'multiple' : 'single';

                        const isSecondary = configuration.allowPrimary ? actions.length === 2 && index === 1 ? true : (actions.length > 2 || actions.length === 1) : true;

                        return (
                            <div
                                class={`cw__direct-call direct-call__call-container ${className}`}>
                                <button
                                    tabindex="0"
                                    class={`c-button ${isSecondary ? 'c-button--secondary' : ''} cw__direct-call direct-call__button ${action.className || ''} ${animateVisibility ? `direct-call__button--hidden` : ''}`}
                                    onclick={action.onClick ? action.onClick : () => {
                                    }}
                                    autofocus={index===0}
                                >{action.label}</button>
                            </div>
                        )
                    })}
                </DirectCallContainer>
            </FooterWrapper>
        )
    }

    private handleShowDirectCallEvent(event: SingleChoiceEvent) {

        this.chatWindow.messageTransforming.withDelay(() => {

            if (event.data.displayTop === true && this.chatWindow.api.currentProvider.getProviderChatWindowConfig().isHeaderPermanent === true) {
                this.dispatchEvent(WindowMethods.directHeaderClose(true));
                event.data.actions = event.data.actions.map((action) => ({
                        label: action.label,
                        onClick: () => {
                            action.onClick();
                            this.dispatchEvent(WindowMethods.directHeaderClose(false));
                        },
                        className: action.className
                    }
                ));
            }

            this.domElement = SingleChoice.generateDirectCallActions(event.data);
            this.handleHiddenButtons();
            AnimationHelper.animateDirectCall(this.domElement, event.data.displayTop);
            this.visible = true;
            this.domElement.focus();
        });

    }

    private handleHiddenButtons() {
        const hiddenButtons = this.domElement.querySelectorAll('.direct-call__button--hidden');
        hiddenButtons.forEach((hiddenButton, index) => {
            setTimeout(() => {
                hiddenButton.classList.add('direct-call__button--visible');
                hiddenButton.classList.remove('direct-call__button--hidden');
            }, index * DefaultConfig.SurveyDelay / 2)
        });
    }

    private onHide() {
        this.visible = false;
        this.domElement = null;
    }

    set visible(visible: boolean) {
        if (visible && !this.parentElement.contains(this.domElement)) {
            this.parentElement.appendChild(this.domElement);
        } else if (!visible && this.parentElement.contains(this.domElement)) {
            this.domElement.remove();
        }
    }


}

export default SingleChoice;
